// PACKAGE
import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
// ACTIONS
import { fetchCU, fetchKategori, postBerita } from "../store/actions/beritaAction";
// HELPERS
import { decodeValue } from "../utils";
// COMPONENTS
import { SwalNotify, ModalConfirmation, LoadingComponent, SwalSuccessNotify } from "../components";

export default function UploadBerita() {
  const history = useHistory();
  const dispatch = useDispatch();
  const topRef = useRef();
  // DATA
  let { listCU, listKategori, listTipeBerita, isLoading } = useSelector((state) => state.berita);
  const [image, setImage] = useState('');
  const [imagePreview, setImagePreview] = useState('');
  const [imageUploaded, setImageUploaded] = useState(false);
  const [judul, setJudul] = useState('');
  const [infoDetail, setInfoDetail] = useState('');
  const [koperasi, setKoperasi] = useState(null);
  const [kategori, setKategori] = useState(null);
  const [tipeNews, setTipeNews] = useState(null);
  // CONDITION
  const [openModal, setOpenModal] = useState(false);

  // CHANGE DOCUMENT NAME
  useEffect(() => {
    document.title = "Upload Berita | CUSO DM";
  }, []);

  // FETCH DATA WHEN OPENING PAGE
  useEffect(() => {
    if (decodeValue(localStorage.getItem("auth"))) {
      // CHECK TIPE USER
      if (decodeValue(localStorage.getItem("auth"))?.tipe_user !== 0 && decodeValue(localStorage.getItem("auth"))?.tipe_user !== 2) {
        SwalNotify("Anda Tidak Memiliki Akses Menu Tersebut, Silahkan Hubungi Administrator");
        history.push("/");
      };

      dispatch(fetchCU());
      dispatch(fetchKategori());
    };

    // AUTO SCROLL
    topRef.current.scrollIntoView({ behavior: "smooth", block: 'end' });
  }, [localStorage.getItem("auth")]);

  // HANDLE CHECK IMG FORMAT
  const checkImgFormat = (img) => {
    const validFormat = ["image/jpeg", "image/png", "image/jpg"];
    return validFormat.includes(img.type);
  };

  //Foto Produk
  function handleImageChange(e) {
    const img = e.target.files[0];

    if (img && checkImgFormat(img) && img.size < 300000) {
      setImage(img);
      let reader = new FileReader();

      reader.onload = function (e) {
        setImagePreview(e.target.result);
        setImageUploaded(true);
      };

      reader.readAsDataURL(img);
    } else {
      SwalNotify("Gambar Tidak Sesuai Persyaratan, Silahkan Pilih Gambar Lain");
      setImagePreview("");
      setImageUploaded(false);
    };
  };

  //judul
  function handleJudulChange(e) {
    if (e.target.value.length) {
      setJudul(e.target.value);
    } else {
      setJudul("");
    };
  };

  //Informasi Detail
  function handleInformasiDetailChange(e) {
    setInfoDetail(e);
  };

  //CU_Id
  function handleKoperasiChange(e) {
    if (e.target.value) {
      setKoperasi(e.target.value);
    } else {
      setKoperasi(null);
    };
  };

  //Kode Kategori
  function handleKategoriChange(e) {
    if (e.target.value) {
      setKategori(e.target.value);
    } else {
      setKategori(null);
    };
  };

  //Tipe News
  function handleTipeNewsChange(e) {
    if (e.target.value) {
      setTipeNews(e.target.value);
    } else {
      setTipeNews(null);
    };
  };

  //submit
  function handleSubmit(e) {
    e.preventDefault();

    // CHECK VALUE
    if (!image) {
      return SwalNotify("Silahkan Pilih Gambar Berita Terlebih Dahulu");
    } else if (!judul) {
      return SwalNotify("Silahkan Isi Judul Berita Terlebih Dahulu");
    } else if (!infoDetail) {
      return SwalNotify("Silahkan Isi Deskripsi Berita Terlebih Dahulu");
    } else if (decodeValue(localStorage.getItem("auth"))?.CU_Id === null && !koperasi) {
      return SwalNotify("Silahkan Pilih Koperasi Terlebih Dahulu");
    } else if (!kategori) {
      return SwalNotify("Silahkan Pilih Kategori Berita Terlebih Dahulu");
    } else if (!tipeNews) {
      return SwalNotify("Silahkan Pilih Tipe Berita Terlebih Dahulu");
    };

    // SET OPEN MODAL
    setOpenModal(true);

    //   Swal.fire({
    //     title: 'Apakah Anda Sudah Yakin?',
    //     html: html,
    //     imageUrl: imagePreview,
    //     imageWidth: 400,
    //     imageHeight: 200,
    //     showCancelButton: true,
    //     confirmButtonText: 'Simpan',
    //     denyButtonText: `Batal`,
    //   }).then((result) => {
    //     /* Read more about isConfirmed, isDenied below */
    //     if (result.isConfirmed) {
    //       let newDate = new Date()
    //       let date = newDate.getDate();
    //       let month = newDate.getMonth() + 1;
    //       let year = newDate.getFullYear();
    //       const separator = '-'
    //       const tanggal = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`

    //       //jika admin maka CU_Id dipilih, jika bukan admin maka cu id tergantung akun
    //       let CU_Id = 0
    //       if (localStorage.getItem('CU_Id') === 'null') {
    //         CU_Id = koperasi
    //       } else {
    //         CU_Id = localStorage.getItem('CU_Id')
    //       }

    //       const fd = new FormData()
    //       fd.append('Foto', image)
    //       fd.append('Judul', judul)
    //       fd.append('CU_Id', CU_Id)
    //       fd.append('Tanggal', tanggal)
    //       fd.append('Informasi_Detail', infoDetail)
    //       fd.append('Kode_Kategori', kategori)
    //       fd.append('Tipe_News', +tipeNews)
    //       dispatch(postBerita(fd));
    //     } else if (result.isDenied) {
    //       Swal.fire('Changes are not saved', '', 'info')
    //     }
    //   })
    // }
  };

  // HANDLE CONFRIM FROM MODAL && SUBMIT DATA
  const handleConfirm = async () => {
    setOpenModal(false);

    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    const separator = '-';
    const tanggal = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`;

    // SET CU ID
    let CU_Id;
    if (decodeValue(localStorage.getItem("auth"))?.CU_Id === null) {
      CU_Id = koperasi;
    } else {
      CU_Id = decodeValue(localStorage.getItem("auth"))?.CU_Id;
    };

    const fd = new FormData();
    fd.append('Foto', image);
    fd.append('Judul', judul);
    fd.append('CU_Id', CU_Id);
    fd.append('Tanggal', tanggal);
    fd.append('Informasi_Detail', infoDetail);
    fd.append('Kode_Kategori', kategori);
    fd.append('Tipe_News', +tipeNews);
    fd.append('Direct_URL', "");

    // SUBMIT DATA
    const submited = await dispatch(postBerita(fd));;
    if (submited) {
      SwalSuccessNotify("Data Berhasil Tersimpan").then(() => {
        // REDIRECT IF USER ADM CUSO
        if (decodeValue(localStorage.getItem("auth"))?.tipe_user === 0) {
          history.push(`/berita?cu_id=${koperasi}`);
        } else {
          history.push(`/berita`);
        };
      });
    };
  };

  return (
    <>
      {/* LOADING COMPONENT */}
      {isLoading && (<LoadingComponent />)}

      {/* MODAL CONFIRMATION */}
      <ModalConfirmation
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleConfirm={handleConfirm}
        data={{
          imageUploaded, imagePreview, judul, infoDetail, listCU, listKategori,
          Koperasi: listCU?.find(item => item.CU_Id === (koperasi ? koperasi : decodeValue(localStorage.getItem("auth"))?.CU_Id))?.Nama_CU,
          Kategori_Berita: listKategori?.find(item => item.Kode_Kategori === kategori)?.Nama_Kategori,
          Tipe_Berita: listTipeBerita[tipeNews]
        }}
        menu={"berita"}
      />

      {/* CONTENT */}
      <div className="py-3">
        {/** Upload Form*/}
        <form className="w-full" style={{ width: 1024 }} onSubmit={handleSubmit}>

          {/* TOP REF */}
          <div className="bg-transparent" ref={topRef} />

          {/** Judul Form */}
          <div className="flex flex-wrap w-full mb-6">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <h1 className="font-sans font-bold text-2xl">Upload Berita</h1>
            </div>
          </div>

          {/** Image */}
          <div className="flex flex-wrap w-full mb-6">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <h1 className="font-['Open_Sans'] font-bold text-xl">Foto</h1>
              <p className="text-sm font-['Open_Sans']">
                Format gambar .jpg .jpeg .png dan
                ukuran maximum 300kb.
              </p>
            </div>

            <div className="w-full border border-gray-300 rounded-md" style={{ maxWidth: 600 }}>
              {/* BORDER PHOTO */}
              <div className="p-3">
                <div className="border border-gray-300 rounded-md flex justify-center" style={{ maxWidth: 600, maxHeight: 300, minHeight: 250 }}>
                  {imageUploaded && imagePreview ? (
                    <img className="w-fit h-fit" src={imageUploaded && imagePreview ? imagePreview : ""} alt="current-product" style={{ maxHeight: 250 }} />
                  ) : null}
                </div>
              </div>
              <div className="flex items-center justify-center w-full mb-1">
                <label
                  className="flex flex-col border-4 border-blue-200 border-dashed hover:border-gray-300 cursor-pointer mb-1" style={{ width: 190, height: 60 }}>
                  <div className="flex flex-col items-center justify-center pt-1">
                    <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 group-hover:text-gray-600" style={{ width: 20, height: 20 }}
                      fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                    </svg>
                    <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                      Update Photo</p>
                  </div>
                  <input id="upload-image-produk" type="file" accept=".jpg,.png,.jpeg" className="opacity-0" onChange={handleImageChange} />
                </label>
              </div>
            </div>
          </div>

          {/** Divider 1*/}
          <div className="flex flex-wrap w-full mb-6 border-dashed border-t-2 border-gray-300">
            <div className="w-full md:w-1/3 px-3 mt-2 mb-6 md:mb-0">
              <h1 className="font-sans font-bold text-2xl">Informasi Berita</h1>
            </div>
          </div>

          {/** Judul */}
          <div className="flex flex-wrap w-full mb-6">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Judul</h1>
              <p className="text-sm font-['Open_Sans']">
                Judul dari Berita.
              </p>
            </div>
            <div className="w-full md:w-2/3 px-3">
              <label className="block text-sm mt-5">
                <input
                  className="block w-full mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                  style={{ maxWidth: 590 }}
                  onChange={handleJudulChange}
                  value={judul}
                />
              </label>
            </div>
          </div>

          {/** Deskripsi Berita */}
          <div className="flex flex-wrap w-full mb-6">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Deskripsi Berita</h1>
              <p className="text-sm font-['Open_Sans']">
                Deskripsi dari Berita.
              </p>
            </div>
            <div className="w-full md:w-2/3 px-3 mt-5">
              <label className="block mt-4 text-sm">
                <Editor
                  apiKey={window.env.REACT_APP_TINY_MCE_API_KEY}
                  init={{
                    height: 400,
                    width: 590,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent link | removeformat | help",
                  }}
                  onEditorChange={handleInformasiDetailChange}
                />
              </label>
            </div>
          </div>

          {/** Nama Koperasi (untuk admin) */}
          {decodeValue(localStorage.getItem("auth"))?.CU_Id === null ? (
            <div>
              {/** CU_Id */}
              <div className="flex flex-wrap w-full mb-6">
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Koperasi</h1>
                  <p className="text-sm font-['Open_Sans']">
                    Silahkan Pilih Koperasi.
                  </p>
                </div>
                <div className="w-full md:w-2/3 px-3 mt-5">
                  <select onChange={handleKoperasiChange} className="block w-full mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                    style={{ maxWidth: 590 }} value={koperasi}>
                    <option selected hidden >Pilih Koperasi</option>
                    {listCU.map((data, idx) => (
                      <option value={data.CU_Id} key={idx}>{data.CU_Id} - {data.Nama_CU}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          ) : null}

          {/** Kode Kategori */}
          <div className="flex flex-wrap w-full mb-6">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Kategori Berita</h1>
              <p className="text-sm font-['Open_Sans']">
                Pilih 1 Kategori Berita.
              </p>
            </div>
            <div className="w-full md:w-2/3 px-3 mt-5">
              <select onChange={handleKategoriChange} className="block w-full mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                style={{ maxWidth: 590 }} value={kategori}>
                <option selected hidden>Pilih Kategori</option>
                {listKategori.map((data, idx) => (
                  <option value={data.Kode_Kategori} key={idx}>{data.Nama_Kategori}</option>
                ))}
              </select>
            </div>
          </div>

          {/** Tipe News */}
          <div className="flex flex-wrap w-full mb-6">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Tipe Berita</h1>
              <p className="text-sm font-['Open_Sans']">
                Pilih Tipe Berita.
              </p>
            </div>
            <div className="w-full md:w-2/3 px-3 mt-5">
              <select onChange={handleTipeNewsChange} className="block w-full mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                style={{ maxWidth: 590 }} value={tipeNews}>
                <option selected hidden>Pilih Tipe Berita</option>
                {listTipeBerita.map((e, idx) => (
                  <option value={idx} key={idx}>{e}</option>
                ))}
              </select>
            </div>
          </div>

          {/** Submit */}
          <div className="flex flex-wrap justify-center w-full" style={{ paddingBottom: 10 }}>
            <button
              type="submit"
              className="w-full md:w-1/6 flex justify-center py-2 my-1 mr-2 rounded-lg text-white items-center text-sm bg-green-700 hover:bg-green-600 focus:outline-none"
            >
              Simpan
            </button>
            <button
              onClick={() => history.push("/berita")}
              type="button"
              className="w-full md:w-1/6 flex justify-center py-2 my-1 rounded-lg text-white items-center text-sm bg-red-600 hover:bg-red-500 focus:outline-none"
            >
              Batal
            </button>
          </div>
        </form>
      </div>
    </>

  );
}
