// PACAKGE
import { Fragment, useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
// ACTIONS
import { fetchUsers, editUser, addUser } from "../store/actions/userManagementAction";
// COMPONENTS
import { SwalNotify, SwalSuccessNotify } from "./index";
// UTILS
import { specialCharactersCheck, specialCharactersList, specialCharactersReplace } from "../utils";
// ICONS
import { showPass, hidePass } from "../assets";

export default function ModalManageUser({ openModal, setOpenModal, setAction, data, header }) {
    const headTitle = useRef();
    const dispatch = useDispatch();
    // DATA
    const { listKoperasi, listTipeUser } = useSelector(state => state.userManagement);
    const [input, setInput] = useState("");
    // CONDTIONAL
    const [showPassword, setShowPassword] = useState(false);

    // CHANGE DOCUMENT NAME
    useEffect(() => {
        document.title = `${header ? header : "List"} User | CUSO DM`;
    }, [header]);

    // SET DATA IF EDIT
    useEffect(() => {
        if (header === "Edit" && data) {
            setInput({ ...data });
        } else {
            setInput("");
        };
    }, [header, data]);

    // HANDLE INPUT
    const handleInput = (e) => {
        if (e.target.name === "password" || e.target.name === "username") {
            const checkStr = specialCharactersCheck(e.target.value);
            if (checkStr) {
                SwalNotify(`Input Tidak Boleh Mengandung Character: 
                    ${specialCharactersList()}`);
            };
            setInput({ ...input, [e.target.name]: specialCharactersReplace(e.target.value) });
        } else if (e.target.name === "tipe_user") {
            setInput({ ...input, [e.target.name]: +e.target.value });
        } else {
            setInput({ ...input, [e.target.name]: e.target.value });
        };
    };

    // HANDLE CONFRIM FROM MODAL && SUBMIT DATA
    const handleConfirm = async (e) => {
        e.preventDefault();

        // SUBMIT DATA
        let submited;
        if (header === "Edit") {
            let payload = { ...input };
            // CHECK IF PASSWORD EMPTY
            if (!payload.password) {
                delete payload.password;
            } else {
                // CHECK PASSWORD LENGTH
                if (input?.password?.length < 6) {
                    return SwalNotify("Minimum Password adalah 6 Karakter!");
                };

            };
            submited = await dispatch(editUser(payload));
        } else {
            // CHECK PASSWORD LENGTH
            if (input?.password?.length < 6) {
                return SwalNotify("Minimum Password adalah 6 Karakter!");
            };

            submited = await dispatch(addUser(input));
        };
        if (submited) {
            SwalSuccessNotify("Data Berhasil Tersimpan").then(() => {
                dispatch(fetchUsers());
                setOpenModal(false);
            });
        };
    };

    return (
        <Transition show={openModal} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-20 overflow-y-auto"
                style={{ width: "100%" }}
                initialFocus={headTitle}
                static
                open={openModal}
                onClose={() => { }}
            >
                <div className="min-h-screen px-4 text-center">
                    <Dialog.Overlay className="fixed inset-0 opacity-30 bg-black" />
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <form className="inline-block w-full p-6 my-8 overflow-x-hidden overflow-y-auto text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
                            style={{ maxWidth: 500 }}
                            onSubmit={handleConfirm}
                        >
                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-700"
                            >
                                <h1 ref={headTitle} className="text-center" style={{ fontSize: 30 }}>
                                    {header} User
                                </h1>
                            </Dialog.Title>
                            <div className="mt-4">
                                <div>
                                    <label className="block" style={{ fontSize: 14, marginTop: 8, fontWeight: 500 }}>
                                        Koperasi
                                    </label>
                                    <select
                                        className="w-full mt-1 text-sm border border-purple-300 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                                        name="cu_id"
                                        disabled={header === "Edit" ? true : false}
                                        value={input.cu_id ? input.cu_id : ""}
                                        onChange={handleInput}
                                        required
                                    >
                                        <option hidden value="">Pilih Koperasi</option>
                                        {listKoperasi?.length ? listKoperasi.map((e, idx) => (
                                            <option value={e.CU_id} key={idx}>{e.CU_id} - {e.Nama_CU}</option>
                                        )) : null}
                                    </select>
                                </div>
                                <div>
                                    <label className="block" style={{ fontSize: 14, marginTop: 8, fontWeight: 500 }}>
                                        Nama
                                    </label>
                                    <input
                                        className="w-full mt-1 text-sm border border-purple-300 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                                        disabled={header === "Edit" ? true : false}
                                        name="name"
                                        type="text"
                                        value={input.name ? input.name : ""}
                                        onChange={handleInput}
                                        required={true}
                                    />
                                </div>
                                <div>
                                    <label className="block" style={{ fontSize: 14, marginTop: 8, fontWeight: 500 }}>
                                        Username
                                    </label>
                                    <input
                                        className={`w-full mt-1 text-sm border border-purple-300 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input`}
                                        disabled={header === "Edit" ? true : false}
                                        name="username"
                                        type="text"
                                        value={input.username ? input.username : ""}
                                        autoComplete="new-username"
                                        onChange={handleInput}
                                        required={true}
                                    />
                                </div>
                                <div className="flex flex-col relative">
                                    <label className="block mt-4 text-sm">
                                        Password
                                    </label>
                                    <input
                                        className="w-full mt-1 text-sm border border-purple-300 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                                        name="password"
                                        value={input.password ? input.password : ""}
                                        autoComplete="new-password"
                                        onChange={handleInput}
                                        type={showPassword ? "text" : "password"}
                                        required={header === "Edit" ? false : true}
                                    />
                                    <div className="bg-white absolute cursor-pointer" style={{ width: 25, height: 25, bottom: 8, right: 6, padding: 3 }} onClick={() => setShowPassword(!showPassword)}>
                                        <img src={showPassword ? showPass : hidePass} className="opacity-70 p-[2px]" alt="pwd-icon" style={{ width: 20, height: 20 }} />
                                    </div>
                                </div>
                                <div>
                                    <label className="block" style={{ fontSize: 14, marginTop: 8, fontWeight: 500 }}>
                                        Email
                                    </label>
                                    <input
                                        className="w-full mt-1 text-sm border border-purple-300 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                                        disabled={header === "Edit" ? true : false}
                                        name="email"
                                        type="email"
                                        value={input.email ? input.email : ""}
                                        onChange={handleInput}
                                        required={true}
                                    />
                                </div>
                                <div>
                                    <label className="block" style={{ fontSize: 14, marginTop: 8, fontWeight: 500 }}>
                                        Tipe User
                                    </label>
                                    <select
                                        className="w-full mt-1 text-sm border border-purple-300 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                                        name="tipe_user"
                                        value={input.tipe_user ? input.tipe_user : ""}
                                        onChange={handleInput}
                                        required
                                    >
                                        <option hidden value="">Pilih Tipe User</option>
                                        {listTipeUser ? listTipeUser.map((e, idx) => {
                                            if (idx !== 0) {
                                                return (
                                                    <option value={idx} key={idx}>{e}</option>
                                                )
                                            };
                                        }) : null}
                                    </select>
                                </div>
                            </div>
                            <div className="mt-4 flex justify-center gap-2">
                                <button
                                    type="submit"
                                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-purple-600 border border-transparent rounded-md hover:bg-purple-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-purple-600"
                                >
                                    Simpan
                                </button>
                                <div
                                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 cursor-pointer"
                                    onClick={() => {
                                        setOpenModal(false);
                                        setTimeout(() => {
                                            setAction("")
                                        }, 200);
                                    }}
                                >
                                    Batal
                                </div>
                            </div>
                        </form>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
}
