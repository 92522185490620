// CONFIG
import axios from "../../config/axiosinstance";
// HELPERS
import { decodeValue } from "../../utils";
// COMPONENTS
import { SwalNotify } from "../../components";

export const fetchListTransaksiPPOB = () => {
  return (dispatch) => {
    dispatch({
      type: "SET_LIST_TRANSAKSI_LOADING",
      payload: true
    });
    axios({
      method: "GET",
      url: "ppobrev",
      headers: {
        token: decodeValue(localStorage.getItem("auth")).token,
      },
    })
      .then(({ data }) => {
        data.data.forEach((element, idx) => {
          element.idx = idx;
        });
        dispatch({
          type: "SET_LIST_TRANSAKSI_PPOB",
          payload: data.data,
          payload2: {
            totalCUSOPoint1: data.totalCUSOPoint1,
            totalCUSOPoint2: data.totalCUSOPoint2,
            totalRevShareCOOP: data.totalRevShareCOOP,
            totalRevShareCUSO: data.totalRevShareCUSO,
            totalTransaksi: data.totalTransaksi,
          },
        });
      })
      .catch(({ response }) => {
        if (response) {
          if (response?.status === 401) {
            dispatch({
              type: "SET_LOGIN_STATUS",
              payload: false,
            });
            localStorage.removeItem("auth");
          } else {
            SwalNotify(response.data.message);
          };
        };
        dispatch({
          type: "SET_LIST_TRANSAKSI_LOADING",
          payload: false,
        });
      });
  };
};

export const searchListTransaksiPPOB = (payload) => {
  return (dispatch) => {
    dispatch({
      type: "SET_LIST_TRANSAKSI_LOADING",
      payload: true
    });
    axios({
      method: "GET",
      url: payload,
      headers: {
        token: decodeValue(localStorage.getItem("auth")).token,
      },
    })
      .then(({ data }) => {
        data.data.forEach((element, idx) => {
          element.idx = idx;
        });
        dispatch({
          type: "SET_LIST_TRANSAKSI_PPOB",
          payload: data.data,
          payload2: {
            totalCUSOPoint1: data.totalCUSOPoint1,
            totalCUSOPoint2: data.totalCUSOPoint2,
            totalRevShareCOOP: data.totalRevShareCOOP,
            totalRevShareCUSO: data.totalRevShareCUSO,
            totalTransaksi: data.totalTransaksi,
          },
        });
      })
      .catch(({ response }) => {
        if (response) {
          if (response?.status === 401) {
            dispatch({
              type: "SET_LOGIN_STATUS",
              payload: false,
            });
            localStorage.removeItem("auth");
          } else {
            SwalNotify(response.data.message);
          };
        };
        dispatch({
          type: "SET_LIST_TRANSAKSI_LOADING",
          payload: false,
        });
      });
  };
};

export const fetchListTransaksiBank = () => {
  return (dispatch) => {
    dispatch({
      type: "SET_LIST_TRANSAKSI_LOADING",
      payload: true
    });
    axios({
      method: "GET",
      url: "ppobrev/topupbank",
      headers: {
        token: decodeValue(localStorage.getItem("auth")).token,
      },
    })
      .then(({ data }) => {
        data.data.forEach((element, idx) => {
          element.idx = idx;
        });
        dispatch({
          type: "SET_LIST_TRANSAKSI_BANK",
          payload: data.data,
          payload2: {
            totalBiayaTransaksi: data.totalBiayaTransaksi,
            totalNominalCUSOPay: data.totalNominalCUSOPay,
            totalNominalTopUp: data.totalNominalTopUp,
          },
        });
      })
      .catch(({ response }) => {
        if (response) {
          if (response?.status === 401) {
            dispatch({
              type: "SET_LOGIN_STATUS",
              payload: false,
            });
            localStorage.removeItem("auth");
          } else {
            SwalNotify(response.data.message);
          };
        };
        dispatch({
          type: "SET_LIST_TRANSAKSI_LOADING",
          payload: false,
        });
      });
  };
};

export const searchListTransaksiBank = (payload) => {
  return (dispatch) => {
    dispatch({
      type: "SET_LIST_TRANSAKSI_LOADING",
      payload: true
    });
    axios({
      method: "GET",
      url: payload,
      headers: {
        token: decodeValue(localStorage.getItem("auth")).token,
      },
    })
      .then(({ data }) => {
        data.data.forEach((element, idx) => {
          element.idx = idx;
        });
        dispatch({
          type: "SET_LIST_TRANSAKSI_BANK",
          payload: data.data,
          payload2: {
            totalBiayaTransaksi: data.totalBiayaTransaksi,
            totalNominalCUSOPay: data.totalNominalCUSOPay,
            totalNominalTopUp: data.totalNominalTopUp,
          },
        });
      })
      .catch(({ response }) => {
        if (response) {
          if (response?.status === 401) {
            dispatch({
              type: "SET_LOGIN_STATUS",
              payload: false,
            });
            localStorage.removeItem("auth");
          } else {
            SwalNotify(response.data.message);
          };
        };
        dispatch({
          type: "SET_LIST_TRANSAKSI_LOADING",
          payload: false,
        });
      });
  };
};

export const fetchListTransaksiAll = () => {
  return (dispatch) => {
    dispatch({
      type: "SET_LIST_TRANSAKSI_LOADING",
      payload: true
    });
    axios({
      method: "GET",
      url: "ppobrev/alllist",
      headers: {
        token: decodeValue(localStorage.getItem("auth")).token,
      },
    })
      .then(({ data }) => {
        data.data.forEach((element, idx) => {
          element.idx = idx;
        });
        dispatch({
          type: "SET_LIST_TRANSAKSI_ALL",
          payload: data.data,
          payload2: {
            totalCUSOPoint1: data.totalCUSOPoint1,
            totalCUSOPoint2: data.totalCUSOPoint2,
            totalRevShareCOOP: data.totalRevShareCOOP,
            totalRevShareCUSO: data.totalRevShareCUSO,
            totalTransaksi: data.totalTransaksi,
          },
        });
      })
      .catch(({ response }) => {
        if (response) {
          if (response?.status === 401) {
            dispatch({
              type: "SET_LOGIN_STATUS",
              payload: false,
            });
            localStorage.removeItem("auth");
          } else {
            SwalNotify(response.data.message);
          };
        };
        dispatch({
          type: "SET_LIST_TRANSAKSI_LOADING",
          payload: false,
        });
      });
  };
};

export const searchListTransaksiAll = (payload) => {
  return (dispatch) => {
    dispatch({
      type: "SET_LIST_TRANSAKSI_LOADING",
      payload: true
    });
    axios({
      method: "GET",
      url: payload,
      headers: {
        token: decodeValue(localStorage.getItem("auth")).token,
      },
    })
      .then(({ data }) => {
        data.data.forEach((element, idx) => {
          element.idx = idx;
        });
        dispatch({
          type: "SET_LIST_TRANSAKSI_ALL",
          payload: data.data,
          payload2: {
            totalCUSOPoint1: data.totalCUSOPoint1,
            totalCUSOPoint2: data.totalCUSOPoint2,
            totalRevShareCOOP: data.totalRevShareCOOP,
            totalRevShareCUSO: data.totalRevShareCUSO,
            totalTransaksi: data.totalTransaksi,
          },
        });
      })
      .catch(({ response }) => {
        if (response) {
          if (response?.status === 401) {
            dispatch({
              type: "SET_LOGIN_STATUS",
              payload: false,
            });
            localStorage.removeItem("auth");
          } else {
            SwalNotify(response.data.message);
          };
        };
        dispatch({
          type: "SET_LIST_TRANSAKSI_LOADING",
          payload: false,
        });
      });
  };
};

// SET DATA JENIS TRANSAKSI
export const setJenisTransaksi = () => {
  if (decodeValue(localStorage.getItem("auth"))) {
    return (dispatch) => {
      dispatch({
        type: "SET_LIST_JENIS_TRANSAKSI",
        payloadPPOB: decodeValue(localStorage.getItem("auth")).jenisTransaksiPPOB,
        payloadViaBank: decodeValue(localStorage.getItem("auth")).jenisTransaksiViaBank,
      });
    };
  };
};
