// PACKAGE
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory, useParams } from "react-router-dom";
import ReactExport from "react-export-excel";
// ACTIONS
import { fetchListTransaksiPPOB, searchListTransaksiPPOB, setJenisTransaksi } from "../store/actions/listtransaksiAction";
import { fetchCU } from "../store/actions/ruangUsahaAction";
// UTILS
import { formatRupiah, statusTransaksi, decodeValue } from "../utils";
// COMPONENTS
import { NominalCard, TransaksiCard, InputSearch, ButtonSearch, InputSimpleDatePicker, LoadingComponent } from "../components";

export default function ListTransaksiPPOB() {
	const query = new URLSearchParams(useLocation().search);
	const history = useHistory();
	const params = useParams();
	const dispatch = useDispatch();
	const topRef = useRef();
	// EXCEL
	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
	// DATA
	const { listTransaksiPPOB, listTransaksiPPOBTotal, jenisTransaksiPPOB, isLoading } = useSelector((state) => state.listtransaksi);
	const { listCU } = useSelector((state) => state.ruangUsaha);
	const [currentData, setCurrentData] = useState([]);
	const [totalCusoPay, setTotalCusoPay] = useState(0);
	const [paging, setPaging] = useState({
		startNumber: 0,
		limit: 10,
		canNext: true,
		canPrev: false,
		lastIdx: null,
	});
	const [input, setInput] = useState({
		ID_Anggota: query.get("ID_Anggota") || "",
		Kode_Transaksi: query.get("Kode_Transaksi") || "",
		No_BA: query.get("No_BA") || "",
		Nama_Anggota: query.get("Nama_Anggota") || "",
		Billers_Status: query.get("Billers_Status") || "",
		Nama_Jenis_Transaksi_Detail: query.get("Nama_Jenis_Transaksi_Detail") || "",
		min_date:
			query.get("min_date") ||
			new Date(new Date().setDate(new Date().getDate() - 0))
				.toISOString()
				.split("T")[0],
		max_date: query.get("max_date") || new Date().toISOString().split("T")[0],
		CU_Id: query.get("CU_Id") || "",
	});

	// CHANGE DOCUMENT NAME
	useEffect(() => {
		document.title = "List Transaksi PPOB | CUSO DM";
	}, []);

	// FETCH DATA WHEN OPENING PAGE
	useEffect(() => {
		if (decodeValue(localStorage.getItem("auth"))) {
			let url = "ppobrev";
			let urlQuery = "ppobrev?";
			const arrQuery = [];
			// CHECK TIPE USER
			if (decodeValue(localStorage.getItem("auth"))?.tipe_user === 0) {
				for (const key in input) {
					if (input[key].length > 0) {
						arrQuery.push(`${key}=${input[key]}`);
					};
				};
			} else {
				arrQuery.push(`CU_Id=${decodeValue(localStorage.getItem("auth"))?.CU_Id}`);
				for (const key in input) {
					if (input[key].length > 0) {
						arrQuery.push(`${key}=${input[key]}`);
					};
				};
			};
			urlQuery += arrQuery.join("&");
			if (urlQuery[urlQuery.length - 1] !== "?") {
				url = urlQuery;
			};

			if (arrQuery.length) {
				dispatch(searchListTransaksiPPOB(url));
			} else {
				dispatch(fetchListTransaksiPPOB());
			};

			// FETCH DATA
			dispatch(fetchCU());
			dispatch(setJenisTransaksi());

			// AUTO SCROLL
			topRef.current.scrollIntoView({ behavior: "smooth", block: 'end' });
		};
	}, [localStorage.getItem("auth"), params]);
	

	// HANDLE CREATE PAGINATION TABLE
	useEffect(() => {
		if (listTransaksiPPOB?.length) {
			// pagination
			if (10 >= listTransaksiPPOB[listTransaksiPPOB.length - 1].idx + 1) {
				setPaging({
					...paging,
					startNumber: 0,
					limit: 10,
					canNext: false,
					canPrev: false,
					lastIdx: listTransaksiPPOB[listTransaksiPPOB.length - 1].idx,
				});
			} else {
				setPaging({
					...paging,
					startNumber: 0,
					limit: 10,
					canNext: true,
					canPrev: false,
					lastIdx: listTransaksiPPOB[listTransaksiPPOB.length - 1].idx,
				});
			};
			const tempData = [];
			for (let i = 0; i < 10 && i < listTransaksiPPOB.length; i++) {
				tempData.push(listTransaksiPPOB[i]);
			};
			setCurrentData(tempData);

			// set cuso pay
			let cusoPay = 0;
			listTransaksiPPOB.forEach((e) => {
				cusoPay += e.Nominal_CUSO_Pay;
			});
			setTotalCusoPay(cusoPay);
		} else {
			setCurrentData([]);
			setPaging({
				...paging,
				startNumber: 0,
				limit: 10,
				canNext: false,
				canPrev: false,
				lastIdx: 0,
			});
			setTotalCusoPay(0);
		};
	}, [listTransaksiPPOB]);

	// CONVERT DATA TO CSV
	const convertCSV = (items) => {

		// FILTER JENIS TRX
		const filterJenisTrx = (value) => {
			if (value === 0) {
				return "Pending";
			} else if (value === 1) {
				return "Berhasil";
			} else if (value === 2) {
				return "Gagal";
			};
		};

		// FILET CHECK DATA
		const checkData = (value) => {
			if (value) {
				const result = value.toString().replaceAll(",", ".").replaceAll("\r\n", "");;
				return result;
			} else {
				return "-";
			};
		};

		const mappingData = items.map((e, idx) => ({
			No: idx + 1,
			"Kode Transaksi": checkData(e.Kode_Transaksi),
			"ID Koperasi": checkData(e.CU_Id),
			"Nama Koperasi": checkData(e.Nama_CU),
			"ID Anggota": checkData(e.ID_Anggota),
			"No BA": checkData(e.No_BA),
			"Nama Anggota": checkData(e.Nama_Anggota),
			"Jenis Transaksi": checkData(e.Nama_Jenis_Transaksi_Detail),
			"Status Transaksi": filterJenisTrx(e.Billers_Status),
			"Tanggal Transaksi": checkData(e.Tgl_Transaksi),
			"Jam Transaksi": checkData(e.Jam_Transaksi),
			"Nominal CUSO PAY": checkData(e.Nominal_CUSO_Pay),
			"Biaya Transaksi": checkData(e.Nominal_Biaya_Transaksi),
			"Rev Share CUSO": checkData(e.Rev_Share_CUSO),
			"Rev Share COOP": checkData(e.Rev_Share_COOP),
			"CUSO Point 1": checkData(e.CUSO_Point_1),
			"CUSO Point 2": checkData(e.CUSO_Point_2),
			"Keterangan": checkData(e.Keterangan)
		}));

		let csv = '';
		let header = Object.keys(mappingData[0]).join(',');
		let values = mappingData.map(o => Object.values(o).join(',')).join('\n');
		csv += header + '\n' + values;

		const data = new Blob([csv], { type: 'text/csv' });
		const csvURL = window.URL.createObjectURL(data);
		const tempLink = document.createElement('a');
		tempLink.href = csvURL;
		tempLink.setAttribute('download', 'List Transaksi PPOB.csv');
		tempLink.click();
	};

	// HANDLE NEXT DATA TABLE
	const next = () => {
		if (paging.limit + 10 >= listTransaksiPPOB.length) {
			setPaging({
				...paging,
				startNumber: paging.startNumber + 10,
				limit: paging.limit + 10,
				canNext: false,
				canPrev: true,
			});
		} else {
			setPaging({
				...paging,
				startNumber: paging.startNumber + 10,
				limit: paging.limit + 10,
				canNext: true,
				canPrev: true,
			});
		};

		const tempData = [];
		if (paging.startNumber >= -10) {
			for (
				let i = paging.startNumber + 10;
				i < paging.limit + 10 && i < listTransaksiPPOB.length;
				i++
			) {
				tempData.push(listTransaksiPPOB[i]);
			};
			setCurrentData(tempData);
		} else {
			setCurrentData([]);
		};
	};

	// HADLE PREV DATA TABLE
	const prev = () => {
		if (paging.startNumber - 10 <= 0) {
			setPaging({
				...paging,
				startNumber: paging.startNumber - 10,
				limit: paging.limit - 10,
				canNext: true,
				canPrev: false,
			});
		} else {
			setPaging({
				...paging,
				startNumber: paging.startNumber - 10,
				limit: paging.limit - 10,
				canNext: true,
				canPrev: true,
			});
		};

		const tempData = [];
		if (
			paging.startNumber >= 10 &&
			paging.startNumber < listTransaksiPPOB.length
		) {
			for (let i = paging.startNumber - 10; i < paging.limit - 10; i++) {
				tempData.push(listTransaksiPPOB[i]);
			}
			setCurrentData(tempData);
		} else if (
			paging.startNumber >= 10 &&
			paging.lastIdx + 1 === listTransaksiPPOB.length
		) {
			for (let i = paging.startNumber - 10; i < paging.lastIdx + 1; i++) {
				tempData.push(listTransaksiPPOB[i]);
			}
			setCurrentData(tempData);
		} else {
			setCurrentData([]);
		};
	};

	// HANDLE DATE CHANGE
	const onDateChange = (name, value) => {
		setInput({
			...input,
			[name]: value,
		});
	};

	// HANDLE INPUT
	const onChangeHandler = (e) => {
		const { name, value } = e.target;
		setInput({
			...input,
			[name]: value,
		});
	};

	// HANDLE SEARCH
	const searchHandler = (e) => {
		e.preventDefault();
		let params = new URLSearchParams();
		for (const i in input) {
			if (input[i].length > 0) {
				params.append(i, input[i]);
			};
		};

		history.push({ search: params.toString() });
	};

	// HANDLE RESET
	const handleReset = () => {
		setInput({
			min_date: new Date(new Date().setDate(new Date().getDate() - 0))
				.toISOString()
				.split("T")[0],
			max_date: new Date().toISOString().split("T")[0],
		});
		history.push("/list-transaksi-ppob");
	};

	return (
		<>
			{/* LOADING COMPONENT */}
			{isLoading && (<LoadingComponent />)}

			<main style={{ width: 1024 }}>
				<div className="container grid px-6 mx-auto">
					<h2 className="py-3 text-2xl font-semibold bg-white w-full sticky top-0 z-10">
						List Transaksi PPOB
					</h2>

					{/* TOP REF */}
					<div className="bg-transparent" ref={topRef} />

					{/* FILTER & DATA TRANSAKSI */}
					<section className="border border-gray-300 rounded-lg p-2 mb-4">
						<div className="grid md:grid-cols-3 grid-cols gap-2">
							<TransaksiCard
								title="Total Transaksi"
								transaksi={listTransaksiPPOB?.length}
							/>
							<NominalCard title="Total Cuso Pay" nominal={totalCusoPay} />
							<NominalCard
								title="Total Biaya Transaksi"
								nominal={listTransaksiPPOBTotal.totalTransaksi}
							/>
							<NominalCard
								title="Total Rev Share CUSO"
								nominal={listTransaksiPPOBTotal.totalRevShareCUSO}
							/>
							<NominalCard
								title="Total Rev Share COOP"
								nominal={listTransaksiPPOBTotal.totalRevShareCOOP}
							/>
							<NominalCard
								title="Total CUSO Point 1"
								nominal={listTransaksiPPOBTotal.totalCUSOPoint1}
							/>
							<NominalCard
								title="Total CUSO Point 2"
								nominal={listTransaksiPPOBTotal.totalCUSOPoint2}
							/>
						</div>
						<div className="flex flex-col md:flex-row md:justify-between md:gap-8 p-4 mb-2">
							<div className="w-full">
								<InputSearch
									label="Kode Transaksi"
									name="Kode_Transaksi"
									value={input.Kode_Transaksi ? input.Kode_Transaksi : ""}
									onChange={(e) => {
										onChangeHandler(e);
									}}
								/>
								<InputSearch
									label="ID Anggota"
									name="ID_Anggota"
									value={input.ID_Anggota ? input.ID_Anggota : ""}
									onChange={(e) => {
										onChangeHandler(e);
									}}
								/>
								<InputSearch
									label="No BA"
									name="No_BA"
									value={input.No_BA ? input.No_BA : ""}
									onChange={(e) => {
										onChangeHandler(e);
									}}
								/>
								<InputSearch
									label="Nama"
									name="Nama_Anggota"
									value={input.Nama_Anggota ? input.Nama_Anggota : ""}
									onChange={(e) => {
										onChangeHandler(e);
									}}
								/>
								{/* LIST TIPE KOPERASI FOR ADM CUSO */}
								{decodeValue(localStorage.getItem("auth"))?.tipe_user === 0 && (
									<div className="flex justify-between items-center my-1 md:text-lg">
										<label className="w-1/2 text-sm text-gray-700 dark:text-gray-400">
											Nama Koperasi
										</label>
										<select
											name="CU_Id"
											value={input.CU_Id ? input.CU_Id : ""}
											onChange={onChangeHandler}
											className="block w-full mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
											list="CU_Id"
										>
											<option selected value={""}>Semua Koperasi</option>
											{listCU?.map((e, idx) => (
												<option value={e.CU_Id} key={idx}>{e.CU_Id} - {e.Nama_CU}</option>
											))}
										</select>
									</div>
								)}
							</div>
							<div className="w-full flex flex-col justify-between">
								<div className="flex justify-between items-center my-1 md:text-lg">
									<label className="w-1/2 text-sm text-gray-700 dark:text-gray-400">
										Status Transaksi
									</label>
									<select
										className="block w-full mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
										name="Billers_Status"
										value={input.Billers_Status ? input.Billers_Status : ""}
										onChange={onChangeHandler}
									>
										<option value="" defaultValue>Semua Status</option>
										<option value="0">Pending</option>
										<option value="1">Berhasil</option>
										<option value="2">Gagal</option>
									</select>
								</div>
								<div className="flex justify-between items-center my-1 md:text-lg">
									<label className="w-1/2 text-sm text-gray-700 dark:text-gray-400">
										Jenis Transaksi
									</label>
									<input
										name="Nama_Jenis_Transaksi_Detail"
										type="text"
										value={input.Nama_Jenis_Transaksi_Detail ? input.Nama_Jenis_Transaksi_Detail : ""}
										onChange={onChangeHandler}
										className="block w-full mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
										list="jenis-transaksi"
									>
									</input>
									<datalist id="jenis-transaksi">
										<option value="" defaultValue />
										{jenisTransaksiPPOB?.map((e, idx) => {
											return <option key={idx} value={e} />;
										})}
									</datalist>
								</div>
								<div className="flex justify-between items-center my-1 md:text-lg">
									<label className="w-1/2 text-sm text-gray-700 dark:text-gray-400">
										Tgl Transaksi Awal
									</label>
									<InputSimpleDatePicker
										handleChange={(date) => onDateChange("min_date", date)}
										value={input.min_date ? new Date(input.min_date) : ""}
									/>
								</div>
								<div className="flex justify-between items-center my-1 md:text-lg">
									<label className="w-1/2 text-sm text-gray-700 dark:text-gray-400">
										Tgl Transaksi Akhir
									</label>
									<InputSimpleDatePicker
										handleChange={(date) => onDateChange("max_date", date)}
										value={input.max_date ? new Date(input.max_date) : ""}
									/>
								</div>
								<div className="flex">
									<ButtonSearch onClick={(e) => searchHandler(e)} />
									<button
										onClick={handleReset}
										className="w-full py-2 my-1 ml-4 rounded-lg text-white items-center text-sm bg-purple-600 hover:bg-purple-800 focus:outline-none"
									>
										Reset
									</button>
								</div>
							</div>
						</div>
					</section>


					<div className="w-full overflow-hidden rounded-lg shadow-md border border-gray-300 mb-2">
						<div className="sticky top-0 w-full overflow-x-auto h-85vh">
							<table className="relative w-full whitespace-no-wrap">
								<thead>
									<tr className="text-xs font-semibold tracking-wide text-center text-gray-500 uppercase border-b bg-gray-200">
										<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200">
											No
										</th>
										<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 150 }}>
											Kode Transaksi
										</th>
										<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 85 }}>
											CU ID
										</th>
										<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 150 }}>
											Nama Koperasi
										</th>
										<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 150 }}>
											ID Anggota
										</th>
										<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 85 }}>
											No BA
										</th>
										<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 250 }}>
											Nama Anggota
										</th>
										<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 150 }}>
											Jenis Transaksi
										</th>
										<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 150 }}>
											Status Transaksi
										</th>
										<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 175 }}>
											Tanggal Transaksi
										</th>
										<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 150 }}>
											Jam Transaksi
										</th>
										<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 175 }}>
											Nominal CUSO Pay
										</th>
										<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 150 }}>
											Biaya Transaksi
										</th>
										<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 150 }}>
											Rev Share CUSO
										</th>
										<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 150 }}>
											Rev Share COOP
										</th>
										<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 150 }}>
											CUSO Point 1
										</th>
										<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 150 }}>
											CUSO Point 2
										</th>
										<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200">
											Keterangan
										</th>
									</tr>
								</thead>
								<tbody className="bg-white divide-y">
									{currentData.map((d, index) => {
										return (
											<tr className="hover:bg-blue-100">
												<td className="px-4 py-3 text-sm text-center border border-l-0 border-t-0 border-gray-300">{index + paging.startNumber + 1}</td>
												<td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-gray-300">{d.Kode_Transaksi}</td>
												<td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-gray-300 truncate text-center">{d.CU_Id}</td>
												<td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-gray-300 truncate">{d.Nama_CU}</td>
												<td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-gray-300 truncate">{d.ID_Anggota}</td>
												<td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-gray-300 truncate">{d.No_BA}</td>
												<td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-gray-300 truncate">{d.Nama_Anggota}</td>
												<td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-gray-300 truncate">
													{d.Nama_Jenis_Transaksi_Detail}
												</td>
												<td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-gray-300 text-center">
													{statusTransaksi(d.Billers_Status)}
												</td>
												<td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-gray-300 text-center">{d.Tgl_Transaksi}</td>
												<td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-gray-300 text-center">{d.Jam_Transaksi}</td>
												<td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-gray-300 truncate">
													{formatRupiah(d.Nominal_CUSO_Pay)}
												</td>
												<td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-gray-300 truncate">
													{formatRupiah(d.Nominal_Biaya_Transaksi)}
												</td>
												<td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-gray-300 truncate">
													{formatRupiah(d.Rev_Share_CUSO)}
												</td>
												<td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-gray-300 truncate">
													{formatRupiah(d.Rev_Share_COOP)}
												</td>
												<td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-gray-300 truncate">
													{formatRupiah(d.CUSO_Point_1)}
												</td>
												<td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-gray-300 truncate">
													{formatRupiah(d.CUSO_Point_2)}
												</td>
												<td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-r-0 border-gray-300 truncate">{d.Keterangan}</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
						{/* Pagination if needed */}
						<div className="flex justify-between flex-wrap px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
							<span className="flex items-center">
								<span>
									Showing{" "}
									{listTransaksiPPOB?.length > 0
										? `${paging.startNumber + 1}-${paging.startNumber + currentData?.length
										} of ${listTransaksiPPOB?.length}`
										: "0-0 of 0"}
								</span>
								<span>
									{/* EXCEL */}
									{listTransaksiPPOB?.length > 10000 ? (
										<button className="flex w-full items-center justify-center px-4 py-2 text-sm font-medium leading-5 text-green-600 hover:text-green-700 focus:outline-none focus:shadow-outline-green"
											onClick={() => convertCSV(listTransaksiPPOB)}>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												className="h-6 w-6"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
												/>
											</svg>
											<span className="ml-2">Download as Excel</span>
										</button>
									) : (
										<ExcelFile
											element={
												<button className="flex w-full items-center justify-center px-4 py-2 text-sm font-medium leading-5 text-green-600 hover:text-green-700 focus:outline-none focus:shadow-outline-green">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														className="h-6 w-6"
														fill="none"
														viewBox="0 0 24 24"
														stroke="currentColor"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															strokeWidth="2"
															d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
														/>
													</svg>
													<span className="ml-2">Download as Excel</span>
												</button>
											}
											filename="List Transaksi PPOB"
										>
											<ExcelSheet
												data={listTransaksiPPOB}
												name={"List Transaksi PPOB"}
											>
												<ExcelColumn label="No" value={(col) => col.idx + 1} />
												<ExcelColumn
													label="Kode Transaksi"
													value="Kode_Transaksi"
												/>
												<ExcelColumn label="ID Koperasi" value="CU_Id" />
												<ExcelColumn label="Nama Koperasi" value="Nama_CU" />
												<ExcelColumn label="No BA" value="No_BA" />
												<ExcelColumn label="ID Anggota" value="ID_Anggota" />
												<ExcelColumn label="Nama Anggota" value="Nama_Anggota" />
												<ExcelColumn
													label="Jenis Transaksi"
													value="Nama_Jenis_Transaksi_Detail"
												/>
												<ExcelColumn
													label="Status Transaksi"
													value={(col) => {
														if (col.Billers_Status === 0) {
															return "Pending";
														} else if (col.Billers_Status === 1) {
															return "Berhasil";
														} else if (col.Billers_Status === 2) {
															return "Gagal";
														}
													}}
												/>
												<ExcelColumn
													label="Tanggal Transaksi"
													value="Tgl_Transaksi"
												/>
												<ExcelColumn
													label="Jam Transaksi"
													value="Jam_Transaksi"
												/>
												<ExcelColumn
													label="Nominal Cuso Pay"
													value="Nominal_CUSO_Pay"
												/>
												<ExcelColumn
													label="Nominal Biaya Transaksi"
													value="Nominal_Biaya_Transaksi"
												/>
												<ExcelColumn
													label="Rev Share Cuso"
													value="Rev_Share_CUSO"
												/>
												<ExcelColumn
													label="Rev Share Coop"
													value="Rev_Share_COOP"
												/>
												<ExcelColumn label="Cuso Point 1" value="CUSO_Point_1" />
												<ExcelColumn label="Cuso Point 2" value="CUSO_Point_2" />
												<ExcelColumn label="Keterangan" value="Keterangan" />
											</ExcelSheet>
										</ExcelFile>
									)}
								</span>
							</span>
							<span className="col-span-2"></span>
							<span className="flex col-span-4 mt-2 sm:mt-auto justify-end">
								<nav aria-label="Table navigation">
									<ul className="inline-flex items-center py-2">
										<li className="mx-2">
											<button
												className={`px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple ${paging.canPrev
													? "bg-purple-600 hover:bg-purple-800"
													: "bg-purple-300 cursor-default"
													} text-white`}
												disabled={paging.canPrev ? false : true}
												onClick={prev}
											>
												PREV
											</button>
										</li>
										<li>
											<button
												className={`px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple ${paging.canNext
													? "bg-purple-600 hover:bg-purple-800"
													: "bg-purple-300 cursor-default"
													} text-white`}
												disabled={paging.canNext ? false : true}
												onClick={next}
											>
												NEXT
											</button>
										</li>
									</ul>
								</nav>
							</span>
						</div>
					</div>
				</div>
			</main>
		</>

	);
}
