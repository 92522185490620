// PACKAGE
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { version } from './autobuild_version'
import { Switch } from "react-router-dom";
// ACTIONS
import { handleLogout } from "./store/actions/userAction";
// HELPERS
import { decodeValue } from "./utils";
// CUSTOM ROUTES
import { PrivateRoute, PublicRoute } from "./CustomRoutes";
// COMPONENTS
import { Navbar, Sidebar, SwalNotify } from "./components";
import {
  LoginPage,
  DashboardPage,
  DepositSetoranPage,
  HistoryDepositTopupPage,
  DepositTopupPage,
  HistoryDepositSetoranPage,
  ListTransaksiViaBankPage,
  ListTransaksiPage,
  ListTransaksiPPOB,
  ListAnggotaPage,
  ListRekTopupCusoPayPage,
  InfoProdukPage,
  UpdateInfoProdukPage,
  BeritaPage,
  UploadBeritaPage,
  UpdateBeritaPage,
  RuangUsahaPage,
  UserManagementPage,
  UploadRuangUsahaPage,
  UpdateRuangUsahaPage,
  BannerPage,
  UploadBannerPage,
  UpdateBannerPage,
} from "./pages";

function App() {
  const history = useHistory();
  const dispatch = useDispatch();
  // DATA
  const { isLogin } = useSelector(state => state.user);
  let interval;

  // CHECK VERSION
  useEffect(() => {
    if (localStorage.getItem("version") !== version) {
      localStorage.removeItem("version");
      localStorage.setItem("version", version);
      window.location.reload()
    };
  }, []);

  // CHECK SESSION
  useEffect(() => {
    if (localStorage.getItem("auth")) {
      // CLEAR INTERVAL WHEN REFRESH
      clearInterval(interval);
      // CHECK PAYLOAD DATA
      const decoded = decodeValue(localStorage.getItem("auth"));
      if (!decoded) {
        SwalNotify("Unauthorized").then(() => {
          localStorage.removeItem("auth");
          dispatch(handleLogout());
          history.push("/login");
        });
      } else {
        // SET CHECK SESSION INTERVAL
        intervalSessionCheck(decoded);
        // SESSION CHECK WHEN RELOAD
        sessionCheck(decoded);
      };
    } else {
      // CLEAR INTERVAL WHEN LOGOUT
      clearInterval(interval);
      history.push("/login");
    };
  }, [isLogin]);

  // SET INTERVAL CHECK ACCESS TOKEN
  const intervalSessionCheck = (data) => {
    interval = setInterval(() => {
      return sessionCheck(data);
    }, Number(window.env.HIT_INTERVAL));
  };

  // SESSION CHECK
  const sessionCheck = (data) => {
    if (data.expired < Date.now()) {
      clearInterval(interval);
      SwalNotify("Session Berakhir").then(() => {
        localStorage.removeItem("auth");
        dispatch(handleLogout());
        history.push("/login");
      });
    };
  };

  return (
    <Switch>
      {/* ROUTES */}
      <PublicRoute exact path="/login">
        <LoginPage />
      </PublicRoute>
      <div className="flex h-screen overflow-x-auto overflow-y-hidden">
        <Sidebar />
        <div className="flex flex-col flex-1">
          <Navbar />
          <div className="w-full flex-1 flex flex-col overflow-x-hidden overflow-y-auto">
            <div className="flex justify-center w-full h-screen">
              <PrivateRoute exact path="/">
                <DashboardPage />
              </PrivateRoute>
              <PrivateRoute path="/deposit-topup">
                <DepositTopupPage />
              </PrivateRoute>
              <PrivateRoute path="/history-deposit-topup/:id">
                <HistoryDepositTopupPage />
              </PrivateRoute>
              <PrivateRoute path="/deposit-setoran">
                <DepositSetoranPage />
              </PrivateRoute>
              <PrivateRoute path="/history-deposit-setoran/:id">
                <HistoryDepositSetoranPage />
              </PrivateRoute>
              <PrivateRoute path="/list-transaksi-via-bank">
                <ListTransaksiViaBankPage />
              </PrivateRoute>
              <PrivateRoute exact path="/list-transaksi-ppob">
                <ListTransaksiPPOB />
              </PrivateRoute>
              <PrivateRoute exact path="/list-transaksi">
                <ListTransaksiPage />
              </PrivateRoute>
              <PrivateRoute exact path="/list-anggota">
                <ListAnggotaPage />
              </PrivateRoute>
              <PrivateRoute exact path="/list-rek-topup-cusopay">
                <ListRekTopupCusoPayPage />
              </PrivateRoute>
              <PrivateRoute path="/info-produk">
                <InfoProdukPage />
              </PrivateRoute>
              <PrivateRoute path="/update-produk/:koperasi/:produk">
                <UpdateInfoProdukPage />
              </PrivateRoute>
              <PrivateRoute path="/berita">
                <BeritaPage />
              </PrivateRoute>
              <PrivateRoute path="/upload-berita">
                <UploadBeritaPage />
              </PrivateRoute>
              <PrivateRoute path="/update-berita/:kode">
                <UpdateBeritaPage />
              </PrivateRoute>
              <PrivateRoute path="/ruang-usaha">
                <RuangUsahaPage />
              </PrivateRoute>
              <PrivateRoute path="/upload-ruang-usaha">
                <UploadRuangUsahaPage />
              </PrivateRoute>
              <PrivateRoute path="/update-ruang-usaha/:kode">
                <UpdateRuangUsahaPage />
              </PrivateRoute>
              <PrivateRoute path="/banner">
                <BannerPage />
              </PrivateRoute>
              <PrivateRoute path="/upload-banner">
                <UploadBannerPage />
              </PrivateRoute>
              <PrivateRoute path="/update-banner/:kode">
                <UpdateBannerPage />
              </PrivateRoute>
              <PrivateRoute path="/user-management">
                <UserManagementPage />
              </PrivateRoute>
            </div>
          </div>
        </div>
      </div>
    </Switch>
  );
};

export default App;
