const LoadingComponent = () => {
    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="flex flex-col justify-center items-center bg-white p-5 rounded-lg">
                    <svg
                        className="animate-spin mr-2" style={{ width: 35, height: 35, marginLeft: 10 }}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="#4F46E5"
                            strokeWidth="4"
                        ></circle>
                        <path
                            className="opacity-75"
                            fill="#4F46E5"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                    <p className="mt-4">Harap Tunggu...</p>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
};

export default LoadingComponent;