import Swal from "sweetalert2";

const SwalNotify = (notice, footer) => {
    return Swal.fire({
        title: `${notice}`,
        footer: footer ? `${footer}` : "Jika Masih Berlanjut Silahkan Hubungi Cuso Care",
        icon: "warning",
        position: "center",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
    })
}

export default SwalNotify;