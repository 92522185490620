// PACKAGE
import { useState, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
// ACTIONS
import { fetchCurrentProduk, updateInfoProduk, fetchCU } from "../store/actions/infoProdukAction";
// HELPERS
import { decodeValue } from "../utils";
// COMPONENTS
import { SwalNotify, ModalConfirmation, LoadingComponent, SwalSuccessNotify } from "../components";

export default function UpdateInfoProdukPage() {
	const history = useHistory();
	const dispatch = useDispatch();
	const topRef = useRef();
	// DATA
	let { koperasi, produk } = useParams();
	let { currentProduk, listCU, isLoading } = useSelector((state) => state.infoProduk);
	const [image, setImage] = useState('');
	const [imagePreview, setImagePreview] = useState('');
	const [imageUploaded, setImageUploaded] = useState(false);
	const [judul, setJudul] = useState('');
	const [infoDetail, setInfoDetail] = useState("");
	// CONDITION
	const [openModal, setOpenModal] = useState(false);

	// CHANGE DOCUMENT NAME
	useEffect(() => {
		document.title = "Update Info Produk | CUSO DM";
	}, []);

	// FETCH DATA WHEN OPENING PAGE
	useEffect(() => {
		if (decodeValue(localStorage.getItem("auth"))) {
			// CHECK TIPE USER
			if (decodeValue(localStorage.getItem("auth"))?.tipe_user !== 0 && decodeValue(localStorage.getItem("auth"))?.tipe_user !== 2) {
				SwalNotify("Anda Tidak Memiliki Akses Menu Tersebut, Silahkan Hubungi Administrator");
				history.push("/");
			};

			// CHECK CU ID
			if (decodeValue(localStorage.getItem("auth"))?.CU_Id !== null) {
				dispatch(fetchCurrentProduk(decodeValue(localStorage.getItem("auth")).CU_Id, produk));
			} else {
				dispatch(fetchCurrentProduk(koperasi, produk));
			};

			// FETCH DATA CU
			dispatch(fetchCU());
		};

		// AUTO SCROLL
		topRef.current.scrollIntoView({ behavior: "smooth", block: 'end' });
	}, [localStorage.getItem("auth")]);

	// HANDLE CHECK IMG FORMAT
	const checkImgFormat = (img) => {
		const validFormat = ["image/jpeg", "image/png", "image/jpg"];
		return validFormat.includes(img.type);
	};

	//Foto Produk
	function handleImageChange(e) {
		const img = e.target.files[0];

		if (img && checkImgFormat(img) && img.size < 300000) {
			setImage(img);
			let reader = new FileReader();

			reader.onload = function (e) {
				setImagePreview(e.target.result);
				setImageUploaded(true);
			};

			reader.readAsDataURL(img);
		} else {
			SwalNotify("Gambar Tidak Sesuai Persyaratan, Silahkan Pilih Gambar Lain");
			setImagePreview("");
			setImageUploaded(false);
		};
	};

	//Judul Produk
	function handleJudulChange(e) {
		if (e.target.value) {
			setJudul(e.target.value);
		} else {
			setJudul("");
		};
	};

	//Deskripsi Produk
	const handleEditorChange = (content) => {
		setInfoDetail(content);
	};

	//submit
	function handleSubmit(e) {
		e.preventDefault();
		setOpenModal(true);
	};

	// HANDLE CONFRIM FROM MODAL && SUBMIT DATA
	const handleConfirm = async () => {
		setOpenModal(false);
		const fd = new FormData()
		fd.append('Kode_Produk', produk)

		if (image) {
			fd.append('Foto', image)
		} else {
			fd.append('Foto_URL', currentProduk.Foto_URL)
		};

		if (judul) {
			fd.append('Judul', judul)
		} else {
			fd.append('Judul', currentProduk.Judul)
		};

		if (infoDetail) {
			fd.append('Informasi_Detail', infoDetail)
		} else {
			fd.append('Informasi_Detail', currentProduk.Informasi_Detail)
		};

		// SUBMIT DATA
		const submited = await dispatch(updateInfoProduk(fd, koperasi));
		if (submited) {
			SwalSuccessNotify("Data Berhasil Tersimpan").then(() => {
				// REDIRECT IF USER ADM CUSO
				if (decodeValue(localStorage.getItem("auth"))?.tipe_user === 0) {
					history.push(`/info-produk?cu_id=${koperasi}`)
				} else {
					history.push("/info-produk")
				};
			});
		};
	};

	return (
		<>
			{/* LOADING COMPONENT */}
			{isLoading && (<LoadingComponent />)}

			{/* MODAL CONFIRMATION */}
			<ModalConfirmation
				openModal={openModal}
				setOpenModal={setOpenModal}
				handleConfirm={handleConfirm}
				data={currentProduk ? {
					...currentProduk, imageUploaded, imagePreview, judul, infoDetail,
					Koperasi: listCU?.find(item => item.CU_Id === koperasi)?.Nama_CU
				} : null}
				menu={"infoProduk"}
			/>

			{/* CONTENT */}
			<div className="py-3">
				{/** Update Form*/}
				<form
					className="w-full" onSubmit={handleSubmit}
				>
					{/* TOP REF */}
					<div className="bg-transparent" ref={topRef} />

					{/** Judul Form */}
					<div className="flex flex-wrap w-full mb-6">
						<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
							<h1 className="font-sans font-bold text-2xl">{currentProduk.Judul}</h1>
						</div>
					</div>

					{/** Image */}
					<div className="flex flex-wrap w-full mb-6">
						<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
							<h1 className="font-['Open_Sans'] font-bold text-xl">Foto</h1>
							<p className="text-sm font-['Open_Sans']">
								Format gambar .jpg .jpeg .png dan
								ukuran maximum 300kb.
							</p>
						</div>

						<div className="w-full border border-gray-300 rounded-md" style={{ maxWidth: 600 }}>
							{/* BORDER PHOTO */}
							<div className="p-3">
								<div className="border border-gray-300 rounded-md flex justify-center" style={{ maxWidth: 600, maxHeight: 300, minHeight: 250 }}>
									{
										currentProduk && (
											<img className="w-fit h-fit" src={
												imageUploaded && imagePreview ? imagePreview :
													currentProduk.Foto_URL ? currentProduk.Foto_URL : null} alt="" style={{ maxHeight: 250 }} />
										)}
								</div>
							</div>
							<div className="flex items-center justify-center w-full mb-1">
								<label
									className="flex flex-col border-4 border-blue-200 border-dashed hover:border-gray-300 cursor-pointer mb-1" style={{ width: 190, height: 60 }}>
									<div className="flex flex-col items-center justify-center pt-1">
										<svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 group-hover:text-gray-600" style={{ width: 20, height: 20 }}
											fill="none" viewBox="0 0 24 24" stroke="currentColor">
											<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
												d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
										</svg>
										<p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
											Update Photo</p>
									</div>
									<input id="upload-image-produk" type="file" accept=".jpg,.png,.jpeg" className="opacity-0" onChange={handleImageChange} />
								</label>
							</div>
						</div>
					</div>

					{/** Divider 1*/}
					<div className="flex flex-wrap w-full mb-6 border-dashed border-t-2 border-gray-300">
						<div className="w-full md:w-1/3 px-3 mt-2 mb-6 md:mb-0">
							<h1 className="font-sans font-bold text-2xl">Informasi Produk</h1>
						</div>
					</div>

					{/** Judul */}
					<div className="flex flex-wrap w-full mb-6">
						<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
							<h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Judul</h1>
							<p className="text-sm font-['Open_Sans']">
								Cantumkan Judul produk anda dengan jelas.
							</p>
						</div>
						<div className="w-full md:w-2/3 px-3">
							<label className="block text-sm mt-5">
								<input
									className="block w-full mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
									style={{ maxWidth: 590 }}
									onChange={handleJudulChange}
									value={judul || currentProduk.Judul}
								/>
							</label>
						</div>
					</div>

					{/** Informasi Detail */}
					<div className="flex flex-wrap w-full mb-6">
						<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
							<h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Informasi Detail</h1>
							<p className="text-sm font-['Open_Sans']">
								Berikan Informasi Sedetail mungkin tentang produk anda.
							</p>
						</div>
						<div className="w-full md:w-2/3 px-3 mt-5">
							<label className="block mt-4 text-sm">
								<Editor
									apiKey={window.env.REACT_APP_TINY_MCE_API_KEY}
									initialValue={currentProduk.Informasi_Detail}
									init={{
										height: 400,
										width: 590,
										menubar: false,
										plugins: [
											"advlist autolink lists link image charmap print preview anchor",
											"searchreplace visualblocks code fullscreen",
											"insertdatetime media table paste code help wordcount",
										],
										toolbar:
											"undo redo | formatselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify table | bullist numlist outdent indent link | removeformat | help",
									}}
									onEditorChange={handleEditorChange}
								/>
							</label>
						</div>
					</div>

					<div className="flex flex-wrap justify-center w-full" style={{ paddingBottom: 10 }}>
						<button
							type="submit"
							className="w-full md:w-1/6 flex justify-center py-2 my-1 mr-2 rounded-lg text-white items-center text-sm bg-green-700 hover:bg-green-600 focus:outline-none"
						>
							Simpan
						</button>
						<button
							onClick={() => decodeValue(localStorage.getItem("auth"))?.tipe_user === 0 ? history.push(`/info-produk?cu_id=${koperasi}`) : history.push("/info-produk")}
							type="button"
							className="w-full md:w-1/6 flex justify-center py-2 my-1 rounded-lg text-white items-center text-sm bg-red-600 hover:bg-red-500 focus:outline-none"
						>
							Batal
						</button>
					</div>
				</form>
			</div>
		</>
	);
}
