// GENERATE CODE CHALLENGE 
const encodeValue = (value) => {
    const concatStr = generateString();
    const encodedValue = btoa(JSON.stringify(value));
    const result = concatStr + encodedValue;

    return result;
};

// GENERATE STRING
const generateString = () => {
    let result = "";
    const char = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789=";
    const charLength = char.length;
    let counter = 0;
    while (counter < 15) {
        result += char.charAt(Math.floor(Math.random() * charLength));
        counter += 1;
    };

    return result;
};

export default encodeValue;