import Swal from "sweetalert2";

const SwalSuccessNotify = (text) => {
    return Swal.fire({
        position: 'center',
        icon: 'success',
        title: `${text}`,
        showConfirmButton: false,
        timer: 1500
    })
}

export default SwalSuccessNotify;