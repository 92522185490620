const initialState = {
	halamanUtamaRuangUsaha: ["Iya", "Tidak"],
	listTipePenjualan: ["Global", "Local"],
	listRuangUsaha: [],
	listCategory: [],
	listBanner: [],
	listCU:[],
	currentRuangUsaha: {},
	currentBanner: {},
	isLoading: false,
};

export default function ruangUsahaReducer(state = initialState, action) {
	switch (action.type) {
		case "SET_LIST_RUANG_USAHA":
			return {
				...state,
				listRuangUsaha: action.payload,
				isLoading: false,
			};
		case "SET_LIST_BANNER":
			return {
				...state,
				listBanner: action.payload,
				isLoading: false,
			};
		case "SET_LIST_CATEGORY":
			return {
				...state,
				listCategory: action.payload,
				isLoading: false,
			};	
		case "SET_LIST_CU":
			return {
				...state,
				listCU: action.payload,
				isLoading: false,
			};
		case "SET_CURRENT_RUANG_USAHA":
			return {
				...state,
				currentRuangUsaha: action.payload,
				isLoading: false,
			};
		case "SET_CURRENT_BANNER":
			return {
				...state,
				currentBanner: action.payload,
				isLoading: false,
			};
		case "SET_RUANG_USAHA_LOADING":
			return {
				...state,
				isLoading: action.payload,
			};
		default:
			return state;
	}
}
