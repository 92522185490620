const initialState = {
    listSimpananAnggota: [],
    isLoading: false,
  };
  
  function anggotaReducer(state = initialState, action) {
    switch (action.type) {
      case "SET_LIST_SIMPANAN_ANGGOTA":
        return {
          ...state,
          listSimpananAnggota: action.payload,
          isLoading: false,
        };
      case "SET_LIST_SIMPANAN_ANGGOTA_LOADING":
        return {
          ...state,
          isLoading: action.payload,
        };
      default:
        return state;
    }
  }
  
  export default anggotaReducer;
  