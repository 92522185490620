// PACKAGE
import { useEffect, useState, useRef } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
// ACTIONS
import { fetchBanner, searchBanner, fetchCU, deleteRuangUsaha } from "../store/actions/ruangUsahaAction";
// HELPERS
import { decodeValue } from "../utils";
// COMPONENTS
import { SwalNotify, LoadingComponent, SwalSuccessNotify } from "../components";

export default function BannerPage() {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const topRef = useRef();
	// DATA
	let { listBanner, listCU, isLoading } = useSelector((state) => state.ruangUsaha);
	const [CU_Id, setCU_Id] = useState("all");
	const [search, setSearch] = useState("");

	// CHANGE DOCUMENT NAME
	useEffect(() => {
		document.title = "Banner | CUSO DM";
	}, []);

	// AUTO SCROLL
	useEffect(() => {
		// topRef.current.scrollIntoView({ behavior: "smooth", block: 'end' });
	}, [listBanner]);

	// FETCH DATA WHEN OPENING PAGE
	useEffect(() => {
		if (decodeValue(localStorage.getItem("auth"))) {
			// CHECK TIPE USER
			if (decodeValue(localStorage.getItem("auth"))?.tipe_user !== 0 && decodeValue(localStorage.getItem("auth"))?.tipe_user !== 2) {
				SwalNotify("Anda Tidak Memiliki Akses Menu Tersebut, Silahkan Hubungi Administrator");
				history.push("/");
			};

			dispatch(fetchCU());
		};
	}, [localStorage.getItem("auth")]);

	// FETCH DATA BANNER
	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		if (decodeValue(localStorage.getItem("auth"))?.tipe_user === 0) {
			// CHECK CU ID FROM SEARCH PARAMS & FETCH DATA IF EXIST
			// IF PARAMS CU ID && SEARCH EXIST
			if (searchParams.get('cu_id') && searchParams.get('search')) {
				dispatch(searchBanner(searchParams.get('search'), searchParams.get('cu_id')));
				setCU_Id(searchParams.get('cu_id'));
				setSearch(searchParams.get('search'));
			}
			// IF PARAMS SEARCH ONLY
			else if (searchParams.get('search')) {
				dispatch(searchBanner(searchParams.get('search')));
				setSearch(searchParams.get('search'));
			}
			// IF PARAMS CU ID ONLY
			else if (searchParams.get('cu_id')) {
				dispatch(fetchBanner(searchParams.get('cu_id')));
				setCU_Id(searchParams.get('cu_id'));
			} else {
				dispatch(fetchBanner());
			};
		} else {
			if (searchParams.get('search')) {
				dispatch(searchBanner(searchParams.get('search')));
				setSearch(searchParams.get('search'));
			} else {
				dispatch(fetchBanner());
			};
		};
	}, []);

	// HANDLE TRUNCATE HTML DATA
	const handleTruncateHtml = (html, length) => {
		const plainText = html.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
		return plainText.length > length ? plainText.substring(0, length) + '...' : plainText;
	};

	// HANDLE SET DATA SEARCH
	function handleSearchChange(e) {
		if (e.target.value.length) {
			setSearch(e.target.value);
		} else {
			setSearch("");
			// REFETCH DATA RUANG USAHA
			if (CU_Id !== "all") {
				dispatch(fetchBanner(CU_Id));
			} else {
				dispatch(fetchBanner());
			};
		};

		// SET SEARCH PARAMS
		if (e.target.value.length) {
			const searchParams = new URLSearchParams(location.search);
			searchParams.set('search', e.target.value);
			history.push({
				pathname: location.pathname,
				search: searchParams.toString(),
			});
		} else {
			// CHECK PARAMS CU_ID
			if (CU_Id && CU_Id !== "all") {
				history.push(`/banner?cu_id=${CU_Id}`);
			} else {
				history.push(`/banner`);
			};
		};
	};

	// HANDLE SEARCH DATA RUANG USAHA
	function handleSearch() {
		// SEARCH EMPTY
		if (!search) {
			// CHECK IF SELECTED CU ID EXIST
			if (CU_Id !== "all") {
				dispatch(fetchBanner(CU_Id));
			} else {
				dispatch(fetchBanner());
			};
		} else {
			dispatch(searchBanner(search, CU_Id));
		};
	};

	// HANDLE FILTER KOPERASI
	function handleFilterKoperasi(e) {
		// SET CU ID & RESET SEARCH FORM
		setCU_Id(e.target.value);
		setSearch("");

		if (e.target.value === 'all') {
			dispatch(fetchBanner());
			history.push("/banner");
		} else {
			dispatch(fetchBanner(e.target.value));
		};

		// SET SEARCH PARAMS
		if (e.target.value !== "all") {
			history.push(`/banner?cu_id=${e.target.value}`);
		};
	};

	// HANDLE DELETE DATA RUANG UASAHA
	function handleDelete(kode) {
		Swal.fire({
			title: 'Apakah Anda Yakin?',
			text: "Produk yang telah dihapus tidak dapat dikembalikan!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Ya, Hapus!'
		}).then(async (result) => {
			if (result.value) {
				// DELETE DATA
				const deleted = await dispatch(deleteRuangUsaha(kode));
				if (deleted) {
					SwalSuccessNotify("Data Berhasil Dihapus").then(() => {
						// REDIRECT IF USER ADM CUSO
						if (CU_Id && CU_Id !== "all") {
							dispatch(fetchBanner(CU_Id));
							history.push(`/banner?cu_id=${CU_Id}`);
						} else {
							dispatch(fetchBanner());
							history.push("/banner");
						};
					});
				};
			};
		})
	};

	return (
		<main style={{ minWidth: 1024, maxWidth: 1024, minHeight: listBanner?.length && listBanner?.length >= 5 ? 1200 : null }}>
			{/* TOP REF */}
			<div className="bg-transparent" ref={topRef} />

			{/** Component atas */}
			<div className="w-full flex justify-between bg-white sticky top-0 z-10 py-2 px-2">
				{/** Button tambah banner */}
				<div className="px-3 mb-6 mt-3 md:mb-0" style={{ width: 200 }}>
					<button
						className="w-full md:w-full flex justify-center py-2 my-1 rounded-lg text-white items-center text-sm bg-blue-700 focus:outline-none"
					>
						<Link
							className="inline-flex justify-center items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-500 dark:hover:text-gray-200"
							to="/upload-banner"
						>
							<svg
								className="w-5 h-5"
								aria-hidden="true"
								fill="none"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 8l-5-5-5 5M12 4.2v10.3"></path>
							</svg>
							<span className="ml-4">Tambah Banner</span>
						</Link>
					</button>
				</div>

				{/** Search bar*/}
				<div className="mb-6 mt-3 pr-3 md:mb-0" >
					<input
						style={{ width: 400 }}
						type="search"
						className="block w-full text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
						placeholder="Search"
						aria-label="Search"
						aria-describedby="button-addon2"
						onChange={handleSearchChange}
						value={search}
						onKeyPress={(e) => e.key === "Enter" ? handleSearch() : null}
					/>
				</div>

				{/** Filter Koperasi - ADM CUSO ONLY*/}
				{decodeValue(localStorage.getItem("auth"))?.CU_Id === null ? (
					<div style={{ maxWidth: 450 }}>
						<div className="bg-white border border-gray-300 rounded-md shadow-md p-2">
							<div className="flex flex-col w-full">
								<label className="mb-1 font-semibold">Nama Koperasi</label>
								<div className="w-full">
									<select onChange={handleFilterKoperasi} className="block bg-white border border-gray-300 hover:border-gray-500 cursor-pointer disabled:cursor-not-allowed p-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
										style={{ minWidth: 350, maxWidth: 350 }} value={CU_Id} disabled={isLoading ? true : false}>
										<option hidden>Pilih Koperasi</option>
										<option selected value={"all"}>Semua Koperasi</option>
										{listCU.map((data, idx) => {
											return <option value={data.CU_Id} key={idx}>{data.CU_Id} - {data.Nama_CU}</option>;
										})}
									</select>
								</div>
							</div>
						</div>
					</div>
				) : null}
			</div>

			{/** List Produk */}
			<div className="bg-slate-100 flex justify-left ml-8 flex-wrap">
				{/** Card */}
				{
					listBanner ? listBanner.map((produk, idx) => {
						return <div key={idx} className="border border-gray-300 shadow-lg mb-2 mt-2 mx-3 rounded-lg flex flex-col content-center overflow relative" style={{ height: 375, width: 300 }}>
							<div className="bg-gray-200 rounded-t-lg" style={{ height: 110 }}>
								<img
									className="object-cover rounded-t-lg w-full"
									style={{ objectFit: 'contain', maxHeight: 110 }}
									src={produk.Foto_URL}
									alt=""
								/>
							</div>
							<div className="p-3">
								<div className="text-sm font-['Open_Sans'] truncate" style={{ height: 20, maxWidth: 200 }}>
									{listCU.find(cu => cu.CU_Id === produk.CU_Id) ? listCU.find(cu => cu.CU_Id === produk.CU_Id).Nama_CU : ''}
								</div>
								<div className="mb-2 flex flex-col font-['Open_Sans']">
									<p className="font-bold truncate" style={{ maxWidth: 300, height: 30 }}>{produk.Judul}</p>
									<div className="text-xs font-semibold font-['Open_Sans']">{produk.Tanggal.split('T')[0].split("-").reverse().join("/")} -  {`Dilihat ${produk.Hit_Counter}x`} </div>
								</div>
								<div className="font-['Open_Sans']">
									<div style={{ fontSize: 14, marginTop: 14 }} dangerouslySetInnerHTML={{ __html: handleTruncateHtml(produk.Informasi_Detail, 175) }} />
								</div>
							</div>
							<div className="flex flex-wrap h-14 justify-center w-full -ml-2 absolute bottom-0.5">
								<Link to={`/update-banner/${produk.Kode}`}>
									<button
										type="button"
										className="w-full m-3 flex justify-center py-2 rounded-lg text-white items-center text-sm bg-green-700 focus:outline-none"
									>
										<svg
											className="w-3 h-3 mr-1"
											aria-hidden="true"
											fill="none"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
											<polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
										</svg>
										Edit
									</button>
								</Link>
								{/* DELETE DATA CUSO ADM ONLY */}
								{decodeValue(localStorage.getItem("auth"))?.CU_Id === null ? (
									<div className="ml-1">
										<button
											type="button"
											onClick={() => handleDelete(produk.Kode)}
											className="w-full m-3 py-2 rounded-lg text-white items-center text-sm bg-red-600 focus:outline-none"
										>
											Hapus
										</button>
									</div>
								) : null}
							</div>
						</div>
					}) : null
				}
			</div>

			{/* LOADING COMPONENT */}
			{isLoading && (
				<LoadingComponent />
			)}
		</main>
	);
}





{/** Card */ }
{/* {
					listBanner ? listBanner.map((produk, idx) => {
						return <div key={idx} className="bg-slate-50 shadow-lg md:w-1/5 h-85 mt-10 mx-5 rounded-lg flex flex-col content-center overflow-hidden relative">
							<div className="bg-yellow-300">
								<img
									className="h-36 w-full object-cover"
									src={produk.Foto_URL}
									alt=""
								/>
							</div>
							<div className="h-8 ml-3 mt-3 text-sm font-['Open_Sans']">
								{listCU.find(cu => cu.CU_Id === produk.CU_Id) ? listCU.find(cu => cu.CU_Id === produk.CU_Id).Nama_CU : ''}
							</div>
							<div className="h-15 mx-3 mb-3 flex flex-col font-['Open_Sans']">
								{produk.Judul.length < 20 ? <div className="font-bold">{produk.Judul}</div> : <div className="font-bold">{produk.Judul.slice(0, 18)}...</div>}
								<div className="text-xs font-semibold font-['Open_Sans']">{produk.Tanggal.split('T')[0].split("-").reverse().join("-")}</div>
								<div>{`Dilihat sebanyak ${produk.Hit_Counter} kali`}</div>
							</div>
							<div className="m-3 h-10 font-semibold text-sm font-['Open_Sans']">{produk.Harga}</div>
							<div className="flex flex-wrap h-14 justify-center w-full mb-2">
								<Link to={`/update-banner/${produk.Kode}`}>
									<button
										type="button"
										className="w-full m-3 flex justify-center py-2 my-1 rounded-lg text-white items-center text-sm bg-green-700 focus:outline-none"
									>
										<svg
											className="w-5 h-5"
											aria-hidden="true"
											fill="none"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
											<polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
										</svg>
										Edit
									</button>
								</Link>
							</div>
						</div>
					}) : <div>
						<svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
						</svg>
						Harap Tunggu...
					</div>
				} */}