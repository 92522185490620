const initialState = {
	namaKoperasi: "",
	depositTopup: [],
	totalSaldo: 0,
	historyTopup: [],
	isLoading: false,
};

function topupReducer(state = initialState, action) {
	switch (action.type) {
		case "SET_DEPOSIT_TOPUP":
			return {
				...state,
				depositTopup: action.payload,
				isLoading: false,
			};
		case "SET_SALDO_TOPUP":
			return {
				...state,
				totalSaldo: action.payload,
				isLoading: false,
			};
		case "SET_HISTORY_TOPUP":
			return {
				...state,
				historyTopup: action.payload,
				namaKoperasi: action.namaKoperasi,
				isLoading: false,
			};
		case "SET_TOPUP_LOADING":
			return {
				...state,
				isLoading: action.payload,
			};
		default:
			return state;
	}
}

export default topupReducer;
