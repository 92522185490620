const initialState = {
	listBerita: [],
	listKategori: [],
	listCU: [],
	listTipeBerita: ["Global", "Local"],
	detailBerita: {},
	currentBerita: {},
	isLoading: false,
};

export default function beritaReducer(state = initialState, action) {
	switch (action.type) {
		case "SET_LIST_BERITA":
			return {
				...state,
				listBerita: action.payload,
				isLoading: false,
			};
		case "SET_DETAIL_BERITA":
			return {
				...state,
				detailBerita: action.payload,
				isLoading: false,
			};
		case "SET_BERITA_LOADING":
			return {
				...state,
				isLoading: action.payload,
			};
		case "SET_LIST_CU":
			return {
				...state,
				listCU: action.payload,
				isLoading: false,
			};
		case "SET_LIST_CATEGORY":
			return {
				...state,
				listKategori: action.payload,
				isLoading: false,
			};
		case "SET_CURRENT_BERITA":
			return {
				...state,
				currentBerita: action.payload,
				isLoading: false,
			};
		default:
			return state;
	}
}
