// PACKAGE
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory, useParams } from "react-router-dom";
import ReactExport from "react-export-excel";
// ACTIONS
import { fetchListSimpananAnggota, resetListSimpananAnggota } from "../store/actions/listSimpananAnggota";
import { fetchCU } from "../store/actions/ruangUsahaAction";
// UTILS
import { formatRupiah, decodeValue, formatDate, addCommas, dateTime } from "../utils";
// COMPONENTS
import { TransaksiCard, InputSearch, ButtonSearch, LoadingComponent, SwalNotify, ModalEditLimitTopupMobile } from "../components";

export default function ListRekTopupCusoPayPage() {
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const topRef = useRef();
  // EXCEL
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  // DATA
  const { listSimpananAnggota, isLoading } = useSelector((state) => state.listSimpananAnggota);
  const { listCU } = useSelector((state) => state.ruangUsaha);
  const [currentData, setCurrentData] = useState([]);
  const [totalSimpanan, setTotalSimpanan] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [paging, setPaging] = useState({
    startNumber: 0,
    limit: 10,
    canNext: true,
    canPrev: false,
    lastIdx: null,
  });
  const [input, setInput] = useState({
    cu_id: query.get("cu_id") || "",
    search: query.get("search") || "",
  });
  const [selectedDataTable, setSelectedDataTable] = useState("");
  // CONDITION
  const [openModal, setOpenModal] = useState(false);

  // CHANGE DOCUMENT NAME
  useEffect(() => {
    if (!openModal) {
      document.title = "List Rek Topup Cuso Pay | CUSO DM";
    };
  }, [openModal]);

  // FETCH DATA WHEN OPENING PAGE
  useEffect(() => {
    if (decodeValue(localStorage.getItem("auth"))) {
      // CHECK TIPE USER
      if (decodeValue(localStorage.getItem("auth"))?.tipe_user !== 0) {
        SwalNotify("Anda Tidak Memiliki Akses Menu Tersebut, Silahkan Hubungi Administrator");
        history.push("/");
      };

      let url = "anggota/dompet-mobile";
      let urlQuery = "anggota/dompet-mobile?";
      const arrQuery = [];
      // CHECK TIPE USER
      if (decodeValue(localStorage.getItem("auth"))?.tipe_user === 0) {
        for (const key in input) {
          if (input[key].length > 0) {
            arrQuery.push(`${key}=${input[key]}`);
          };
        };
      } else {
        arrQuery.push(`cu_id=${decodeValue(localStorage.getItem("auth"))?.CU_Id}`);
        for (const key in input) {
          if (input[key].length > 0) {
            arrQuery.push(`${key}=${input[key]}`);
          };
        };
      };
      urlQuery += arrQuery.join("&");
      if (urlQuery[urlQuery.length - 1] !== "?") {
        url = urlQuery;
      };

      // FETCH DATA
      dispatch(fetchCU());
      // CHECK IF PARAMS CU ID EXIST
      if (url.includes("cu_id=")) {
        dispatch(fetchListSimpananAnggota(url));
      } else {
        dispatch(resetListSimpananAnggota());
      };

      // AUTO SCROLL
      topRef.current.scrollIntoView({ behavior: "smooth", block: 'end' });
    };
  }, [localStorage.getItem("auth"), params]);

  // HANDLE CREATE PAGINATION TABLE
  useEffect(() => {
    if (listSimpananAnggota?.length) {
      // pagination
      if (10 >= listSimpananAnggota[listSimpananAnggota.length - 1].idx + 1) {
        setPaging({
          ...paging,
          startNumber: 0,
          limit: 10,
          canNext: false,
          canPrev: false,
          lastIdx: listSimpananAnggota[listSimpananAnggota.length - 1].idx,
        });
      } else {
        setPaging({
          ...paging,
          startNumber: 0,
          limit: 10,
          canNext: true,
          canPrev: false,
          lastIdx: listSimpananAnggota[listSimpananAnggota.length - 1].idx,
        });
      }
      const tempData = [];
      for (let i = 0; i < 10 && i < listSimpananAnggota.length; i++) {
        tempData.push(listSimpananAnggota[i]);
      }
      setCurrentData(tempData);
      // total koperasi
      setTotalSimpanan(listSimpananAnggota.length);
    } else {
      setCurrentData([]);
      setPaging({
        ...paging,
        startNumber: 0,
        limit: 10,
        canNext: false,
        canPrev: false,
        lastIdx: 0,
      });
    }
  }, [listSimpananAnggota]);

  // CONVERT DATA TO CSV
  const convertCSV = (items) => {
    // FILET CHECK DATA
    const checkData = (value) => {
      if (value) {
        const result = value.toString().replaceAll(",", ".").replaceAll("\r\n", "");;
        return result;
      } else {
        return "-";
      };
    };

    const mappingData = items.map((e, idx) => ({
      No: idx + 1,
      "No Rekening SH": checkData(e.No_RekeningSH),
      "No BA": checkData(e.No_BA),
      "Nama Anggota": checkData(e.Nama),
      "Nama Golongan": checkData(formatDate(data.Nama_Golongan)),
      "Saldo Simpanan": formatRupiah(data.Saldo_Simpanan),
      "Saldo Simpanan Diagunkan": formatRupiah(e.Saldo_Simpanan_Diagunkan),
      "Saldo Minimum": formatRupiah(data.Saldo_Minimum),
      "Saldo Yang Bisa Ditopup": formatRupiah(data.Saldo_Yg_Bisa_Ditopup),
      "Isi Dompet Digital Perhari": formatRupiah(data.Isi_Dompet_Digital_Perhari),
      "Last Update By": checkData(e.Last_Update_DateTime),
      "Last Update DateTime": dateTime(e.Last_Update_By),
      "Last Update Location": checkData(e.Last_Update_Location),
    }));

    let csv = '';
    let header = Object.keys(mappingData[0]).join(',');
    let values = mappingData.map(o => Object.values(o).join(',')).join('\n');
    csv += header + '\n' + values;

    const data = new Blob([csv], { type: 'text/csv' });
    const csvURL = window.URL.createObjectURL(data);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'List Simpanan Anggota.csv');
    tempLink.click();
  };

  // HANDLE NEXT DATA TABLE
  const next = () => {
    if (paging.limit + 10 >= listSimpananAnggota.length) {
      setPaging({
        ...paging,
        startNumber: paging.startNumber + 10,
        limit: paging.limit + 10,
        canNext: false,
        canPrev: true,
      });
    } else {
      setPaging({
        ...paging,
        startNumber: paging.startNumber + 10,
        limit: paging.limit + 10,
        canNext: true,
        canPrev: true,
      });
    };

    const tempData = [];
    if (paging.startNumber >= -10) {
      for (
        let i = paging.startNumber + 10;
        i < paging.limit + 10 && i < listSimpananAnggota.length;
        i++
      ) {
        tempData.push(listSimpananAnggota[i]);
      };
      setCurrentData(tempData);
    } else {
      setCurrentData([]);
    };
  };

  // HADLE PREV DATA TABLE
  const prev = () => {
    if (paging.startNumber - 10 <= 0) {
      setPaging({
        ...paging,
        startNumber: paging.startNumber - 10,
        limit: paging.limit - 10,
        canNext: true,
        canPrev: false,
      });
    } else {
      setPaging({
        ...paging,
        startNumber: paging.startNumber - 10,
        limit: paging.limit - 10,
        canNext: true,
        canPrev: true,
      });
    };

    const tempData = [];
    if (
      paging.startNumber >= 10 &&
      paging.startNumber < listSimpananAnggota.length
    ) {
      for (let i = paging.startNumber - 10; i < paging.limit - 10; i++) {
        tempData.push(listSimpananAnggota[i]);
      };
      setCurrentData(tempData);
    } else if (
      paging.startNumber >= 10 &&
      paging.lastIdx + 1 === listSimpananAnggota.length
    ) {
      for (let i = paging.startNumber - 10; i < paging.lastIdx + 1; i++) {
        tempData.push(listSimpananAnggota[i]);
      };
      setCurrentData(tempData);
    } else {
      setCurrentData([]);
    };
  };

  // HANDLE INPUT
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
  };

  // HANDLE SEARCH
  const searchHandler = (e) => {
    e.preventDefault();
    let params = new URLSearchParams();
    for (const i in input) {
      if (input[i].length > 0) {
        params.append(i, input[i]);
      };
    };

    history.push({ search: params.toString() });
  };

  // HANDLE RESET
  const handleReset = () => {
    setInput("");
    setTotalSimpanan(0);
    setSelectedRow(null);
    dispatch(resetListSimpananAnggota());
    history.push("/list-rek-topup-cusopay");
  };

  return (
    <>
      {/* LOADING COMPONENT */}
      {isLoading && (<LoadingComponent />)}

      {/* MODAL EDIT LIMIT TOPUP */}
      <ModalEditLimitTopupMobile
        openModal={openModal}
        setOpenModal={setOpenModal}
        setSelectedRow={setSelectedRow}
        data={selectedDataTable}
      />

      <main style={{ width: 1024 }}>
        <div className="container grid px-6 mx-auto">
          <h2 className="py-3 text-2xl font-semibold bg-white w-full sticky top-0 z-10">
            List Rek Topup Cuso Pay
          </h2>

          {/* TOP REF */}
          <div className="bg-transparent" ref={topRef} />

          {/* FILTER & DATA TRANSAKSI */}
          <section className="border border-gray-300 rounded-lg p-2 mb-4">
            <div className="grid md:grid-cols-3 grid-cols gap-2">
              <TransaksiCard title="Total Rekening" transaksi={addCommas(totalSimpanan)} />
            </div>
            <div className="flex flex-col md:flex-row md:justify-between md:gap-8 p-4 mb-2">
              <div className="w-full">
                <InputSearch
                  label="Nama/No Rekening"
                  name="search"
                  value={input.search ? input.search : ""}
                  onChange={(e) => {
                    onChangeHandler(e);
                  }}
                />
                {/* LIST TIPE KOPERASI FOR ADM CUSO */}
                {decodeValue(localStorage.getItem("auth"))?.tipe_user === 0 && (
                  <div className="flex justify-between items-center my-1 md:text-lg">
                    <label className="w-1/2 text-sm text-gray-700 dark:text-gray-400">
                      Nama Koperasi
                    </label>
                    <select
                      name="cu_id"
                      value={input.cu_id ? input.cu_id : ""}
                      onChange={onChangeHandler}
                      className="block w-full mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                      list="cu_id"
                    >
                      <option selected value={""}>Pilih Koperasi</option>
                      {listCU?.map((e, idx) => (
                        <option value={e.CU_Id} key={idx}>{e.CU_Id} - {e.Nama_CU}</option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
              <div className="w-full flex flex-col justify-between">
                <div className="flex mt-2">
                  <ButtonSearch onClick={(e) => searchHandler(e)} />
                  <button
                    onClick={handleReset}
                    className="w-full py-2 my-1 ml-4 rounded-lg text-white items-center text-sm bg-purple-600 hover:bg-purple-800 focus:outline-none"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </section>

          {/* INSTRUCTION MESSAGE */}
          <p className="text-purple-600 font-semibold" style={{ fontSize: 12 }}>*Klik 2 Kali Pada Data Table Untuk Update Limit Isi Dompet Digital Perhari Anggota.</p>
          <div className="w-full overflow-hidden rounded-lg shadow-md border border-gray-300 mb-2">
            <div className="sticky top-0 w-full overflow-x-auto h-85vh">
              <table className="relative w-full whitespace-no-wrap">
                <thead>
                  <tr className="text-xs font-semibold tracking-wide text-center text-gray-500 uppercase border-b bg-gray-200">
                    <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200">
                      No
                    </th>
                    <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 150 }}>
                      No Rekening SH
                    </th>
                    <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 150 }}>
                      No BA
                    </th>
                    <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 250 }}>
                      Nama Anggota
                    </th>
                    <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 150 }}>
                      Nama Golongan
                    </th>
                    <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 150 }}>
                      Saldo Simpanan
                    </th>
                    <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 225 }}>
                      Saldo Simpanan Diagunkan
                    </th>
                    <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 150 }}>
                      Saldo Minimum
                    </th>
                    <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 200 }}>
                      Saldo Yang Bisa Ditopup
                    </th>
                    <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 225 }}>
                      Isi Dompet Digital Perhari
                    </th>
                    <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 200 }}>
                      Last Update By
                    </th>
                    <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 200 }}>
                      Last Update DateTime
                    </th>
                    <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 200 }}>
                      Last Update Location
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y">
                  {currentData.map((d, index) => {
                    return (
                      <tr className={`hover:bg-blue-200 cursor-pointer ${selectedRow === index ? "bg-blue-100" : null}`}
                        onClick={() => {
                          setSelectedRow(index); setSelectedDataTable({
                            ...d,
                            Cu_ID: decodeValue(localStorage.getItem("auth"))?.tipe_user === 0 ? input.cu_id : decodeValue(localStorage.getItem("auth"))?.CU_Id
                          })
                        }} onDoubleClick={() => setOpenModal(true)}>
                        <td className="px-4 py-3 text-sm text-center border border-l-0 border-t-0 border-gray-300">{index + paging.startNumber + 1}</td>
                        <td className="px-4 py-3 text-sm text-center border border-l-0 border-t-0 border-gray-300">{d.No_RekeningSH}</td>
                        <td className="px-4 py-3 text-sm text-center border border-l-0 border-t-0 border-gray-300">{d.No_BA}</td>
                        <td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-gray-300">{d.Nama}</td>
                        <td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-gray-300">{d.Nama_Golongan}</td>
                        <td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-gray-300 truncate">
                          {formatRupiah(d.Saldo_Simpanan)}
                        </td>
                        <td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-gray-300 truncate">
                          {formatRupiah(d.Saldo_Simpanan_Diagunkan)}
                        </td>
                        <td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-gray-300 truncate">
                          {formatRupiah(d.Saldo_Minimum)}
                        </td>
                        <td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-gray-300 truncate">
                          {formatRupiah(d.Saldo_Yg_Bisa_Ditopup)}
                        </td>
                        <td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-gray-300 truncate">
                          {formatRupiah(d.Isi_Dompet_Digital_Perhari)}
                        </td>
                        <td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-gray-300">{d.Last_Update_By}</td>
                        <td className="px-4 py-3 text-sm text-center border border-l-0 border-t-0 border-gray-300">{dateTime(d.Last_Update_DateTime)}</td>
                        <td className="px-4 py-3 text-sm border border-l-0 border-t-0 border-r-0 border-gray-300">{d.Last_Update_Location}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {/* Pagination if needed */}
            <div className="flex justify-between flex-wrap px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
              <span className="flex items-center">
                <span>
                  Showing{" "}
                  {listSimpananAnggota?.length > 0
                    ? `${paging.startNumber + 1}-${paging.startNumber + currentData?.length
                    } of ${listSimpananAnggota?.length}`
                    : "0-0 of 0"}
                </span>
                <span>
                  {/* EXCEL */}
                  {listSimpananAnggota?.length > 10000 ? (
                    <button className="flex w-full items-center justify-center px-4 py-2 text-sm font-medium leading-5 text-green-600 hover:text-green-700 focus:outline-none focus:shadow-outline-green"
                      onClick={() => convertCSV(listSimpananAnggota)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                        />
                      </svg>
                      <span className="ml-2">Download as Excel</span>
                    </button>
                  ) : (
                    <ExcelFile
                      element={
                        <button className="flex w-full items-center justify-center px-4 py-2 text-sm font-medium leading-5 text-green-600 hover:text-green-700 focus:outline-none focus:shadow-outline-green">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                            />
                          </svg>
                          <span className="ml-2">Download as Excel</span>
                        </button>
                      }
                      filename="List Simpanan Anggota"
                    >
                      <ExcelSheet data={listSimpananAnggota} name="List Simpanan Anggota">
                        <ExcelColumn label="No" value={(col) => col.idx + 1} />
                        <ExcelColumn label="No Rekening SH" value="No_RekeningSH" />
                        <ExcelColumn label="No BA" value="No_BA" />
                        <ExcelColumn label="Nama Anggota" value="Nama" />
                        <ExcelColumn label="Nama Golongan" value="Nama_Golongan" />
                        <ExcelColumn label="Saldo Simpanan" value="Saldo_Simpanan" />
                        <ExcelColumn label="Saldo Simpanan Diagunkan" value="Saldo_Simpanan_Diagunkan" />
                        <ExcelColumn label="Saldo Minimum" value="Saldo_Minimum" />
                        <ExcelColumn label="Saldo Yang Bisa Ditopup" value="Saldo_Yg_Bisa_Ditopup" />
                        <ExcelColumn label="Isi Dompet Digital Perhari" value="Isi_Dompet_Digital_Perhari" />
                        <ExcelColumn label="Last Update By" value="Last_Update_By" />
                        <ExcelColumn label="Last Update DateTime" value="Last_Update_DateTime" />
                        <ExcelColumn label="Last Update Location" value="Last_Update_Location" />
                      </ExcelSheet>
                    </ExcelFile>
                  )}
                </span>
              </span>
              <span className="col-span-2"></span>
              <span className="flex col-span-4 mt-2 sm:mt-auto justify-end">
                <nav aria-label="Table navigation">
                  <ul className="inline-flex items-center py-2">
                    <li className="mx-2">
                      <button
                        className={`px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple ${paging.canPrev
                          ? "bg-purple-600 hover:bg-purple-800"
                          : "bg-purple-300 cursor-default"
                          } text-white`}
                        disabled={paging.canPrev ? false : true}
                        onClick={prev}
                      >
                        PREV
                      </button>
                    </li>
                    <li>
                      <button
                        className={`px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple ${paging.canNext
                          ? "bg-purple-600 hover:bg-purple-800"
                          : "bg-purple-300 cursor-default"
                          } text-white`}
                        disabled={paging.canNext ? false : true}
                        onClick={next}
                      >
                        NEXT
                      </button>
                    </li>
                  </ul>
                </nav>
              </span>
            </div>
          </div>
        </div>
      </main>
    </>

  );
}
