// PACKAGE
import { useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// ACTIONS
import { fetchInfoProduk, fetchCU, resetInfoProduk } from "../store/actions/infoProdukAction";
// HELPERS
import { decodeValue } from "../utils";
// COMPONENTS
import { SwalNotify, LoadingComponent } from "../components";

export default function InfoProdukPage() {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const topRef = useRef();
	// DATA
	const { listInfoProduk, listCU, isLoading } = useSelector((state) => state.infoProduk);
	const [CU_Id, setCU_Id] = useState("");

	// CHANGE DOCUMENT NAME
	useEffect(() => {
		document.title = "Info Produk | CUSO DM";
	}, []);

	// FETCH DATA WHEN OPENING PAGE
	useEffect(() => {
		if (decodeValue(localStorage.getItem("auth"))) {
			// CHECK TIPE USER
			if (decodeValue(localStorage.getItem("auth"))?.tipe_user !== 0 && decodeValue(localStorage.getItem("auth"))?.tipe_user !== 2) {
				SwalNotify("Anda Tidak Memiliki Akses Menu Tersebut, Silahkan Hubungi Administrator");
				history.push("/");
			};

			if (decodeValue(localStorage.getItem("auth"))?.CU_Id !== null) {
				dispatch(fetchInfoProduk(decodeValue(localStorage.getItem("auth")).CU_Id));
				setCU_Id(decodeValue(localStorage.getItem("auth")).CU_Id);
			};

			// FETCH LIST CU IF USER ADM CUSO
			if (decodeValue(localStorage.getItem("auth"))?.tipe_user === 0) {
				// CHECK CU ID FROM SEARCH PARAMS & FETCH DATA IF EXIST
				const searchParams = new URLSearchParams(location.search);
				if (searchParams.get('cu_id')) {
					dispatch(fetchInfoProduk(searchParams.get('cu_id')));
					setCU_Id(searchParams.get('cu_id'));
				} else {
					dispatch(resetInfoProduk());
				};
				dispatch(fetchCU());
			};
		};

		// AUTO SCROLL
		topRef.current.scrollIntoView({ behavior: "smooth", block: 'end' });
	}, [localStorage.getItem("auth")]);

	// HANDLE SELECT FILTER KOPERASI
	function handleFilterKoperasi(e) {
		dispatch(fetchInfoProduk(e.target.value));
		setCU_Id(e.target.value);

		// SET SEARCH PARAMS
		const searchParams = new URLSearchParams(location.search);
		searchParams.set('cu_id', e.target.value);
		history.push({
			pathname: location.pathname,
			search: searchParams.toString(),
		});

		// AUTO SCROLL
		topRef.current.scrollIntoView({ behavior: "smooth", block: 'end' });
	};

	return (
		<main style={{ minWidth: 1024, maxWidth: 1024, minHeight: listInfoProduk?.length ? 1400 : null }}>
			{/** Filter Koperasi - ADM CUSO ONLY*/}
			{decodeValue(localStorage.getItem("auth"))?.CU_Id === null ? (
				<div className="w-full bg-white sticky top-0 z-10 py-2 px-2">
					<div className="flex justify-center">
						<div style={{ maxWidth: 450 }}>
							<div className="bg-white border border-gray-300 rounded-md shadow-md p-2">
								<div className="flex flex-col w-full">
									<label className="mb-1 font-semibold">Nama Koperasi</label>
									<div className="w-full">
										<select onChange={handleFilterKoperasi} className="block bg-white border border-gray-300 hover:border-gray-500 cursor-pointer disabled:cursor-not-allowed p-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
											style={{ minWidth: 350, maxWidth: 350 }} value={CU_Id} disabled={isLoading ? true : false}>
											<option selected hidden>Pilih Koperasi</option>
											{listCU.map((data, idx) => {
												return <option value={data.CU_Id} key={idx}>{data.CU_Id} - {data.Nama_CU}</option>;
											})}
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : null}

			{/* TOP REF */}
			<div className="bg-transparent" ref={topRef} />

			{/** List Produk */}
			<div className="flex justify-center flex-wrap" style={{ paddingBottom: 10 }}>
				{
					listInfoProduk?.length && !isLoading ? listInfoProduk.map((produk, idx) => {
						return (
							<div key={idx} className="border border-gray-300 shadow-md mt-3 mx-2 rounded-lg flex flex-col content-center">
								<div className="p-5 text-2xl font-['Open_Sans']">
									<p className="font-bold truncate" style={{ maxWidth: 370 }}>{produk.Judul}</p>
								</div>
								<div className="flex justify-center">
									<img
										className="h-full w-full rounded-lg mb-5"
										src={produk.Foto_URL}
										alt="foto-produk"
										style={{ maxWidth: 300, maxHeight: 350, minHeight: 350 }}
									/>
								</div>
								{/* DESKRIPSI PRODUK */}
								<div className="flex flex-col">
									<label className="mx-5 mb-1 flex flex-col font-['Open_Sans'] font-bold">Deskripsi Produk:</label>
									<div className="border border-gray-300 p-2 rounded-md overflow-y-auto mx-5 mb-3 flex flex-col font-['Open_Sans']" style={{ width: 375, height: 300 }}>
										<div dangerouslySetInnerHTML={{ __html: produk.Informasi_Detail }}></div>
									</div>
								</div>
								{/* BUTTON UPDATE */}
								<div className="flex flex-wrap h-14 justify-center w-full mb-2">
									<Link to={`/update-produk/${CU_Id}/${produk.Kode_Produk}`}>
										<button
											type="button"
											className="w-full p-2 m-2 flex justify-center rounded-lg text-white items-center text-md bg-green-700 focus:outline-none"
										>
											<svg
												className="w-4 h-4 mr-1"
												aria-hidden="true"
												fill="none"
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
												<polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
											</svg>
											Update
										</button>
									</Link>
								</div>
							</div>
						)
					}) : null
				}

				{isLoading && (
					<LoadingComponent />
				)}
			</div>
		</main>
	);
}
