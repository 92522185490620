// PACKAGE
import { useEffect, useState, useRef } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// ACTIONS
import { fetchBerita, fetchCU } from "../store/actions/beritaAction";
// HELPERS
import { decodeValue } from "../utils";
// COMPONENTS
import { SwalNotify, LoadingComponent } from "../components";

export default function BeritaPage() {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const topRef = useRef();
	// DATA
	let { listBerita, listCU, isLoading } = useSelector((state) => state.berita);
	const [CU_Id, setCU_Id] = useState("all");

	// CHANGE DOCUMENT NAME
	useEffect(() => {
		document.title = "Berita | CUSO DM";
	}, []);

	// AUTO SCROLL
	useEffect(() => {
		topRef.current.scrollIntoView({ behavior: "smooth", block: 'end' });
	}, [listBerita]);

	// FETCH DATA WHEN OPENING PAGE
	useEffect(() => {
		if (decodeValue(localStorage.getItem("auth"))) {
			// CHECK TIPE USER
			if (decodeValue(localStorage.getItem("auth"))?.tipe_user !== 0 && decodeValue(localStorage.getItem("auth"))?.tipe_user !== 2) {
				SwalNotify("Anda Tidak Memiliki Akses Menu Tersebut, Silahkan Hubungi Administrator");
				history.push("/");
			};

			dispatch(fetchCU());
		};
	}, [localStorage.getItem("auth")]);

	// FILTER DATA BERITA
	useEffect(() => {
		if (decodeValue(localStorage.getItem("auth"))?.tipe_user === 0) {
			// CHECK CU ID FROM SEARCH PARAMS & FETCH DATA IF EXIST
			const searchParams = new URLSearchParams(location.search);
			if (searchParams.get('cu_id')) {
				dispatch(fetchBerita(searchParams.get('cu_id')));
				setCU_Id(searchParams.get('cu_id'));
			} else {
				dispatch(fetchBerita());
			};
		} else {
			dispatch(fetchBerita());
		};
	}, []);

	// HANDLE TRUNCATE HTML DATA
	const handleTruncateHtml = (html, length) => {
		const plainText = html.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
		return plainText.length > length ? plainText.substring(0, length) + '...' : plainText;
	};

	// HANDLE SELECT FILTER KOPERASI
	function handleFilterKoperasi(e) {
		// SET CU ID
		setCU_Id(e.target.value);

		if (e.target.value === 'all') {
			dispatch(fetchBerita());
			history.push("/berita");
		} else {
			dispatch(fetchBerita(e.target.value));
		};

		// SET SEARCH PARAMS
		if (e.target.value !== "all") {
			const searchParams = new URLSearchParams(location.search);
			searchParams.set('cu_id', e.target.value);
			history.push({
				pathname: location.pathname,
				search: searchParams.toString(),
			});
		};
	};

	return (
		<main style={{ minWidth: 1024, maxWidth: 1024, minHeight: listBerita?.length && listBerita?.length >= 6 ? 1400 : null }}>
			{/* TOP REF */}
			<div className="bg-transparent" ref={topRef} />

			{/** Component atas */}
			<div className="w-full flex flex-row justify-between bg-white sticky top-0 z-10 py-2 px-2">
				{/** Button tambah Berita */}
				<div className="px-3 mb-6 mt-3 md:mb-0" style={{ width: 200 }}>
					<button
						className="w-full md:w-full flex justify-center py-2 my-1 rounded-lg text-white items-center text-sm bg-blue-700 focus:outline-none"
					>
						<Link
							className="inline-flex justify-center items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-500 dark:hover:text-gray-200"
							to="/upload-berita"
						>
							<svg
								className="w-5 h-5"
								aria-hidden="true"
								fill="none"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 8l-5-5-5 5M12 4.2v10.3"></path>
							</svg>
							<span className="ml-4">Tambah Berita</span>
						</Link>
					</button>
				</div>

				{/** Filter Koperasi - ADM CUSO ONLY*/}
				{decodeValue(localStorage.getItem("auth"))?.CU_Id === null ? (
					<div>
						<div className="flex justify-center">
							<div style={{ maxWidth: 450 }}>
								<div className="bg-white border border-gray-300 rounded-md shadow-md p-2">
									<div className="flex flex-col w-full">
										<label className="mb-1 font-semibold">Nama Koperasi</label>
										<div className="w-full">
											<select onChange={handleFilterKoperasi} className="block bg-white border border-gray-300 hover:border-gray-500 cursor-pointer disabled:cursor-not-allowed p-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
												style={{ minWidth: 350, maxWidth: 350 }} value={CU_Id} disabled={isLoading ? true : false}>
												<option selected value={"all"}>Semua Koperasi</option>
												{listCU.map((data, idx) => {
													return <option value={data.CU_Id} key={idx}>{data.CU_Id} - {data.Nama_CU}</option>;
												})}
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : null}
			</div>

			{/** List Berita */}
			<div className="bg-slate-100 flex justify-left ml-8 flex-wrap">
				{/** Card */}
				{
					listBerita ? listBerita.map((produk, idx) => {
						return (
							<div key={idx} className="border border-gray-300 shadow-lg mb-2 mt-2 mx-3 rounded-lg flex flex-col content-center overflow relative" style={{ height: 550, width: 300 }}>
								<div className="bg-gray-200 rounded-t-lg" style={{ height: 300 }}>
									<img
										className="object-cover rounded-t-lg w-full"
										style={{ height: 300, objectFit: 'contain' }}
										src={produk.Foto_URL}
										alt=""
									/>
								</div>
								{/* CONTENT */}
								<div className="p-3">
									<div className="text-sm font-['Open_Sans'] truncate" style={{ height: 20, maxWidth: 265 }}>
										{listCU.find(cu => cu.CU_Id === produk.CU_Id) ? listCU.find(cu => cu.CU_Id === produk.CU_Id).Nama_CU : ''}
									</div>
									<div className="mb-2 flex flex-col font-['Open_Sans']">
										<p className="font-bold truncate" style={{ maxWidth: 300 }}>{produk.Judul}</p>
										<div className="text-xs font-semibold font-['Open_Sans']">{produk.Tanggal.split('T')[0].split("-").reverse().join("/")}</div>
									</div>
									<div style={{ fontSize: 14, marginTop: 14 }} dangerouslySetInnerHTML={{ __html: handleTruncateHtml(produk.Informasi_Detail, 150) }} />
								</div>
								<div className="flex flex-wrap h-14 justify-center w-full mb-2 -ml-2 absolute bottom-0">
									<Link to={`/update-berita/${produk.Kode_News}`}>
										<button
											type="button"
											className="w-full m-3 flex justify-center py-2 rounded-lg text-white items-center text-sm bg-green-700 focus:outline-none"
										>
											<svg
												className="w-5 h-5"
												aria-hidden="true"
												fill="none"
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
												<polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
											</svg>
											Edit
										</button>
									</Link>
								</div>
							</div>
						)
					}) : null
				}
			</div>

			{/* LOADING COMPONENT */}
			{isLoading && (
				<LoadingComponent />
			)}
		</main>
	);
}
