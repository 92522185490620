// CONFIG
import axios from "../../config/axiosinstance";
// HELPERS
import { decodeValue } from "../../utils";
// COMPONENTS
import { SwalNotify } from "../../components";

export const fetchListSimpananAnggota = (payload) => {
  return (dispatch) => {
    dispatch({
      type: "SET_LIST_SIMPANAN_ANGGOTA_LOADING",
      payload: true,
    });
    axios({
      method: "GET",
      url: payload,
      headers: {
        token: decodeValue(localStorage.getItem("auth")).token,
      },
    })
      .then(({ data }) => {
        data.data.forEach((element, idx) => {
          element.idx = idx;
        });
        dispatch({
          type: "SET_LIST_SIMPANAN_ANGGOTA",
          payload: data.data,
        });
      })
      .catch(({ response }) => {
        if (response) {
          if (response?.status === 401) {
            dispatch({
              type: "SET_LOGIN_STATUS",
              payload: false,
            });
            localStorage.removeItem("auth");
          } else {
            SwalNotify(response.data.message);
          };
        };
        dispatch({
          type: "SET_LIST_SIMPANAN_ANGGOTA_LOADING",
          payload: false,
        });
      });
  };
};

export const resetListSimpananAnggota = () => {
  return (dispatch) => {
    dispatch({
      type: "SET_LIST_SIMPANAN_ANGGOTA",
      payload: null,
    });
  };
};

// HANDLE EDIT LIMIT TOPUP MOBILE
export const editLimitTopupMobile = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_LIST_SIMPANAN_ANGGOTA_LOADING",
        payload: true,
      });
      await axios({
        url: "anggota/limit-topup",
        method: "PUT",
        headers: {
          token: decodeValue(localStorage.getItem("auth")).token,
        },
        data: payload,
      });
      // SUCCSESS SUBMIT
      return true;
    } catch ({ response }) {
      if (response) {
        if (response?.status === 401) {
          dispatch({
            type: "SET_LOGIN_STATUS",
            payload: false,
          });
          localStorage.removeItem("auth");
        } else {
          SwalNotify(response.data.message);
        };
      };
      // FAIL TO SUBMIT
      return null;
    } finally {
      dispatch({
        type: "SET_LIST_SIMPANAN_ANGGOTA_LOADING",
        payload: false,
      });
    };
  };
};
