const decodeValue = (data) => {
    try {
        const sliceStr = data.slice(15);
        const result = JSON.parse(atob(sliceStr));

        return result;
    } catch (error) {
        return;
    };
};

export default decodeValue;