// PACAKGE
import { Fragment, useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
// ACTIONS
import { fetchListSimpananAnggota, editLimitTopupMobile } from "../store/actions/listSimpananAnggota";
// COMPONENTS
import { SwalSuccessNotify } from "./index";
// UTILS
import { numberDivider, inputNum } from "../utils";

export default function ModalEditLimitTopupMobile({ openModal, setOpenModal, setSelectedRow, data }) {
    const headTitle = useRef();
    const dispatch = useDispatch();
    const query = new URLSearchParams(useLocation().search);
    // DATA
    const [input, setInput] = useState("");
    // CONDITIONAL
    const [isConfirmed, setIsConfirmed] = useState(false);

    // CHANGE DOCUMENT NAME
    useEffect(() => {
        if (openModal) {
            document.title = `Edit Limit Topup Harian Mobile | CUSO DM`;
        };
    }, [openModal]);

    // SET DATA IF EDIT
    useEffect(() => {
        if (data) {
            setInput({
                No_RekeningSH: data.No_RekeningSH,
                Isi_Dompet_Digital_Perhari: data.Isi_Dompet_Digital_Perhari,
                Cu_ID: data.Cu_ID
            });
        } else {
            setInput("");
        };
    }, [data, openModal]);

    // HANDLE INPUT
    const handleInput = (e) => {
        if (e.target.name === "Isi_Dompet_Digital_Perhari") {
            setInput({ ...input, [e.target.name]: inputNum(e.target.value) });
        } else {
            setInput({ ...input, [e.target.name]: e.target.value });
        };
    };

    // HANDLE CONFRIM FROM MODAL && SUBMIT DATA
    const handleConfirm = async (e) => {
        e.preventDefault();

        if (!isConfirmed) {
            return setIsConfirmed(true);
        };

        // SUBMIT DATA
        const submited = await dispatch(editLimitTopupMobile(input));
        if (submited) {
            SwalSuccessNotify("Data Berhasil Tersimpan").then(() => {
                let url = `anggota/dompet-mobile?cu_id=${input.Cu_ID}`;
                // CHECK PARAMS
                if (query.get("search")) {
                    url = url + `&search=${query.get("search")}`;
                };
                dispatch(fetchListSimpananAnggota(url));
                setOpenModal(false);
                setIsConfirmed(false);
                setSelectedRow(null);
            });
        };
    };

    return (
        <Transition show={openModal} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-20 overflow-y-auto"
                style={{ width: "100%" }}
                initialFocus={headTitle}
                static
                open={openModal}
                onClose={() => { }}
            >
                <div className="min-h-screen px-4 text-center">
                    <Dialog.Overlay className="fixed inset-0 opacity-30 bg-black" />
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <form className="inline-block w-full p-4 overflow-x-hidden overflow-y-auto text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
                            style={{ maxWidth: 500 }}
                            onSubmit={handleConfirm}
                        >
                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-700"
                            >
                                <h1 ref={headTitle} className="text-center" style={{ fontSize: 24 }}>
                                    {!isConfirmed ? "Edit Limit Topup Harian Mobile" : "Perhatian"}
                                </h1>
                                {isConfirmed ? (<p className="text-center" style={{ fontSize: 14 }}>Apakah Anda Yakin dengan Data Anggota di Bawah ini?</p>) : null}
                            </Dialog.Title>
                            <div className="mt-6">
                                {!isConfirmed ? (
                                    <>
                                        <div>
                                            <label className="block" style={{ fontSize: 14, marginTop: 8, fontWeight: 500 }}>
                                                No Rekening SH
                                            </label>
                                            <input
                                                className="w-full mt-1 text-sm border border-purple-300 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                                                name="No_RekeningSH"
                                                disabled={true}
                                                value={input.No_RekeningSH ? input.No_RekeningSH : ""}
                                            />
                                        </div>
                                        <div>
                                            <label className="block" style={{ fontSize: 14, marginTop: 8, fontWeight: 500 }}>
                                                No BA
                                            </label>
                                            <input
                                                className="w-full mt-1 text-sm border border-purple-300 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                                                disabled={true}
                                                value={data ? data.No_BA : ""}
                                                type="text"
                                            />
                                        </div>
                                        <div>
                                            <label className="block" style={{ fontSize: 14, marginTop: 8, fontWeight: 500 }}>
                                                Nama Anggota
                                            </label>
                                            <input
                                                className="w-full mt-1 text-sm border border-purple-300 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                                                disabled={true}
                                                value={data ? data.Nama : ""}
                                                type="text"
                                            />
                                        </div>
                                        <div>
                                            <label className="block" style={{ fontSize: 14, marginTop: 8, fontWeight: 500 }}>
                                                Limit Isi Dompet Digital Perhari Sebelumnya
                                            </label>
                                            <input
                                                className="w-full mt-1 text-sm border border-purple-300 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                                                disabled={true}
                                                value={data ? numberDivider(data.Isi_Dompet_Digital_Perhari) : 0}
                                                type="text"
                                            />
                                        </div>
                                        <div>
                                            <label className="block" style={{ fontSize: 14, marginTop: 8, fontWeight: 500 }}>
                                                Limit Isi Dompet Digital Perhari Saat Ini
                                            </label>
                                            <input
                                                className="w-full mt-1 text-sm border border-purple-300 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                                                name="Isi_Dompet_Digital_Perhari"
                                                type="text"
                                                value={input.Isi_Dompet_Digital_Perhari || input.Isi_Dompet_Digital_Perhari === 0 ? numberDivider(input.Isi_Dompet_Digital_Perhari) : ""}
                                                onChange={handleInput}
                                                required={true}
                                            />
                                        </div>
                                        <div>
                                            <label className="block" style={{ fontSize: 14, marginTop: 8, fontWeight: 500 }}>
                                                CU ID
                                            </label>
                                            <input
                                                className={`w-full mt-1 text-sm border border-purple-300 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input`}
                                                disabled={true}
                                                name="Cu_ID"
                                                type="text"
                                                value={input.Cu_ID ? input.Cu_ID : ""}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <div className="border border-black p-2" style={{ fontSize: 14 }}>
                                        <div>
                                            <p className="inline-block text-left" style={{ paddingRight: 5, width: 175, fontWeight: 500 }}>No. Rekening SH</p>
                                            <p className="inline-block text-right" style={{ paddingRight: 5 }}>:</p>
                                            <p className="inline-block text-left" style={{ paddingRight: 5 }}>{input.No_RekeningSH ? input.No_RekeningSH : ""}</p>
                                        </div>
                                        <div>
                                            <p className="inline-block text-left" style={{ paddingRight: 5, width: 175, fontWeight: 500 }}>No. BA</p>
                                            <p className="inline-block text-right" style={{ paddingRight: 5 }}>:</p>
                                            <p className="inline-block text-left" style={{ paddingRight: 5 }}>{data ? data.No_BA : ""}</p>
                                        </div>
                                        <div className="flex">
                                            <p className="inline-block text-left" style={{ paddingRight: 5, width: 175, fontWeight: 500 }}>Nama Anggota</p>
                                            <p className="inline-block text-right" style={{ paddingRight: 5 }}>:</p>
                                            <p className="inline-block text-left" style={{ paddingRight: 5, maxWidth: 250 }}>{data ? data.Nama : ""}</p>
                                        </div>
                                        <div>
                                            <p className="inline-block text-left" style={{ paddingRight: 5, width: 175, fontWeight: 500 }}> Limit Isi Dompet Digital</p>
                                            <p className="inline-block text-right" style={{ paddingRight: 5 }}>: Rp.</p>
                                            <p className="inline-block text-left" style={{ paddingRight: 5 }}>{input.Isi_Dompet_Digital_Perhari || input.Isi_Dompet_Digital_Perhari === 0 ? numberDivider(input.Isi_Dompet_Digital_Perhari) : ""}</p>
                                        </div>
                                        <div>
                                            <p className="inline-block text-left" style={{ paddingRight: 5, width: 175, fontWeight: 500 }}> CU ID</p>
                                            <p className="inline-block text-right" style={{ paddingRight: 5 }}>:</p>
                                            <p className="inline-block text-left" style={{ paddingRight: 5 }}>{input.Cu_ID ? input.Cu_ID : ""}</p>
                                        </div>
                                    </div>
                                )}

                            </div>
                            <div className="mt-4 flex justify-center gap-2">
                                <button
                                    type="submit"
                                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-purple-600 border border-transparent rounded-md hover:bg-purple-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-purple-600"
                                >
                                    {isConfirmed ? "Iya" : "Simpan"}
                                </button>
                                <div
                                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 cursor-pointer"
                                    onClick={() => {
                                        // CHECK IS CONFIRMED
                                        if (isConfirmed) {
                                            return setIsConfirmed(false);
                                        };
                                        setOpenModal(false);
                                        setSelectedRow(null);
                                        setTimeout(() => {
                                            setInput("");
                                            setIsConfirmed(false);
                                        }, 200);
                                    }}
                                >
                                    {isConfirmed ? "Tidak" : "Batal"}
                                </div>
                            </div>
                        </form>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
}
