import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import userReducer from "./reducers/userReducer";
import topupReducer from "./reducers/topupReducer";
import setoranReducer from "./reducers/setoranReducer";
import listtransaksiReducer from "./reducers/listtransaksiReducer";
import beritaReducer from "./reducers/beritaReducer";
import userManagementReducer from "./reducers/userManagementReducer";
import listanggotaReducer from "./reducers/listanggotaReducer";
import ruangUsahaReducer from "./reducers/ruangUsahaReducer";
import infoProdukReducer from "./reducers/infoProdukReducer";
import dashboardReducer from "./reducers/dashboardReducer";
import listSimpananAnggotaReducer from "./reducers/listSimpananAnggotaReducer";

const rootReducer = combineReducers({
  user: userReducer,
  topup: topupReducer,
  setoran: setoranReducer,
  listtransaksi: listtransaksiReducer,
  berita: beritaReducer,
  userManagement: userManagementReducer,
  listanggota: listanggotaReducer,
  ruangUsaha: ruangUsahaReducer,
  infoProduk: infoProdukReducer,
  dashboard: dashboardReducer,
  listSimpananAnggota: listSimpananAnggotaReducer
});
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
