// PACKAGE
import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// ACTIONS
import { handleLogin } from "../store/actions/userAction";
// HELPERS
import { specialCharactersReplace } from "../utils";
// COMPONENTS
import ButtonLoading from "../components/Button/ButtonLoading";
// ICONS
import { showPass, hidePass, logo } from "../assets";

export default function LoginPage() {
	const inputRef = useRef(null);
	const history = useHistory();
	const dispatch = useDispatch();
	// DATA
	const { isLogin, isLoading } = useSelector((state) => state.user);
	const [input, setInput] = useState({
		username: "",
		password: "",
	});
	// CONDTIONAL
	const [showPassword, setShowPassword] = useState(false);

	// CHANGE DOCUMENT NAME AND SET FOCUS
	useEffect(() => {
		document.title = "Login | CUSO DM";
		inputRef.current.focus();
	}, []);

	// CHECK IF USER STILL LOGIN
	useEffect(() => {
		if (isLogin) {
			history.push("/");
			setInput({
				username: "",
				password: "",
			});
		};
	}, [isLogin, history]);

	// HANDLE SUBMIT
	const submitLogin = (e) => {
		e.preventDefault();
		dispatch(handleLogin(input));
	};

	// HANDLE INPUT
	const onChangeHandler = (e) => {
		const { name, value } = e.target;
		setInput({
			...input,
			[name]: specialCharactersReplace(value),
		});
	};

	return (
		<div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
			<div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
				<div className="flex flex-col overflow-y-auto md:flex-row">
					<div className="h-32 md:h-auto md:w-1/2">
						<img
							className="object-cover w-full h-full dark:hidden"
							src={logo}
							alt="Office"
						/>
						<img
							aria-hidden="true"
							className="hidden object-cover w-full h-full dark:block"
							src="../assets/andre-benz-cXU6tNxhub0-unsplash.jpg"
							alt="Office"
						/>
					</div>
					<div className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
						<div className="w-full">
							<h1 className="mb-4 text-xl text-center font-semibold text-gray-700 dark:text-gray-200">
								Dashboard Mobile
							</h1>
							<form onSubmit={submitLogin}>
								<div>
									<label className="block text-sm">
										Username
									</label>
									<input
										className="w-full mt-1 text-sm border border-purple-300 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
										name="username"
										ref={inputRef}
										value={input.username}
										onChange={(e) => onChangeHandler(e)}
										required
									/>
								</div>
								<div className="flex flex-col relative">
									<label className="block mt-4 text-sm">
										Password
									</label>
									<input
										className="w-full mt-1 text-sm border border-purple-300 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
										name="password"
										value={input.password}
										onChange={(e) => onChangeHandler(e)}
										type={showPassword ? "text" : "password"}
										required
									/>
									<div className="bg-white absolute cursor-pointer" style={{ width: 25, height: 25, bottom: 8, right: 6, padding: 3 }} onClick={() => setShowPassword(!showPassword)}>
										<img src={showPassword ? showPass : hidePass} className="opacity-70 p-[2px]" alt="pwd-icon" style={{ width: 20, height: 20 }} />
									</div>
								</div>
								{/* BUTTON */}
								<div className="mt-4">
									{isLoading ? (
										<ButtonLoading />
									) : (
										<button
											type="submit"
											className="w-full flex justify-center py-2 my-1 rounded-lg text-white items-center text-sm bg-purple-600 focus:outline-none"
										>
											Log in
										</button>
									)}
								</div>
								{/* COPYRIGHTS */}
								<p className="text-center text-gray-500 text-xs my-3">
									Copyright&copy;{new Date().getFullYear()}.{" "}
									<span className="text-purple-600 font-bold">CUSO Dashboard Mobile</span>
								</p>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
