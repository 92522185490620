const initialState = {
  lisTransaksiPPOB: [],
  listTransaksiPPOBTotal: {
    totalCUSOPoint1: 0,
    totalCUSOPoint2: 0,
    totalRevShareCOOP: 0,
    totalRevShareCUSO: 0,
    totalTransaksi: 0,
  },
  listTransaksiBank: [],
  listTransaksiBankTotal: {
    totalBiayaTransaksi: 0,
    totalNominalCUSOPay: 0,
    totalNominalTopUp: 0,
  },
  listTransaksiAllTotal: {
    totalCUSOPoint1: 0,
    totalCUSOPoint2: 0,
    totalRevShareCOOP: 0,
    totalRevShareCUSO: 0,
    totalTransaksi: 0,
  },
  listTransaksiAll: [],
  jenisTransaksiPPOB: [],
  jenisTransaksiViaBank: [],
  jenisTransaksiAll: [],
  isLoading: false,
};

export default function listtransaksiReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_LIST_TRANSAKSI_PPOB":
      return {
        ...state,
        listTransaksiPPOB: action.payload,
        listTransaksiPPOBTotal: action.payload2,
        isLoading: false,
      };
    case "SET_LIST_TRANSAKSI_BANK":
      return {
        ...state,
        listTransaksiBank: action.payload,
        listTransaksiBankTotal: action.payload2,
        isLoading: false,
      };
    case "SET_LIST_TRANSAKSI_ALL":
      return {
        ...state,
        listTransaksiAll: action.payload,
        listTransaksiAllTotal: action.payload2,
        isLoading: false,
      };
    case "SET_LIST_JENIS_TRANSAKSI":
      return {
        ...state,
        jenisTransaksiPPOB: action.payloadPPOB,
        jenisTransaksiViaBank: action.payloadViaBank,
      };
    case "SET_LIST_TRANSAKSI_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
}
