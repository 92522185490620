const initialState = {
	listUsers: [],
	listKoperasi: [],
	listTipeUser: ["Admin CUSO", "User", "Admin CU"],
	isLoading: false,
};

function userManagementReducer(state = initialState, action) {
	switch (action.type) {
		case "SET_USER_MANAGEMENT_LOADING":
			return {
				...state,
				isLoading: true,
			};
		case "SET_USER_MANAGEMENT_LIST_USER":
			return {
				...state,
				listUsers: action.payload,
				isLoading: false,
			};
		case "SET_USER_MANAGEMENT_LIST_KOPERASI":
			return {
				...state,
				listKoperasi: action.payload,
				isLoading: false,
			};
		default:
			return state;
	}
}

export default userManagementReducer;
