// PACKAGE
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory, useParams, Link } from "react-router-dom"
import ReactExport from "react-export-excel";
// ACTIONS
import { fetchDepositTopup, searchDepositTopup } from "../store/actions/topupAction";
import { fetchCU } from "../store/actions/ruangUsahaAction";
// UTILS
import { formatRupiah, addCommas, removeNonNumeric, decodeValue } from "../utils";
// COMPONENTS
import { NominalCard, TransaksiCard, InputSearch, ButtonSearch, LoadingComponent } from "../components";

export default function DepositTopupPage() {
	const query = new URLSearchParams(useLocation().search);
	const history = useHistory();
	const params = useParams();
	const dispatch = useDispatch();
	// EXCEL
	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
	// DATA
	const { depositTopup, totalSaldo, isLoading } = useSelector((state) => state.topup);
	const { listCU } = useSelector((state) => state.ruangUsaha);

	const [currentData, setCurrentData] = useState([]);
	const [totalKoperasi, setTotalKoperasi] = useState(0);
	const [paging, setPaging] = useState({
		startNumber: 0,
		limit: 10,
		canNext: true,
		canPrev: false,
		lastIdx: null,
	});
	const [input, setInput] = useState({
		CU_Id: query.get("CU_Id") || "",
		Area: query.get("Area") || "",
		Min_Saldo: query.get("Min_Saldo") || "",
		Max_Saldo: query.get("Max_Saldo") || "",
	});

	// CHANGE DOCUMENT NAME
	useEffect(() => {
		document.title = "List Deposit Topup | CUSO DM";
	}, []);

	// FETCH DATA WHEN OPENING PAGE
	useEffect(() => {
		if (decodeValue(localStorage.getItem("auth"))) {

			let url = "dtopup/list";
			let urlQuery = "dtopup/list/?";
			const arrQuery = [];
			for (const key in input) {
				if (input[key].length > 0) {
					if (key === "Min_Saldo" || key === "Max_Saldo") {
						arrQuery.push(`${key}=${removeNonNumeric(input[key])}`);
					} else {
						arrQuery.push(`${key}=${input[key]}`);
					};
				};
			};
			urlQuery += arrQuery.join("&");
			if (urlQuery[urlQuery.length - 1] !== "?") {
				url = urlQuery;
			};

			// CHECK QUERY & CHEKC TIPE USER
			if (arrQuery.length && decodeValue(localStorage.getItem("auth"))?.tipe_user === 0) {
				dispatch(searchDepositTopup(url));
			} else {
				dispatch(fetchDepositTopup());
			};

			// FETCH CU
			dispatch(fetchCU());
		};
	}, [localStorage.getItem("auth"), params]);

	// HANDLE CREATE PAGINATION TABLE
	useEffect(() => {
		if (depositTopup.length) {
			// pagination
			if (10 >= depositTopup[depositTopup.length - 1].idx + 1) {
				setPaging({
					...paging,
					startNumber: 0,
					limit: 10,
					canNext: false,
					canPrev: false,
					lastIdx: depositTopup[depositTopup.length - 1].idx,
				});
			} else {
				setPaging({
					...paging,
					startNumber: 0,
					limit: 10,
					canNext: true,
					canPrev: false,
					lastIdx: depositTopup[depositTopup.length - 1].idx,
				});
			};
			const tempData = [];
			for (let i = 0; i < 10 && i < depositTopup.length; i++) {
				tempData.push(depositTopup[i]);
			};
			setCurrentData(tempData);
			// total koperasi
			setTotalKoperasi(depositTopup.length);
		} else {
			setCurrentData([]);
			setPaging({
				...paging,
				startNumber: 0,
				limit: 10,
				canNext: false,
				canPrev: false,
				lastIdx: 0,
			});
		};
	}, [depositTopup]);

	// HANDLE NEXT DATA TABLE
	const next = () => {
		if (paging.limit + 10 >= depositTopup.length) {
			setPaging({
				...paging,
				startNumber: paging.startNumber + 10,
				limit: paging.limit + 10,
				canNext: false,
				canPrev: true,
			});
		} else {
			setPaging({
				...paging,
				startNumber: paging.startNumber + 10,
				limit: paging.limit + 10,
				canNext: true,
				canPrev: true,
			});
		}

		const tempData = [];
		if (paging.startNumber >= -10) {
			for (
				let i = paging.startNumber + 10;
				i < paging.limit + 10 && i < depositTopup.length;
				i++
			) {
				tempData.push(depositTopup[i]);
			}
			setCurrentData(tempData);
		} else {
			setCurrentData([]);
		}
	};

	// HANDLE PREV DATA TABLE
	const prev = () => {
		if (paging.startNumber - 10 <= 0) {
			setPaging({
				...paging,
				startNumber: paging.startNumber - 10,
				limit: paging.limit - 10,
				canNext: true,
				canPrev: false,
			});
		} else {
			setPaging({
				...paging,
				startNumber: paging.startNumber - 10,
				limit: paging.limit - 10,
				canNext: true,
				canPrev: true,
			});
		}

		const tempData = [];
		if (
			paging.startNumber >= 10 &&
			paging.startNumber < depositTopup.length
		) {
			for (let i = paging.startNumber - 10; i < paging.limit - 10; i++) {
				tempData.push(depositTopup[i]);
			}
			setCurrentData(tempData);
		} else if (
			paging.startNumber >= 10 &&
			paging.lastIdx + 1 === depositTopup.length
		) {
			for (let i = paging.startNumber - 10; i < paging.lastIdx + 1; i++) {
				tempData.push(depositTopup[i]);
			}
			setCurrentData(tempData);
		} else {
			setCurrentData([]);
		}
	};

	// HANDLE INPUT
	const onChangeHandler = (e) => {
		const { name, value } = e.target;
		if (name === "Min_Saldo" || name === "Max_Saldo") {
			setInput({
				...input,
				[name]: addCommas(removeNonNumeric(value)),
			});
		} else {
			setInput({
				...input,
				[name]: value,
			});
		};
	};

	// HANDLE FILTER
	const searchHandler = (e) => {
		e.preventDefault();
		let params = new URLSearchParams();
		for (const i in input) {
			if (input[i].length > 0) {
				if (i === "Min_Saldo" || i === "Max_Saldo") {
					params.append(i, removeNonNumeric(input[i]));
				} else {
					params.append(i, input[i]);
				};
			};
		};

		history.push({ search: params.toString() });
	};

	// HANDLE RESET
	const handleReset = () => {
		setInput("");
		history.push("/deposit-topup");
	};

	return (
		<>
			{/* LOADING COMPONENT */}
			{isLoading && (<LoadingComponent />)}

			{/* CONTENT */}
			<main style={{ width: 1024 }}>
				<div className="grid px-6 mx-auto">
					<h2 className="py-3 text-2xl font-semibold bg-white w-full sticky top-0 z-10">
						List Deposit Topup
					</h2>
					{decodeValue(localStorage.getItem("auth"))?.tipe_user === 0 && (
						<div className="border border-gray-300 rounded-lg p-2 mb-4">
							<div className="grid md:grid-cols-4 grid-cols-1 gap-4">
								<TransaksiCard title="Total Koperasi" transaksi={totalKoperasi} />
								<NominalCard title="Total Saldo" nominal={totalSaldo} />
							</div>
							<div className="flex flex-row justify-between gap-8 px-4 my-3">
								<div className="w-full flex flex-col">
									<div className="flex justify-between items-center my-1 md:text-lg">
										<label className="w-1/2 text-sm text-gray-700 dark:text-gray-400">List Koperasi</label>
										<select className="block w-full mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
											onChange={onChangeHandler}
											name="CU_Id"
											value={input.CU_Id ? input.CU_Id : ""}>
											<option value="">Semua Koperasi</option>
											{listCU.map((data, idx) => {
												return (
													<option value={data.CU_Id} key={idx}>{data.CU_Id} - {data.Nama_CU}</option>
												)
											})}
										</select>
									</div>
									<InputSearch
										label="Area"
										name="Area"
										value={input.Area ? input.Area : ""}
										onChange={(e) => {
											onChangeHandler(e);
										}}
									/>
								</div>
								<div className="w-full flex flex-col justify-between">
									<InputSearch
										label="Min Saldo"
										name="Min_Saldo"
										value={input.Min_Saldo ? input.Min_Saldo : ""}
										onChange={(e) => {
											onChangeHandler(e);
										}}
									/>
									<InputSearch
										label="Max Saldo"
										name="Max_Saldo"
										value={input.Max_Saldo ? input.Max_Saldo : ""}
										onChange={(e) => {
											onChangeHandler(e);
										}}
									/>
									<div className="flex">
										<ButtonSearch onClick={(e) => searchHandler(e)} />
										<button
											onClick={handleReset}
											className="w-full py-2 my-1 ml-4 rounded-lg text-white items-center text-sm bg-purple-600 hover:bg-purple-800 focus:outline-none"
										>
											Reset
										</button>
									</div>
								</div>
							</div>
						</div>
					)}
					<div className="w-full rounded-lg shadow-md border border-gray-300 mb-2">
						<div className="sticky top-0 w-full overflow-x-auto rounded-t-lg h-85vh">
							<table className="relative w-full whitespace-no-wrap">
								<thead>
									<tr className="text-xs font-semibold tracking-wide text-center bg-gray-200 text-gray-500 uppercase border-b">
										<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-200 dark:text-gray-400 dark:bg-gray-800">
											No
										</th>
										<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-200 dark:text-gray-400 dark:bg-gray-800">
											Koperasi Id
										</th>
										<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-200 dark:text-gray-400 dark:bg-gray-800">
											Area
										</th>
										<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-200 dark:text-gray-400 dark:bg-gray-800">
											Koperasi
										</th>
										<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-200 dark:text-gray-400 dark:bg-gray-800">
											Saldo
										</th>
										<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-200 dark:text-gray-400 dark:bg-gray-800">
											Action
										</th>
									</tr>
								</thead>
								<tbody className="bg-white divide-y">
									{currentData?.length ? currentData.map((d, index) => {
										return (
											<tr className="border border-t-0 border-l-0 border-r-0 border-gray-300 hover:bg-blue-100">
												<td className="px-4 py-3 text-sm text-center">{index + paging.startNumber + 1}</td>
												<td className="px-4 py-3 text-sm text-center">{d.CU_Id}</td>
												<td className="px-4 py-3 text-sm">{d.Area}</td>
												<td className="px-4 py-3 text-sm">{d.Nama_CU}</td>
												<td className="px-4 py-3 text-sm truncate">
													{formatRupiah(d.Deposit_TopUp)}
												</td>
												<td className="px-4 py-3 flex justify-center">
													<Link to={`/history-deposit-topup/${d.CU_Id}`}>
														<button
															className="flex items-center justify-between px-2 py-1 text-sm font-medium leading-5 text-purple-600 rounded-md dark:text-purple-600 focus:outline-none focus:shadow-outline-gray bg-gray-200"
															aria-label="Edit"
														>
															Detail
														</button>
													</Link>
												</td>
											</tr>
										);
									}) : null}
								</tbody>
							</table>
						</div>
						{/* PAGIANTION & EXCEL */}
						<div className="flex justify-between flex-wrap px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t">
							<span className="flex items-center">
								<span>
									Showing{" "}
									{depositTopup.length > 0
										? `${paging.startNumber + 1}-${paging.startNumber + currentData.length
										} of ${depositTopup.length} |`
										: "0-0 of 0 |"}
								</span>
								<span>
									<ExcelFile
										element={
											<button className="flex w-full items-center justify-center px-4 py-2 text-sm font-medium leading-5 text-green-600 hover:text-green-700 focus:outline-none focus:shadow-outline-green">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													class="h-6 w-6"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														stroke-width="2"
														d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
													/>
												</svg>
												<span className="ml-2">Download as Excel</span>
											</button>
										}
										filename="Deposit Topup"
									>
										<ExcelSheet data={depositTopup} name="Deposit Topup">
											<ExcelColumn label="No" value={(col) => col.idx + 1} />
											<ExcelColumn label="CU ID" value="CU_Id" />
											<ExcelColumn label="Area" value="Area" />
											<ExcelColumn label="Koperasi" value="Nama_CU" />
											<ExcelColumn label="Saldo" value="Deposit_TopUp" />
										</ExcelSheet>
									</ExcelFile>
								</span>
							</span>
							<span className="col-span-2"></span>
							<span className="flex col-span-4 mt-2 sm:mt-auto justify-end">
								<nav aria-label="Table navigation">
									<ul className="inline-flex items-center py-2">
										<li className="mx-2">
											<button
												className={`px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple ${paging.canPrev
													? "bg-purple-600 hover:bg-purple-800"
													: "bg-purple-300 cursor-default"
													} text-white`}
												disabled={paging.canPrev ? false : true}
												onClick={prev}
											>
												PREV
											</button>
										</li>
										<li>
											<button
												className={`px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple ${paging.canNext
													? "bg-purple-600 hover:bg-purple-800"
													: "bg-purple-300 cursor-default"
													} text-white`}
												disabled={paging.canNext ? false : true}
												onClick={next}
											>
												NEXT
											</button>
										</li>
									</ul>
								</nav>
							</span>
						</div>
					</div>
				</div>
			</main>
		</>

	);
}
