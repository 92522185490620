// CONFIG
import axios from "../../config/axiosinstance";
// HELPERS
import { decodeValue } from "../../utils";
// COMPONENTS
import { SwalNotify } from "../../components";

//Fetch CU
export const fetchCU = () => {
	return (dispatch) => {
		dispatch({
			type: "SET_INFO_PRODUK_LOADING",
			payload: true,
		});
		axios({
			method: "GET",
			url: "rusaha/cu",
			headers: {
				token: decodeValue(localStorage.getItem("auth")).token,
			},
		})
			.then(({ data }) => {
				dispatch({
					type: "SET_LIST_CU",
					payload: data.data,
				});
			})
			.catch(({ response }) => {
				if (response) {
					if (response?.status === 401) {
						dispatch({
							type: "SET_LOGIN_STATUS",
							payload: false,
						});
						localStorage.removeItem("auth");
					} else {
						SwalNotify(response.data.message);
					};
				};
				dispatch({
					type: "SET_INFO_PRODUK_LOADING",
					payload: false,
				});
			});
	};
};

//Fetch InfoProduk
export const fetchInfoProduk = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_LIST_INFO_PRODUK",
		});
		dispatch({
			type: "SET_INFO_PRODUK_LOADING",
			payload: true,
		});
		axios({
			method: "GET",
			url: `infoproduk/${payload}`,
			headers: {
				token: decodeValue(localStorage.getItem("auth")).token,
			},
		})
			.then(({ data }) => {
				dispatch({
					type: "SET_LIST_INFO_PRODUK",
					payload: data.data,
				});
			})
			.catch(({ response }) => {
				if (response) {
					if (response?.status === 401) {
						dispatch({
							type: "SET_LOGIN_STATUS",
							payload: false,
						});
						localStorage.removeItem("auth");
					} else {
						SwalNotify(response.data.message);
					};
				};
				dispatch({
					type: "SET_INFO_PRODUK_LOADING",
					payload: false,
				});
			});
	};
};

// RESET DATA INFO PRODUK
export const resetInfoProduk = () => {
	return (dispatch) => {
		dispatch({
			type: "SET_LIST_INFO_PRODUK",
		});
	};
};

//Fetch Current Produk
export const fetchCurrentProduk = (koperasi, produk) => {
	return (dispatch) => {
		dispatch({
			type: "SET_INFO_PRODUK_LOADING",
			payload: true,
		});
		axios({
			method: "GET",
			url: `infoproduk/${koperasi}`,
			headers: {
				token: decodeValue(localStorage.getItem("auth")).token,
			},
		})
			.then(({ data }) => {
				const current = data.data.find((item) => item.Kode_Produk == produk);
				dispatch({
					type: "SET_CURRENT_PRODUK",
					payload: current,
				});
			})
			.catch(({ response }) => {
				if (response) {
					if (response?.status === 401) {
						dispatch({
							type: "SET_LOGIN_STATUS",
							payload: false,
						});
						localStorage.removeItem("auth");
					} else {
						SwalNotify(response.data.message);
					};
				};
				dispatch({
					type: "SET_INFO_PRODUK_LOADING",
					payload: false,
				});
			});
	};
};

//Update Info Produk
export const updateInfoProduk = (payload, id) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: "SET_INFO_PRODUK_LOADING",
				payload: true,
			});
			await axios({
				method: "PUT",
				url: `infoproduk/${id}`,
				headers: {
					"Content-Type": "multipart/form-data",
					token: decodeValue(localStorage.getItem("auth")).token,
				},
				data: payload,
			});

			// SUCCSESS SUBMIT
			return true;
		} catch ({ response }) {
			if (response) {
				if (response?.status === 401) {
					dispatch({
						type: "SET_LOGIN_STATUS",
						payload: false,
					});
					localStorage.removeItem("auth");
				} else {
					SwalNotify(response.data.message);
				};
			};
			// FAIL TO SUBMIT
			return null;
		} finally {
			dispatch({
				type: "SET_INFO_PRODUK_LOADING",
				payload: false,
			});
		};
	};
};
