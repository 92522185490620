const initialState = {
	transactionData: [],
    trxSuccess: [],
    trxPending: [],
    trxFailed: [],
    trxLabel: [],
	trxType: [],
	userData: {},
	totalUser: 0,
	newUser: 0,
	generation: [],
	generationTotal: [],
	isLoading: false,
};

export default function dashboardReducer(state = initialState, action) {
	switch (action.type) {
		case "SET_TRX_DATA":
			return {
				...state,
				transactionData: action.payload.allData,
                trxSuccess: action.payload.success,
                trxPending: action.payload.pending,
                trxFailed: action.payload.failed,
                trxLabel: action.payload.label,
				isLoading: false,
			};
		case "SET_TRX_TYPE":
			return {
				...state,
				trxType: action.payload,
				isLoading: false,
			};
		case "SET_USER_STATS":
			return {
				...state,
				userData: action.payload.allData,
				totalUser: action.payload.totalUser,
                newUser: action.payload.newUser,
                generation: action.payload.generation,
                generationTotal: action.payload.generationTotal,
				isLoading: false,
			};
        case "SET_DASHBOARD_LOADING":
			return {
				...state,
				isLoading: action.payload,
			};
		default:
			return state;
	}
}
