// PACKAGE
import DatePicker from "react-datepicker";
import { Bar, Pie } from "react-chartjs-2";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// ACTIONS
import { fetchTRX, fetchUser, fetchTRXType, fetchTRXByStatus } from "../store/actions/dashboardAction";
import { fetchCU } from "../store/actions/ruangUsahaAction";
// HELPERS
import { addCommas, decodeValue } from "../utils";

export default function DashboardPage() {
  const dispatch = useDispatch();
  const topRef = useRef();
  // DATA
  const { trxSuccess, trxFailed, trxPending, trxLabel,
    totalUser, newUser, generation, generationTotal, trxType, isLoading } = useSelector((state) => state.dashboard);
  const { listCU } = useSelector((state) => state.ruangUsaha);
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1));
  const [endDate, setEndDate] = useState(new Date());
  const [koperasi, setKoperasi] = useState('all');
  const [tipeTransaksi, setTipeTransaksi] = useState('all');

  // CHANGE DOCUMENT NAME AND SET FOCUS
  useEffect(() => {
    document.title = "Dashboard | CUSO DM";
  }, []);

  useEffect(() => {
    if (topRef?.current) {
      topRef.current.scrollIntoView({ behavior: "smooth", block: 'end' });
    }
  }, [topRef.current]);

  // HANDLE FETCH DATA WHEN PAGE LOADED
  useEffect(() => {
    if (decodeValue(localStorage.getItem("auth"))) {
      // SET PAYLOAD
      let payload = {
        start_date: startDate.toISOString().split("T")[0],
        end_date: endDate.toISOString().split("T")[0]
      };
      // CHECK CU ID
      if (decodeValue(localStorage.getItem("auth")).CU_Id === 'null') {
        setKoperasi('all');
        payload = {
          ...payload,
          CU_Id: 'all'
        };
      } else {
        setKoperasi(decodeValue(localStorage.getItem("auth")).CU_Id);
        payload = {
          ...payload,
          CU_Id: decodeValue(localStorage.getItem("auth")).CU_Id
        };
      };

      dispatch(fetchTRX(payload));
      dispatch(fetchUser(payload));
      dispatch(fetchCU())
      dispatch(fetchTRXType())
    };
  }, [localStorage.getItem("auth")]);

  // HANDLE SET START DATE
  const startDateHandler = (newDate) => {
    // SET START DATE
    setStartDate(newDate);
    // SET PAYLOAD
    let payload = {
      CU_Id: koperasi,
      start_date: newDate.toISOString().split("T")[0],
      end_date: endDate.toISOString().split("T")[0]
    };
    // FETCH DATA
    if (tipeTransaksi !== 'all') {
      dispatch(fetchTRXByStatus({ ...payload, trx_type: tipeTransaksi }))
    } else {
      dispatch(fetchTRX(payload));
    };
    dispatch(fetchUser(payload));
  };

  // HANDLE SET END DATE
  const endDateHandler = (newDate) => {
    // SET END DATE
    setEndDate(newDate);
    // SET PAYLOAD
    let payload = {
      CU_Id: koperasi,
      start_date: startDate.toISOString().split("T")[0],
      end_date: newDate.toISOString().split("T")[0]
    };
    // FETCH DATA
    if (tipeTransaksi !== 'all') {
      dispatch(fetchTRXByStatus({ ...payload, trx_type: tipeTransaksi }));
    } else {
      dispatch(fetchTRX(payload));
    };
    dispatch(fetchUser(payload));
  };

  // HANDLE FILTER KOPERASI
  function handleFilterKoperasi(e) {
    // SET KOPERASI
    setKoperasi(e.target.value);
    // SET PAYLOAD
    let payload = {
      CU_Id: e.target.value,
      start_date: startDate.toISOString().split("T")[0],
      end_date: endDate.toISOString().split("T")[0]
    };
    // FETCH DATA
    if (tipeTransaksi !== 'all') {
      dispatch(fetchTRXByStatus({ ...payload, trx_type: tipeTransaksi }));
    } else {
      dispatch(fetchTRX(payload));
    };
    dispatch(fetchUser(payload));
  };

  // HANDLE TIPE TRANSAKSI
  function handleTipeTransaksi(e) {
    // SET TIPE TRANSAKSI
    setTipeTransaksi(e.target.value);
    // SET PAYLOAD
    let payload = {
      CU_Id: koperasi,
      start_date: startDate.toISOString().split("T")[0],
      end_date: endDate.toISOString().split("T")[0]
    };
    if (e.target.value === 'all') {
      dispatch(fetchTRX(payload));
    } else {
      dispatch(fetchTRXByStatus({ ...payload, trx_type: e.target.value }));
    };
  };

  // HANDLE QUICK FILTER
  function quickDateFilter(e) {
    let date = new Date();
    let start_date;
    let end_date;

    // CHECK FILTER
    if (e.target.value === 'Today') {
      start_date = date;
      end_date = date;
    } else if (e.target.value === 'Yesterday') {
      start_date = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
      end_date = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
    } else if (e.target.value === 'Last7Days') {
      start_date = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 6);
      end_date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    } else if (e.target.value === 'Last30Days') {
      start_date = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 30);
      end_date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    } else if (e.target.value === 'ThisMonth') {
      start_date = new Date(date.getFullYear(), date.getMonth(), 1);
      end_date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    } else if (e.target.value === 'ThisYear') {
      start_date = new Date(date.getFullYear(), 0, 1);
      end_date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    };

    // SET START DATE/END DATE
    setStartDate(start_date);
    setEndDate(end_date);

    // SET PAYLOAD
    const payload = {
      CU_Id: koperasi,
      start_date: start_date.toISOString().split("T")[0],
      end_date: end_date.toISOString().split("T")[0]
    };

    // FETCH DATA
    if (tipeTransaksi !== 'all') {
      payload.trx_type = tipeTransaksi
      dispatch(fetchTRXByStatus(payload))
    } else {
      dispatch(fetchTRX(payload));
    };
    dispatch(fetchUser(payload));
  };

  // BAR CHART PROPERTIES 
  const trxBarOptions = {
    scales: {
      xAxes: [{
        stacked: true
      }],
      yAxes: [{
        stacked: true
      }]
    },
    cornerRadius: 20,
    aspectRatio: 4,
  };

  // BAR CHART DATA SET
  let trxBarData = {
    datasets: [{
      label: 'Berhasil',
      data: trxSuccess,
      backgroundColor: "rgba(37, 159, 81, 1)"
    },
    {
      label: 'Gagal',
      data: trxFailed,
      backgroundColor: "rgba(214, 34, 34, 1)"
    },
    {
      label: 'Pending',
      data: trxPending,
      backgroundColor:
        "rgba(242, 183, 113, 1)"
    }],
    labels: trxLabel
    // labels: Object.keys(transactionData)
  };

  // PIE CHARTS DATA SET
  const userPieOptions = {
    responsive: false,
    /**
     * Default legends are ugly and impossible to style.
     * See examples in charts.html to add your own legends
     *  */
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: true,
        color: "white",
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map((data) => {
            return (sum += data);
          });
          let percentage = ((value * "100%") / sum).toFixed(2) + "%";
          return percentage;
        },
      },
    },
  };

  // PIE CHARTS PROPERTIES
  let userPieData = {
    datasets: [
      {
        label: 'My First Dataset',
        data: generationTotal,
        backgroundColor: ["#0694a2", "#1c64f2", "#7e3af2", "#f2b51c", "#f2a11c"],
      },
    ],
    labels: generation,
  };

  return (
    <div className="flex flex-col">
      {/* TOP REF */}
      <div className="bg-transparent" ref={topRef} />

      {/* TOP FILTER LIST */}
      <header className="sticky top-0 z-10 py-2 bg-white w-full flex justify-center items-center">
        <div className="flex flex-row items-end pl-2 pt-2">
          {/** Tipe Transaksi */}
          <div class="flex flex-col max-w-72 bg-white border border-gray-300 rounded-md shadow-md p-2 mr-2">
            <label className="mb-1 text-sm font-semibold">Tipe Transaksi</label>
            <select className="text-sm border border-gray-400 rounded-md py-1 px-1 hover:border-gray-500" onChange={handleTipeTransaksi} style={{ maxWidth: 300, minWidth: 300 }}>
              <option value="all">Semua Transaksi</option>
              {trxType.map((data, idx) => {
                if (tipeTransaksi === data.Jenis_Transaksi_Detail) {
                  return (
                    <option value={data.Jenis_Transaksi_Detail} key={idx} selected>{data.Nama_Jenis_Transaksi_Detail}</option>
                  );
                } else {
                  return (
                    <option value={data.Jenis_Transaksi_Detail} key={idx}>{data.Nama_Jenis_Transaksi_Detail}</option>
                  );
                }
              })}
            </select>
          </div>

          <div className="flex bg-white border border-gray-300 rounded-md shadow-md p-2 mr-2">
            {/** Start Date */}
            <div className="flex flex-col">
              <label className="mb-1 text-sm font-semibold">Start Date</label>
              <DatePicker
                className="text-sm border w-24 border-gray-400 rounded-md py-1 px-1 mr-2 hover:border-gray-500"
                selected={startDate}
                placeholderText="start date"
                dateFormat="dd/MM/yyyy"
                onChange={(date) => startDateHandler(date)}
              />
            </div>

            {/** START DATE/END DATE DEVIDER */}
            <div className="mt-6">
              <label className="text-sm font-semibold mr-2">To</label>
            </div>

            {/** End Date */}
            <div className="flex flex-col">
              <label className="mb-1 text-sm font-semibold">End Date</label>
              <DatePicker
                className="text-sm border w-24 border-gray-400 rounded-md py-1 px-1 hover:border-gray-500"
                selected={endDate}
                placeholderText="end date"
                dateFormat="dd/MM/yyyy"
                // isClearable
                onChange={(date) => endDateHandler(date)}
              />
            </div>
          </div>

          {/** Date Quick Filter */}
          <div className="flex flex-col bg-white border border-gray-300 rounded-md shadow-md p-2 mr-2">
            <label className="mb-1 text-sm font-semibold">Quick Filter</label>
            <select onChange={quickDateFilter} className="text-sm border border-gray-400 rounded-md py-1 px-1 hover:border-gray-500">
              <option selected hidden>
                Filter
              </option>
              <option value="Today">Today</option>
              <option value="Yesterday">Yesterday</option>
              <option value="Last7Days">Last 7 Days</option>
              <option value="Last30Days">Last 30 Days</option>
              <option value="ThisMonth">This Month</option>
              <option value="ThisYear">This Year</option>
            </select>
          </div>

          {/** LIST KOPERASI // ADM ONLY */}
          {decodeValue(localStorage.getItem("auth"))?.CU_Id === null ? (
            <div class="max-w-72 flex flex-col bg-white border border-gray-300 rounded-md shadow-md p-2">
              <label className="mb-1 text-sm font-semibold">List Koperasi</label>
              <select className="text-sm border border-gray-400 rounded-md py-1 hover:border-gray-500 hover:cursor-pointer" onChange={handleFilterKoperasi} style={{ maxWidth: 300, minWidth: 300 }}>
                <option value="all">Semua Koperasi</option>
                {listCU.map((data, idx) => {
                  return (
                    <option value={data.CU_Id} key={idx}>{data.CU_Id} - {data.Nama_CU}</option>
                  )
                })}
              </select>
            </div>
          ) : null}
        </div>
      </header>

      {/* <!-- Main content --> */}
      <div className="h-full mx-5">
        {/* <!-- Bars chart --> */}
        <div className="bg-white shadow-lg w-full rounded-lg">
          {/* BAR CHARTS */}
          <div className="flex flex-col">
            <h4 className="font-semibold text-xl">Total Transaksi</h4>
            {isLoading ? (
              <div className="flex justify-center">
                <div className={`bg-gray-300 w-full animate-pulse rounded-md mt-2`} style={{ height: 300 }} />
              </div>
            ) : (
              <Bar data={trxBarData} options={trxBarOptions} height={95} />
            )}
          </div>
        </div>

        <div className="flex">
          {/* <!-- Total User --> */}
          <div className="bg-white shadow-lg md:w-1/4 h-50 mb-2 mt-10 mx-5 rounded-lg flex flex-col content-left relative">
            <div className="bg-slate-300 flex align-center justify-left ml-10">
              <img
                className="h-24 w-24 object-cover"
                src="https://i.imgur.com/HGULiq6.png"
                alt=""
              />
            </div>
            <div className="h-25 ml-10 mt-1 w-40 text-xl font-semibold font-['Open_Sans']">
              Total Pengguna Mobile Apps
            </div>
            {
              isLoading ? (<div className={`bg-gray-300 animate-pulse rounded-md mt-2 ml-10`} style={{ width: 100, height: 25 }} />)
                : totalUser || totalUser === 0 ?
                  <div className="m-3 ml-10 h-10 font-semibold text-2xl font-['Open_Sans']">{addCommas(totalUser)}</div>
                  : null
            }
          </div>

          {/* <!-- New User --> */}
          <div className="bg-white shadow-lg md:w-1/4 h-50 mb-2 mt-10 mx-5 rounded-lg flex flex-col content-center relative">
            <div className="bg-white flex align-center justify-left ml-10">
              <img
                className="h-24 w-24 object-cover"
                src="https://i.imgur.com/wRj79Ho.png"
                alt=""
              />
            </div>
            <div className="h-25 ml-10 mt-1 w-40 text-xl font-semibold font-['Open_Sans']">
              Pengguna Baru Mobile Apps
            </div>
            {
              isLoading ? (
                <div className={`bg-gray-300 animate-pulse rounded-md mt-2 ml-10`} style={{ width: 100, height: 25 }} />)
                : newUser || newUser === 0 ?
                  <div className="m-3 mb-0 ml-10 h-10 font-semibold text-2xl font-['Open_Sans']">{addCommas(newUser)}</div>
                  : null
            }
            {
              isLoading ? (
                <div className={`bg-gray-300 animate-pulse rounded-md mt-2 ml-10`} style={{ width: 100, height: 25 }} />)
                : newUser || newUser === 0 ?
                  <div className="m-3 mb-0 ml-10 h-10 font-semibold text-sm font-['Open_Sans'] text-green-600">+{newUser === 0 ? 0 : addCommas(Math.floor(newUser / ((endDate - startDate) / (1000 * 60 * 60 * 24))))}/day</div>
                  : null
            }
          </div>

          {/* <!-- Pie Chart --> */}
          <div className="bg-white shadow-lg h-50 mt-10 ml-5 rounded-lg flex flex-col content-start justify-start relative" style={{ maxHeight: 260 }}>
            <div className="bg-white flex align-center justify-center font-semibold font-['Open_Sans'] text-xl">
              Usia Pengguna Mobile Apps
            </div>
            {isLoading ? (
              <div className={`bg-gray-300 w-full animate-pulse rounded-md mt-2`} style={{ height: 220 }} />
            ) : (
              <>
                <div className="bg-white md:w-full h-50 rounded-lg mt-5 mb-5 flex flex-col content-center justify-center relative">
                  <div className="flex flex-col mx-auto items-left align-center justify-center w-3/4">
                    <Pie
                      data={userPieData}
                      options={userPieOptions}
                      height={147}
                    />
                  </div>

                  {/* <!-- Chart legend --> */}
                  <div className="flex flex-wrap mx-auto items-left align-center ml-5 mr-5 justify-start w-full">
                    <div class="flex md:w-1/3 items-center">
                      <span class="inline-block w-3 h-3 mr-1 rounded-full" style={{ backgroundColor: "#0694a2" }}></span>
                      <span>Post War</span>
                    </div>
                    <div class="flex md:w-1/3 items-center">
                      <span class="inline-block w-3 h-3 mr-1 rounded-full" style={{ backgroundColor: "#1c64f2" }}></span>
                      <span>Boomers</span>
                    </div>
                    <div class="flex md:w-1/3 items-center">
                      <span class="inline-block w-3 h-3 mr-1 rounded-full" style={{ backgroundColor: "#7e3af2" }}></span>
                      <span>Gen X</span>
                    </div>
                    <div class="flex md:w-1/3 items-center">
                      <span class="inline-block w-3 h-3 mr-1 rounded-full" style={{ backgroundColor: "#f2b51c" }}></span>
                      <span>Millenials</span>
                    </div>
                    <div class="flex md:w-1/3 items-center">
                      <span class="inline-block w-3 h-3 mr-1 rounded-full" style={{ backgroundColor: "#f2a11c" }}></span>
                      <span>Gen Z</span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div >
      </div >
    </div >
  );
}
