// CONFIG
import axios from "../../config/axiosinstance";
// HELPERS
import { decodeValue } from "../../utils";
// COMPONENTS
import { SwalNotify } from "../../components";

export const fetchDepositSetoran = () => {
	return (dispatch) => {
		dispatch({
			type: "SET_SETORAN_LOADING",
			payload: true,
		});
		axios({
			method: "GET",
			url: "dsetoran/list",
			headers: {
				token: decodeValue(localStorage.getItem("auth")).token,
			},
		})
			.then(({ data }) => {
				data.data.forEach((element, idx) => {
					element.idx = idx;
				});
				dispatch({
					type: "SET_DEPOSIT_SETORAN",
					payload: data.data,
				});
				dispatch({
					type: "SET_SALDO_SETORAN",
					payload: data.totalSaldo,
				});
			})
			.catch(({ response }) => {
				if (response) {
					if (response?.status === 401) {
						dispatch({
							type: "SET_LOGIN_STATUS",
							payload: false,
						});
						localStorage.removeItem("auth");
					} else {
						SwalNotify(response.data.message);
					};
				};
				dispatch({
					type: "SET_SETORAN_LOADING",
					payload: false,
				});
			});
	};
};

export const searchDepositSetoran = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_SETORAN_LOADING",
			payload: true,
		});
		axios({
			method: "GET",
			url: payload,
			headers: {
				token: decodeValue(localStorage.getItem("auth")).token,
			},
		})
			.then(({ data }) => {
				data.data.forEach((element, idx) => {
					element.idx = idx;
				});
				dispatch({
					type: "SET_DEPOSIT_SETORAN",
					payload: data.data,
				});
				dispatch({
					type: "SET_SALDO_SETORAN",
					payload: data.totalSaldo,
				});
			})
			.catch(({ response }) => {
				if (response) {
					if (response?.status === 401) {
						dispatch({
							type: "SET_LOGIN_STATUS",
							payload: false,
						});
						localStorage.removeItem("auth");
					} else {
						SwalNotify(response.data.message);
					};
				};
				dispatch({
					type: "SET_SETORAN_LOADING",
					payload: false,
				});
			});
	};
};

export const fetchHistorySetoran = (id) => {
	return (dispatch) => {
		dispatch({
			type: "SET_SETORAN_LOADING",
			payload: true,
		});
		axios({
			method: "GET",
			url: `dsetoran/history/${id}`,
			headers: {
				token: decodeValue(localStorage.getItem("auth")).token,
			},
		})
			.then(({ data }) => {
				data.data.forEach((element, idx) => {
					element.idx = idx;
				});
				dispatch({
					type: "SET_HISTORY_SETORAN",
					payload: data.data,
					namaKoperasi: data.namaKoperasi,
				});
			})
			.catch(({ response }) => {
				if (response) {
					if (response?.status === 401) {
						dispatch({
							type: "SET_LOGIN_STATUS",
							payload: false,
						});
						localStorage.removeItem("auth");
					} else {
						SwalNotify(response.data.message);
					};
				};
				dispatch({
					type: "SET_SETORAN_LOADING",
					payload: false,
				});
			});
	};
};

export const searchHistorySetoran = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_SETORAN_LOADING",
			payload: true,
		});
		axios({
			method: "GET",
			url: payload,
			headers: {
				token: decodeValue(localStorage.getItem("auth")).token,
			},
		})
			.then(({ data }) => {
				data.data.forEach((element, idx) => {
					element.idx = idx;
				});
				dispatch({
					type: "SET_HISTORY_SETORAN",
					payload: data.data,
					namaKoperasi: data.namaKoperasi,
				});
			})
			.catch(({ response }) => {
				if (response) {
					if (response?.status === 401) {
						dispatch({
							type: "SET_LOGIN_STATUS",
							payload: false,
						});
						localStorage.removeItem("auth");
					} else {
						SwalNotify(response.data.message);
					};
				};
				dispatch({
					type: "SET_SETORAN_LOADING",
					payload: false,
				});
			});
	};
};
