// PACKAGE
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory, useParams } from "react-router-dom"
import ReactExport from "react-export-excel";
// ACTIONS
import { fetchHistoryTopup, searchHistoryTopup } from "../store/actions/topupAction";
// UTILS
import { formatRupiah, addCommas, removeNonNumeric, decodeValue } from "../utils";
// COMPONENTS
import { NominalCard, TransaksiCard, InputSearch, ButtonSearch, InputSimpleDatePicker, LoadingComponent } from "../components";

export default function HistoryDepositTopupPage() {
    const query = new URLSearchParams(useLocation().search);
    const history = useHistory();
    const params = useParams();
    const dispatch = useDispatch();
    const { id } = useParams();
    // EXCEL
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    // DATA
    const { historyTopup, namaKoperasi, isLoading } = useSelector((state) => state.topup)
    const [currentData, setCurrentData] = useState([]);
    const [totalTransaksi, setTotalTransaksi] = useState(0);
    const [jumlahTransaksi, setJumlahTransaksi] = useState(0);
    const [paging, setPaging] = useState({
        startNumber: 0,
        limit: 10,
        canNext: true,
        canPrev: false,
        lastIdx: null,
    });
    const [input, setInput] = useState({
        ID_Anggota: query.get("ID_Anggota") || "",
        No_BA: query.get("No_BA") || "",
        Nama_Anggota: query.get("Nama_Anggota") || "",
        Min_Nominal: query.get("Min_Nominal") || "",
        Max_Nominal: query.get("Max_Nominal") || "",
        min_date:
            query.get("min_date") ||
            new Date(new Date().setDate(new Date().getDate() - 30))
                .toISOString()
                .split("T")[0],
        max_date: query.get("max_date") || new Date().toISOString().split("T")[0],
        Keterangan: query.get("Keterangan") || "",
    });

    // CHANGE DOCUMENT NAME
    useEffect(() => {
        document.title = "History Deposit Topup | CUSO DM";
    }, []);

    // FETCH DATA WHEN OPENING PAGE
    useEffect(() => {
        if (decodeValue(localStorage.getItem("auth"))) {

            let url = `dtopup/history/${id}`;
            let urlQuery = `dtopup/history/${id}?`;
            const arrQuery = [];
            for (const key in input) {
                if (input[key].length > 0) {
                    if (key === "Min_Nominal" || key === "Max_Nominal") {
                        arrQuery.push(`${key}=${removeNonNumeric(input[key])}`);
                    } else {
                        arrQuery.push(`${key}=${input[key]}`);
                    };
                };
            };
            urlQuery += arrQuery.join("&");
            if (urlQuery[urlQuery.length - 1] !== "?") {
                url = urlQuery;
            };
            if (arrQuery.length) {
                dispatch(searchHistoryTopup(url));
            } else {
                dispatch(fetchHistoryTopup(id));
            };
        };
    }, [localStorage.getItem("auth"), params]);

    // HANDLE CREATE PAGINATION TABLE
    useEffect(() => {
        if (historyTopup.length) {
            // pagination
            if (10 >= historyTopup[historyTopup.length - 1].idx + 1) {
                setPaging({
                    ...paging,
                    startNumber: 0,
                    limit: 10,
                    canNext: false,
                    canPrev: false,
                    lastIdx: historyTopup[historyTopup.length - 1].idx,
                });
            } else {
                setPaging({
                    ...paging,
                    startNumber: 0,
                    limit: 10,
                    canNext: true,
                    canPrev: false,
                    lastIdx: historyTopup[historyTopup.length - 1].idx,
                });
            }
            const tempData = [];
            for (let i = 0; i < 10 && i < historyTopup.length; i++) {
                tempData.push(historyTopup[i]);
            }
            setCurrentData(tempData);
        } else {
            setCurrentData([]);
            setPaging({
                ...paging,
                startNumber: 0,
                limit: 10,
                canNext: false,
                canPrev: false,
                lastIdx: 0,
            });
        };

        // total transaksi
        let transaksi = 0;
        historyTopup.forEach((data) => {
            transaksi += data.Nominal;
        });
        setTotalTransaksi(transaksi);
        setJumlahTransaksi(historyTopup.length);
    }, [historyTopup]);

    // HANDLE NEXT DATA TABLE
    const next = () => {
        if (paging.limit + 10 >= historyTopup.length) {
            setPaging({
                ...paging,
                startNumber: paging.startNumber + 10,
                limit: paging.limit + 10,
                canNext: false,
                canPrev: true,
            });
        } else {
            setPaging({
                ...paging,
                startNumber: paging.startNumber + 10,
                limit: paging.limit + 10,
                canNext: true,
                canPrev: true,
            });
        };

        const tempData = [];
        if (paging.startNumber >= -10) {
            for (
                let i = paging.startNumber + 10;
                i < paging.limit + 10 && i < historyTopup.length;
                i++
            ) {
                tempData.push(historyTopup[i]);
            };
            setCurrentData(tempData);
        } else {
            setCurrentData([]);
        };
    };

    // HANDLE PREV DATA TABLE
    const prev = () => {
        if (paging.startNumber - 10 <= 0) {
            setPaging({
                ...paging,
                startNumber: paging.startNumber - 10,
                limit: paging.limit - 10,
                canNext: true,
                canPrev: false,
            });
        } else {
            setPaging({
                ...paging,
                startNumber: paging.startNumber - 10,
                limit: paging.limit - 10,
                canNext: true,
                canPrev: true,
            });
        };

        const tempData = [];
        if (
            paging.startNumber >= 10 &&
            paging.startNumber < historyTopup.length
        ) {
            for (let i = paging.startNumber - 10; i < paging.limit - 10; i++) {
                tempData.push(historyTopup[i]);
            };
            setCurrentData(tempData);
        } else if (
            paging.startNumber >= 10 &&
            paging.lastIdx + 1 === historyTopup.length
        ) {
            for (let i = paging.startNumber - 10; i < paging.lastIdx + 1; i++) {
                tempData.push(historyTopup[i]);
            }
            setCurrentData(tempData);
        } else {
            setCurrentData([]);
        };
    };

    // HANDLE INPUT
    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        if (name === "Min_Nominal" || name === "Max_Nominal") {
            setInput({
                ...input,
                [name]: addCommas(removeNonNumeric(value)),
            });
        } else {
            setInput({
                ...input,
                [name]: value,
            });
        };
    };

    // HANDLE FILTER
    const searchHandler = (e) => {
        e.preventDefault();
        let params = new URLSearchParams();
        for (const i in input) {
            if (input[i].length > 0) {
                params.append(i, input[i]);
            };
        };

        history.push({ search: params.toString() });
    };

    // HANDLE CHANGE DATE
    const onDateChange = (name, value) => {
        setInput({
            ...input,
            [name]: value,
        });
    };

    // HANDLE RESET
    const handleReset = () => {
        setInput({
            min_date: new Date(new Date().setDate(new Date().getDate() - 30))
                .toISOString()
                .split("T")[0],
            max_date: new Date().toISOString().split("T")[0],
        });
        history.push(`/history-deposit-topup/${id}`);
    };

    return (
        <>
            {/* LOADING COMPONENT */}
            {isLoading && (<LoadingComponent />)}

            {/* CONTENT */}
            <main className="pb-4" style={{ width: 1024 }}>
                <div className="grid px-6 mx-auto">
                    <h2 className="py-3 text-2xl font-semibold bg-white w-full sticky top-0 z-10">
                        History Deposit Topup {namaKoperasi}
                    </h2>
                    {/* FILTER */}
                    <div className="border border-gray-300 rounded-lg p-2 mb-4">
                        <div className="grid md:grid-cols-4 grid-cols-1 gap-4">
                            <TransaksiCard title="Jumlah Transaksi" transaksi={jumlahTransaksi} />
                            <NominalCard title="Total Transaksi" nominal={totalTransaksi} />
                        </div>
                        <div className="flex flex-col md:flex-row md:justify-between md:gap-8 px-4 my-3">
                            <div className="w-full">
                                <div className="flex justify-between items-center my-1 md:text-lg">
                                    <label className="w-1/2 text-sm text-gray-700 dark:text-gray-400">
                                        Tgl Transaksi Awal
                                    </label>
                                    <InputSimpleDatePicker
                                        handleChange={(date) => onDateChange("min_date", date)}
                                        value={input.min_date ? new Date(input.min_date) : ""}
                                    />
                                </div>
                                <div className="flex justify-between items-center my-1 md:text-lg">
                                    <label className="w-1/2 text-sm text-gray-700 dark:text-gray-400">
                                        Tgl Transaksi Akhir
                                    </label>
                                    <InputSimpleDatePicker
                                        handleChange={(date) => onDateChange("max_date", date)}
                                        value={input.max_date ? new Date(input.max_date) : ""}
                                    />
                                </div>
                                <InputSearch
                                    label="Min Nominal"
                                    name="Min_Nominal"
                                    value={input.Min_Nominal ? input.Min_Nominal : ""}
                                    onChange={(e) => {
                                        onChangeHandler(e);
                                    }}
                                />
                                <InputSearch
                                    label="Max Nominal"
                                    name="Max_Nominal"
                                    value={input.Max_Nominal ? input.Max_Nominal : ""}
                                    onChange={(e) => {
                                        onChangeHandler(e);
                                    }}
                                />
                            </div>
                            <div className="w-full flex flex-col justify-between">
                                <InputSearch
                                    label="ID Anggota"
                                    name="ID_Anggota"
                                    value={input.ID_Anggota ? input.ID_Anggota : ""}
                                    onChange={(e) => {
                                        onChangeHandler(e);
                                    }}
                                />
                                <InputSearch
                                    label="No BA"
                                    name="No_BA"
                                    value={input.No_BA ? input.No_BA : ""}
                                    onChange={(e) => {
                                        onChangeHandler(e);
                                    }}
                                />
                                <InputSearch
                                    label="Nama Anggota"
                                    name="Nama_Anggota"
                                    value={input.Nama_Anggota ? input.Nama_Anggota : ""}
                                    onChange={(e) => {
                                        onChangeHandler(e);
                                    }}
                                />
                                <div className="flex justify-between items-center my-1 md:text-lg">
                                    <label className="w-1/2 text-sm text-gray-700 dark:text-gray-400">
                                        Keterangan
                                    </label>
                                    <input
                                        name="Keterangan"
                                        value={input.Keterangan ? input.Keterangan : ""}
                                        onChange={(e) => {
                                            onChangeHandler(e);
                                        }}
                                        className="block w-full mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                                        list="keterangan"
                                    />
                                    <datalist id="keterangan">
                                        <option value="" defaultValue />
                                        {historyTopup.map((el) => {
                                            return <option value={el.Keterangan} />;
                                        })}
                                    </datalist>
                                </div>
                                <div className="flex">
                                    <ButtonSearch onClick={(e) => searchHandler(e)} />
                                    <button
                                        onClick={handleReset}
                                        className="w-full py-2 my-1 ml-4 rounded-lg text-white items-center text-sm bg-purple-600 hover:bg-purple-800 focus:outline-none"
                                    >
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full overflow-hidden rounded-lg shadow-md border border-gray-300 mb-2">
                        <div className="sticky top-0 w-full overflow-x-auto h-85vh">
                            <table className="relative w-full whitespace-no-wrap">
                                <thead>
                                    <tr className="text-xs font-semibold tracking-wide text-center text-gray-500 uppercase border-b bg-gray-200">
                                        <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200">
                                            No
                                        </th>
                                        <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200">
                                            Id Anggota
                                        </th>
                                        <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200">
                                            No BA
                                        </th>
                                        <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200 truncate">
                                            Nama Anggota
                                        </th>
                                        <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 150 }}>
                                            Tipe Transaksi
                                        </th>
                                        <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200">
                                            Nominal
                                        </th>
                                        <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 200 }}>
                                            Tanggal Transaksi
                                        </th>
                                        <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200" style={{ minWidth: 135 }}>
                                            Jam Transaksi
                                        </th>
                                        <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200">
                                            Keterangan
                                        </th>
                                    </tr>
                                </thead>
                                {!historyTopup.length && !isLoading ? (
                                    <p className="truncate text-center text-gray-500 px-4 py-2">
                                        Belum ada riwayat transaksi
                                    </p>
                                ) : (
                                    <tbody className="bg-white divide-y">
                                        {currentData.map((d, index) => {
                                            return (
                                                <tr className="border border-t-0 border-l-0 border-r-0 border-gray-300 hover:bg-blue-100">
                                                    <td className="px-4 py-3 text-sm text-center">{index + paging.startNumber + 1}</td>
                                                    <td className="px-4 py-3 text-sm">{d.ID_Anggota}</td>
                                                    <td className="px-4 py-3 text-sm">{d.No_BA}</td>
                                                    <td className="px-4 py-3 text-sm truncate">{d.Nama_Anggota}</td>
                                                    <td className="px-4 py-3 text-sm text-center">
                                                        {d.Tipe_Transaksi === 0 ? "Penerimaan" : ""}
                                                    </td>
                                                    <td className="px-4 py-3 text-sm truncate">
                                                        {formatRupiah(d.Nominal)}
                                                    </td>
                                                    <td className="px-4 py-3 text-sm text-center">{d.Tgl_Transaksi}</td>
                                                    <td className="px-4 py-3 text-sm text-center">{d.Jam_Transaksi}</td>
                                                    <td className="px-4 py-3 text-sm w-10 truncate overflow">
                                                        {d.Keterangan}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        {/* PAGIANTION & EXCEL */}
                        <div className="flex justify-between flex-wrap px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t">
                            <span className="flex items-center">
                                <span>
                                    Showing{" "}
                                    {historyTopup.length > 0
                                        ? `${paging.startNumber + 1}-${paging.startNumber + currentData.length
                                        } of ${historyTopup.length} |`
                                        : "0-0 of 0 |"}
                                </span>
                                <span>
                                    {historyTopup.length && (
                                        <ExcelFile
                                            element={
                                                <button className="flex w-full items-center justify-center px-4 py-2 text-sm font-medium leading-5 text-green-600 hover:text-green-700 focus:outline-none focus:shadow-outline-green">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-6 w-6"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                                        />
                                                    </svg>
                                                    <span className="ml-2">Download as Excel</span>
                                                </button>
                                            }
                                            filename="Mutasi Deposit Topup"
                                        >
                                            <ExcelSheet
                                                data={historyTopup}
                                                name={`Mutasi Topup Koperasi ${historyTopup[0].Nama_CU}`}
                                            >
                                                <ExcelColumn label="No" value={(col) => col.idx + 1} />
                                                <ExcelColumn label="ID Anggota" value="ID_Anggota" />
                                                <ExcelColumn label="No BA" value="No_BA" />
                                                <ExcelColumn label="Nama Anggota" value="Nama_Anggota" />
                                                <ExcelColumn
                                                    label="Tipe Transaksi"
                                                    value={(col) =>
                                                        col.Tipe_Transaksi === 0 ? "Penerimaan" : ""
                                                    }
                                                />
                                                <ExcelColumn label="Nominal" value="Nominal" />
                                                <ExcelColumn
                                                    label="Tanggal Transaksi"
                                                    value="Tgl_Transaksi"
                                                />
                                                <ExcelColumn
                                                    label="Jam Transaksi"
                                                    value="Jam_Transaksi"
                                                />
                                                <ExcelColumn label="Keterangan" value="Keterangan" />
                                            </ExcelSheet>
                                        </ExcelFile>
                                    )}
                                </span>
                            </span>
                            <span className="col-span-2"></span>
                            <span className="flex col-span-3 mt-2 sm:mt-auto items-center justify-end">
                                <nav aria-label="Table navigation">
                                    <ul className="inline-flex items-center py-2">
                                        <li className="mx-2">
                                            <button
                                                className={`px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple ${paging.canPrev
                                                    ? "bg-purple-600 hover:bg-purple-800"
                                                    : "bg-purple-300 cursor-default"
                                                    } text-white`}
                                                disabled={paging.canPrev ? false : true}
                                                onClick={prev}
                                            >
                                                PREV
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                className={`px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple ${paging.canNext
                                                    ? "bg-purple-600 hover:bg-purple-800"
                                                    : "bg-purple-300 cursor-default"
                                                    } text-white`}
                                                disabled={paging.canNext ? false : true}
                                                onClick={next}
                                            >
                                                NEXT
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </span>
                        </div>
                    </div>
                </div>
            </main>
        </>

    );
}
