// PACKAGE
import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
// ACTIONS
import { fetchCategory, fetchCU, postRuangUsaha } from "../store/actions/ruangUsahaAction";
// HELPERS
import { decodeValue } from "../utils";
// COMPONENTS
import { SwalNotify, ModalConfirmation, LoadingComponent, SwalSuccessNotify } from "../components";

export default function UploadRuangUsahaPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const topRef = useRef();
  // DATA
  let { listCU, listCategory, halamanUtamaRuangUsaha, listTipePenjualan, isLoading } = useSelector((state) => state.ruangUsaha);
  const [image, setImage] = useState('');
  const [imagePreview, setImagePreview] = useState('');
  const [imageUploaded, setImageUploaded] = useState(false);
  const [judul, setJudul] = useState('');
  const [infoSingkat, setInfoSingkat] = useState('');
  const [kategori, setKategori] = useState(null);
  const [infoDetail, setInfoDetail] = useState("");
  const [harga, setHarga] = useState("");
  const [noWhatsapp, setNoWhatsapp] = useState(null);
  const [halamanUtama, setHalamanUtama] = useState(null);
  const [tipePenjualan, setTipePenjualan] = useState(null);
  const [koperasi, setKoperasi] = useState(null);
  // CONDITION
  const [openModal, setOpenModal] = useState(false);

  // UPADTE RUANG USAHA
  useEffect(() => {
    document.title = "Upload Info Produk | CUSO DM";
  }, []);

  // FETCH DATA WHEN OPENING PAGE
  useEffect(() => {
    if (decodeValue(localStorage.getItem("auth"))) {
      // CHECK TIPE USER
      if (decodeValue(localStorage.getItem("auth"))?.tipe_user !== 0 && decodeValue(localStorage.getItem("auth"))?.tipe_user !== 2) {
        SwalNotify("Anda Tidak Memiliki Akses Menu Tersebut, Silahkan Hubungi Administrator");
        history.push("/");
      };

      // FETCH DATA CU
      dispatch(fetchCU());
      dispatch(fetchCategory())
    };

    // AUTO SCROLL
    topRef.current.scrollIntoView({ behavior: "smooth", block: 'end' });
  }, [localStorage.getItem("auth")]);

  // HANDLE CHECK IMG FORMAT
  const checkImgFormat = (img) => {
    const validFormat = ["image/jpeg", "image/png", "image/jpg"];
    return validFormat.includes(img.type);
  };

  //Foto Produk
  function handleImageChange(e) {
    const img = e.target.files[0];

    if (img && checkImgFormat(img) && img.size < 300000) {
      setImage(img);
      let reader = new FileReader();

      reader.onload = function (e) {
        setImagePreview(e.target.result);
        setImageUploaded(true);
      };

      reader.readAsDataURL(img);
    } else {
      SwalNotify("Gambar Tidak Sesuai Persyaratan, Silahkan Pilih Gambar Lain");
      setImagePreview("");
      setImageUploaded(false);
    };
  };

  //Nama Produk
  function handleJudulChange(e) {
    if (e.target.value.length) {
      setJudul(e.target.value);
    } else {
      setJudul("");
    };
  };

  //InfoSingkat Produk
  function handleInfoSingkatChange(e) {
    if (e.target.value.length) {
      setInfoSingkat(e.target.value);
    } else {
      setInfoSingkat("");
    };
  };

  //Category Produk
  function handleKategoriChange(e) {
    if (e.target.value) {
      setKategori(e.target.value);
    } else {
      setKategori(null);
    };
  };

  //Deskripsi Produk
  const handleEditorChange = (content) => {
    setInfoDetail(content);
  };

  //Harga Produk
  function handleHargaChange(e) {
    const price = e.target.value;
    if (+price) {
      const format = price.toString().split('').reverse().join('');
      const convert = format.match(/\d{1,3}/g);
      const rupiah = 'Rp ' + convert.join('.').split('').reverse().join('');
      setHarga(rupiah);
    } else {
      if (price) {
        setHarga(price);
      } else {
        setHarga("");
      };
    };
  };

  //CU_Id
  function handleKoperasiChange(e) {
    if (e.target.value) {
      setKoperasi(e.target.value);
    } else {
      setKoperasi(null);
    };
  };

  //Halaman Utama
  function handleHalamanUtamaChange(e) {
    if (e.target.value) {
      setHalamanUtama(e.target.value);
    } else {
      setHalamanUtama(null);
    };
  };

  //Tipe Penjualan
  function handleTipePenjualanChange(e) {
    if (e.target.value) {
      setTipePenjualan(e.target.value);
    } else {
      setTipePenjualan(null);
    };
  };

  //no Whatsapp
  function handleNoWhatsappChange(e) {
    if (e.target.value) {
      setNoWhatsapp(e.target.value);
    } else {
      setNoWhatsapp("");
    };
  };

  //submit
  function handleSubmit(e) {
    e.preventDefault()

    // CHECK FORM
    if (!image) {
      return SwalNotify("Silahkan pilih gambar produk terlebih dahulu");
    } else if (!judul) {
      return SwalNotify("Silahkan isi judul produk terlebih dahulu");
    } else if (!kategori) {
      return SwalNotify("Silahkan pilih kategori produk terlebih dahulu");
    } else if (!infoSingkat) {
      return SwalNotify("Silahkan isi info singkat produk terlebih dahulu");
    } else if (!infoDetail) {
      return SwalNotify("Silahkan isi infoDetail produk terlebih dahulu");
    } else if (!harga) {
      return SwalNotify("Silahkan isi harga produk terlebih dahulu");
    } else if (decodeValue(localStorage.getItem("auth"))?.CU_Id === null && !koperasi) {
      return SwalNotify("Silahkan Pilih Koperasi terlebih dahulu");
    } else if (halamanUtama === null) {
      return SwalNotify("Silahkan pilih apakah produk ingin ditampilkan di halaman utama terlebih dahulu");
    } else if (tipePenjualan === null) {
      return SwalNotify("Silahkan pilih tipe penjualan terlebih dahulu");
    } else if (!noWhatsapp) {
      return SwalNotify("Silahkan isi nomor whatsapp terlebih dahulu");
    };

    // OPEN MODAL CONFIRMATION
    setOpenModal(true);
  };

  // HANDLE CONFRIM FROM MODAL && SUBMIT DATA
  const handleConfirm = async () => {
    setOpenModal(false);

    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    const separator = '-'
    const tanggal = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`;

    //jika admin maka CU_Id dipilih, jika bukan admin maka cu id tergantung akun
    let CU_Id;
    if (decodeValue(localStorage.getItem("auth"))?.CU_Id === null) {
      CU_Id = koperasi;
    } else {
      CU_Id = decodeValue(localStorage.getItem("auth"))?.CU_Id;
    };

    const fd = new FormData()
    fd.append('Foto', image)
    fd.append('Judul', judul)
    fd.append('CU_Id', CU_Id)
    fd.append('Tanggal', tanggal)
    fd.append('Info_Singkat', infoSingkat)
    fd.append('Kode_Kategori', kategori)
    fd.append('Informasi_Detail', infoDetail)
    fd.append('Harga', harga)
    fd.append('Status_Tampil_Layar_Utama', +halamanUtama)
    fd.append('Tipe_Penjualan', +tipePenjualan)
    fd.append('No_Hp_Whatsapp', noWhatsapp)
    fd.append('Status_Banner', 1) //flag untuk identifikasi bukan banner

    // SUBMIT DATA
    const submited = await dispatch(postRuangUsaha(fd));
    if (submited) {
    	SwalSuccessNotify("Data Berhasil Tersimpan").then(() => {
    		// REDIRECT IF USER ADM CUSO
    		if (decodeValue(localStorage.getItem("auth"))?.tipe_user === 0) {
    			history.push(`/ruang-usaha?cu_id=${koperasi}`);
    		} else {
    			history.push("/ruang-usaha");
    		};
    	});
    };
  };

  return (
    <>
      {/* LOADING COMPONENT */}
      {isLoading && (<LoadingComponent />)}

      {/* MODAL CONFIRMATION */}
      <ModalConfirmation
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleConfirm={handleConfirm}
        data={{
          imageUploaded, imagePreview, infoDetail, judul, harga, noWhatsapp, infoSingkat,
          Koperasi: listCU?.find(item => item.CU_Id === koperasi)?.Nama_CU,
          Kategori_Penjualan: listCategory?.find(item => item.Kode_Kategori === kategori)?.Nama_Kategori,
          Show_Page: halamanUtamaRuangUsaha[halamanUtama],
          Tipe_Penjualan: listTipePenjualan[tipePenjualan]
        }}
        menu={"ruangUsaha"}
      />

      <div className="py-3">
        {/** Upload Form*/}
        <form className="w-full" style={{ maxWidth: 1024 }} onSubmit={handleSubmit}>

          {/* TOP REF */}
          <div className="bg-transparent" ref={topRef} />

          {/** Judul Form */}
          <div className="flex flex-wrap w-full mb-6">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <h1 className="font-sans font-bold text-2xl">Upload Ruang Usaha</h1>
            </div>
          </div>

          {/** Image */}
          <div className="flex flex-wrap w-full mb-6">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <h1 className="font-['Open_Sans'] font-bold text-xl">Foto</h1>
              <p className="text-sm font-['Open_Sans']">
                Format gambar .jpg .jpeg .png dan
                ukuran maximum 300kb.
              </p>
            </div>

            <div className="w-full border border-gray-300 rounded-md" style={{ maxWidth: 600 }}>
              {/* BORDER PHOTO */}
              <div className="p-3">
                <div className="border border-gray-300 rounded-md flex justify-center" style={{ maxWidth: 600, maxHeight: 300, minHeight: 250 }}>
                  {imageUploaded && imagePreview ? (
                    <img className="w-fit h-fit" src={imagePreview} alt="" style={{ maxHeight: 250 }} />
                  ) : null}
                </div>
              </div>
              <div className="flex items-center justify-center w-full mb-1">
                <label
                  className="flex flex-col border-4 border-blue-200 border-dashed hover:border-gray-300 cursor-pointer mb-1" style={{ width: 190, height: 60 }}>
                  <div className="flex flex-col items-center justify-center pt-1">
                    <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 group-hover:text-gray-600" style={{ width: 20, height: 20 }}
                      fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                    </svg>
                    <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                      Update Photo</p>
                  </div>
                  <input id="upload-image-produk" type="file" accept=".jpg,.png,.jpeg" className="opacity-0" onChange={handleImageChange} />
                </label>
              </div>
            </div>
          </div>

          {/** Divider 1*/}
          <div className="flex flex-wrap w-full mb-6 border-dashed border-t-2 border-gray-300">
            <div className="w-full md:w-1/3 px-3 mt-2 mb-6 md:mb-0">
              <h1 className="font-sans font-bold text-2xl">Informasi Produk</h1>
            </div>
          </div>

          {/** Nama Produk */}
          <div className="flex flex-wrap w-full mb-6">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Nama Produk</h1>
              <p className="text-sm font-['Open_Sans']">
                Cantumkan judul produk yang deskriptif agar
                semakin menarik dan mudah ditemukan
                oleh pembeli, terdiri dari jenis produk,
                merek, dan keterangan seperti warna,
                bahan, atau tipe.
              </p>
            </div>
            <div className="w-full md:w-2/3 px-3">
              <label className="block text-sm mt-5">
                <input
                  className="block w-full mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                  style={{ maxWidth: 590 }}
                  onChange={handleJudulChange}
                  value={judul}
                />
              </label>
            </div>
          </div>

          {/** Kategori Produk */}
          <div className="flex flex-wrap w-full mb-6">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Kategori Produk</h1>
              <p className="text-sm font-['Open_Sans']">
                Pilihlah 1 Kategori yang sesuai dengan
                Produk yang akan Anda jual di Koperasi.
              </p>
            </div>
            <div className="w-full md:w-2/3 px-3 mt-5">
              <select onChange={handleKategoriChange} className="block w-full mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                style={{ maxWidth: 590 }} value={kategori}>
                <option selected hidden>Pilih Kategori</option>
                {listCategory.map((data, idx) => (
                  <option value={data.Kode_Kategori} key={idx}>{data.Nama_Kategori}</option>
                ))}
              </select>
            </div>
          </div>

          {/** Info Singkat */}
          <div className="flex flex-wrap w-full mb-6">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Info Singkat</h1>
              <p className="text-sm font-['Open_Sans']">
                Cantumkan info produk secara singkat agar pembeli
                mengetahui informasi dasar tentang produk ini.
              </p>
            </div>
            <div className="w-full md:w-2/3 px-3">
              <label className="block text-sm mt-5">
                <input
                  className="block w-full mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                  style={{ maxWidth: 590 }}
                  onChange={handleInfoSingkatChange}
                  value={infoSingkat}
                />
              </label>
            </div>
          </div>

          {/** Deskripsi Produk */}
          <div className="flex flex-wrap w-full mb-6">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Deskripsi Produk</h1>
              <p className="text-sm font-['Open_Sans']">
                Pastikan Deskripsi produk memuat
                spesifikasi, ukuran, bahan, masa berlaku,
                dan lainnya. Semakin detail, semakin
                berguna bagi pembeli, cantumkan max. 2500 karakter
                agar pembeli semakin
                mudah mengerti dan menemukan
                produk anda
              </p>
            </div>
            <div className="w-full md:w-2/3 px-3 mt-5">
              <label className="block mt-4 text-sm">
                <Editor
                  apiKey={window.env.REACT_APP_TINY_MCE_API_KEY}
                  init={{
                    height: 400,
                    width: 590,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent link | removeformat | help",
                  }}
                  onEditorChange={handleEditorChange}
                />
              </label>
            </div>
          </div>

          {/** Divider 2*/}
          <div className="flex flex-wrap w-full mb-6 border-dashed border-t-2 border-gray-300">
            <div className="pl-3 mt-2 mb-6 md:mb-0">
              <h1 className="font-sans font-bold text-2xl">Harga & Pengelolaan Produk</h1>
            </div>
          </div>

          {/** Harga Produk */}
          <div className="flex flex-wrap w-full mb-10">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <h1 className="font-['Open_Sans'] font-bold text-xl">Harga Produk (Satuan)</h1>
            </div>
            <div className="w-full md:w-2/3 px-3">
              <label className="block text-sm">
                <input
                  className="block w-full mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                  style={{ maxWidth: 590 }}
                  onChange={handleHargaChange}
                  value={harga}
                />
              </label>
            </div>
          </div>

          {/** Nama Koperasi (untuk admin CUSO) */}
          {decodeValue(localStorage.getItem("auth"))?.CU_Id === null ? (
            <div>
              {/** CU_Id */}
              <div className="flex flex-wrap w-full mb-6">
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Koperasi</h1>
                  <p className="text-sm font-['Open_Sans']">
                    Silahkan Pilih Koperasi.
                  </p>
                </div>
                <div className="w-full md:w-2/3 px-3 mt-5">
                  <select onChange={handleKoperasiChange} className="block w-full mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                    style={{ maxWidth: 590 }} value={koperasi}>
                    <option selected hidden >Pilih Koperasi</option>
                    {listCU.map((data, idx) => (
                      <option value={data.CU_Id} key={idx}>{data.CU_Id} - {data.Nama_CU}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          ) : null}

          {/** Halaman Utama */}
          <div className="flex flex-wrap w-full mb-6">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Tampil Halaman Utama</h1>
              <p className="text-sm font-['Open_Sans']">
                Pilih apakah produk anda ingin di tampilkan di halaman utama atau tidak?
              </p>
            </div>
            <div className="w-full md:w-2/3 px-3 mt-5">
              <select onChange={handleHalamanUtamaChange} className="block w-full mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                style={{ maxWidth: 590 }} value={halamanUtama}>
                <option selected hidden>Pilih Status</option>
                {halamanUtamaRuangUsaha.map((e, idx) => (
                  <option value={idx} key={idx}>{e}</option>
                ))}
              </select>
            </div>
          </div>

          {/** Tipe Penjualan */}
          <div className="flex flex-wrap w-full mb-6">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Tipe Penjualan</h1>
              <p className="text-sm font-['Open_Sans']">
                Pilih Tipe Penjualan Anda
              </p>
            </div>
            <div className="w-full md:w-2/3 px-3 mt-5">
              <select onChange={handleTipePenjualanChange} className="block w-full mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                style={{ maxWidth: 590 }} value={tipePenjualan}>
                <option selected hidden>Pilih Tipe Penjualan</option>
                {listTipePenjualan.map((e, idx) => (
                  <option value={idx} key={idx}>{e}</option>
                ))}
              </select>
            </div>
          </div>

          {/** No HP/Whatsapp */}
          <div className="flex flex-wrap w-full mb-10">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Nomor HP/Whatsapp</h1>
              <p className="text-sm font-['Open_Sans']">
                Cantumkan nomor handphone atau whatsapp yang dapat dihubungi agar pembeli mudah untuk menghubungi penjual.
              </p>
            </div>
            <div className="w-full md:w-2/3 px-3">
              <label className="block text-sm mt-5">
                <input
                  className="block w-full mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                  style={{ maxWidth: 590 }}
                  onChange={handleNoWhatsappChange}
                  value={noWhatsapp}
                />
              </label>
            </div>
          </div>

          {/** Submit */}
          <div className="flex flex-wrap justify-center w-full" style={{ paddingBottom: 10 }}>
            <button
              type="submit"
              className="w-full md:w-1/6 flex justify-center py-2 my-1 mr-2 rounded-lg text-white items-center text-sm bg-green-700 hover:bg-green-600 focus:outline-none"
            >
              Simpan
            </button>
            <button
              onClick={() => history.push("/ruang-usaha")}
              type="button"
              className="w-full md:w-1/6 flex justify-center py-2 my-1 rounded-lg text-white items-center text-sm bg-red-600 hover:bg-red-500 focus:outline-none"
            >
              Batal
            </button>
          </div>
        </form>
      </div>
    </>

  );
}
