// PACAKGE
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function ModalConfirmation({ openModal, setOpenModal, data, handleConfirm, menu }) {
    const headTitle = useRef();

    return (
        <Transition show={openModal} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-20 overflow-y-auto"
                style={{ width: "115%" }}
                initialFocus={headTitle}
                static
                open={openModal}
                onClose={() => setOpenModal(false)}
            >
                <div className="min-h-screen px-4 text-center">
                    <Dialog.Overlay className="fixed inset-0 opacity-30 bg-black" />
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block w-full p-6 my-8 overflow-x-hidden overflow-y-auto text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl" style={{ maxWidth: 600 }}>
                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-700"
                            >
                                <h1 ref={headTitle} className="text-center">
                                    Apakah anda sudah yakin dengan data di bawah ini?
                                </h1>
                            </Dialog.Title>
                            <div className="mt-6 border border-gray-300 rounded-md flex flex-col p-2" style={{ height: 415 }}>
                                <div className="flex justify-center">
                                    <div className="border border-gray-300 rounded-md flex justify-center" style={{ minWidth: 300, maxWidth: 300, maxHeight: 150, minHeight: 150 }}>
                                        <img className="w-fit h-fit" src={
                                            data.imageUploaded && data.imagePreview ? data.imagePreview :
                                                data.Foto_URL ? data.Foto_URL : null} alt="" style={{ maxHeight: 150 }} />
                                    </div>
                                </div>
                                {/* CONTENT */}
                                <div className="p-3" style={{ fontSize: 14 }}>
                                    <div className="flex">
                                        <p className="inline-block text-left font-semibold" style={{ width: 105, paddingRight: 5 }}>Koperasi</p>
                                        <p className="inline-block text-right" style={{ paddingRight: 5 }}>:</p>
                                        <p className="inline-block text-left" style={{ paddingRight: 5, maxWidth: 390 }}>{data?.Koperasi}</p>
                                    </div>
                                    <div className="flex">
                                        <p className="inline-block text-left font-semibold" style={{ width: 105, paddingRight: 5 }}>Judul</p>
                                        <p className="inline-block text-right" style={{ paddingRight: 5 }}>:</p>
                                        <p className="inline-block text-left truncate" style={{ paddingRight: 5, maxWidth: 390 }}>{data?.judul || data?.Judul}</p>
                                    </div>
                                    {menu === "berita" && (
                                        <>
                                            <div className="flex">
                                                <p className="inline-block text-left font-semibold" style={{ width: 105, paddingRight: 5 }}>Kategori</p>
                                                <p className="inline-block text-right" style={{ paddingRight: 5 }}>:</p>
                                                <p className="inline-block text-left truncate" style={{ paddingRight: 5, maxWidth: 390 }}>{data?.Kategori_Berita}</p>
                                            </div>
                                            <div className="flex">
                                                <p className="inline-block text-left font-semibold" style={{ width: 105, paddingRight: 5 }}>Tipe Berita</p>
                                                <p className="inline-block text-right" style={{ paddingRight: 5 }}>:</p>
                                                <p className="inline-block text-left truncate" style={{ paddingRight: 5, maxWidth: 390 }}>{data?.Tipe_Berita}</p>
                                            </div>
                                        </>
                                    )}
                                    {menu === "ruangUsaha" && (
                                        <>
                                            <div className="flex">
                                                <p className="inline-block text-left font-semibold" style={{ width: 105, paddingRight: 5 }}>Info Singkat</p>
                                                <p className="inline-block text-right" style={{ paddingRight: 5 }}>:</p>
                                                <p className="inline-block text-left truncate" style={{ paddingRight: 5, maxWidth: 390 }}>{data?.infoSingkat || data?.Info_Singkat}</p>
                                            </div>
                                            <div className="flex">
                                                <p className="inline-block text-left font-semibold" style={{ width: 105, paddingRight: 5 }}>Kategori</p>
                                                <p className="inline-block text-right" style={{ paddingRight: 5 }}>:</p>
                                                <p className="inline-block text-left truncate" style={{ paddingRight: 5, maxWidth: 390 }}>{data?.Kategori_Penjualan}</p>
                                            </div>
                                            <div className="flex">
                                                <p className="inline-block text-left font-semibold" style={{ width: 105, paddingRight: 5 }}>Harga</p>
                                                <p className="inline-block text-right" style={{ paddingRight: 5 }}>:</p>
                                                <p className="inline-block text-left truncate" style={{ paddingRight: 5, maxWidth: 390 }}>{data?.harga || data?.Harga}</p>
                                            </div>
                                            <div className="flex">
                                                <p className="inline-block text-left font-semibold" style={{ width: 105, paddingRight: 5 }}>Tipe Penjualan</p>
                                                <p className="inline-block text-right" style={{ paddingRight: 5 }}>:</p>
                                                <p className="inline-block text-left truncate" style={{ paddingRight: 5, maxWidth: 390 }}>{data?.Tipe_Penjualan}</p>
                                            </div>
                                            <div className="flex">
                                                <p className="inline-block text-left font-semibold" style={{ width: 105, paddingRight: 5 }}>Hal. Utama</p>
                                                <p className="inline-block text-right" style={{ paddingRight: 5 }}>:</p>
                                                <p className="inline-block text-left truncate" style={{ paddingRight: 5, maxWidth: 390 }}>{data?.Show_Page}</p>
                                            </div>
                                            <div className="flex">
                                                <p className="inline-block text-left font-semibold" style={{ width: 105, paddingRight: 5 }}>No HP/WA</p>
                                                <p className="inline-block text-right" style={{ paddingRight: 5 }}>:</p>
                                                <p className="inline-block text-left truncate" style={{ paddingRight: 5, maxWidth: 390 }}>{data?.noWhatsapp || data?.No_HP_Whatsapp}</p>
                                            </div>
                                        </>
                                    )}
                                    {menu === "banner" && (
                                        <>
                                            <div className="flex">
                                                <p className="inline-block text-left font-semibold" style={{ width: 105, paddingRight: 5 }}>Info Singkat</p>
                                                <p className="inline-block text-right" style={{ paddingRight: 5 }}>:</p>
                                                <p className="inline-block text-left truncate" style={{ paddingRight: 5, maxWidth: 390 }}>{data?.infoSingkat || data?.Info_Singkat}</p>
                                            </div>
                                            <div className="flex">
                                                <p className="inline-block text-left font-semibold" style={{ width: 105, paddingRight: 5 }}>No HP/WA</p>
                                                <p className="inline-block text-right" style={{ paddingRight: 5 }}>:</p>
                                                <p className="inline-block text-left truncate" style={{ paddingRight: 5, maxWidth: 390 }}>{data?.noWhatsapp || data?.No_HP_Whatsapp}</p>
                                            </div>
                                        </>
                                    )}
                                    <div style={{ marginTop: menu === "ruangUsaha" ? null : 12 }} >
                                        <p className="inline-block text-left font-semibold" style={{ width: 125, paddingRight: 5 }}>Informasi Detail</p>
                                    </div>
                                </div>
                                {/* INFORMASI DETAIL */}
                                <div className="flex justify-center">
                                    <div className="border border-gray-300 rounded-md overflow-auto" style={{ width: 515, height: menu === "berita" || menu === "banner" ? 110 : menu === "ruangUsaha" ? 35 : 150, marginTop: -5 }}>
                                        <div className="p-3" style={{ fontSize: 14 }} dangerouslySetInnerHTML={{ __html: data.infoDetail || data.Informasi_Detail }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 flex justify-center gap-2">
                                <button
                                    type="button"
                                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-purple-600 border border-transparent rounded-md hover:bg-purple-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-purple-600"
                                    onClick={handleConfirm}
                                >
                                    Simpan
                                </button>
                                <button
                                    type="button"
                                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                    onClick={() => setOpenModal(false)}
                                >
                                    Batal
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
}
