// PACKAGE
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ClickAwayListener from "react-click-away-listener";
// ACIIONS
import { setMenuBarOpen, handleLogout } from "../store/actions/userAction";
// HELPERS
import { decodeValue } from "../utils";
// COMPONENTS
import { ModalUpdatePassword } from "../components";

export default function Navbar() {
  const history = useHistory();
  const dispatch = useDispatch();
  // DATA
  const { isSideMenuOpen } = useSelector((state) => state.user);
  const [test, setTest] = useState(false);
  // CONDITION
  const [openModal, setOpenModal] = useState(false);

  // HANDLE LOGOUT
  const logout = () => {
    localStorage.removeItem("auth");
    dispatch(handleLogout());
    history.push("/login");
  };

  // HANDLE OPEN MENU BAR
  const openMenuBar = () => {
    dispatch(setMenuBarOpen(!isSideMenuOpen));
  };

  return (
    <>
      {/* MODAL EDIT USER */}
      <ModalUpdatePassword
        openModal={openModal}
        setOpenModal={setOpenModal}
      />

      <header className="relative z-20 py-4 bg-white shadow-md">
        <div className="flex items-center justify-end h-full px-6 mx-auto text-purple-600">
          {/* <!-- Mobile hamburger --> */}
          {/* <button
          className="p-1 mr-5 -ml-1 rounded-md md:hidden focus:outline-none focus:shadow-outline-purple"
          aria-label="Menu"
          onClick={openMenuBar}
        >
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button> */}
          <ul className="flex items-center just flex-shrink-0 space-x-6">
            {/* <!-- Profile menu --> */}
            <li className="relative">
              <button
                className="align-middle rounded-full focus:shadow-outline-purple focus:outline-none"
                aria-label="Account"
                aria-haspopup="true"
                onClick={() => {
                  !test ? setTest(true) : setTest(false);
                }}
              >
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
              </button>
              <div>
                {test && (
                  <ClickAwayListener
                    onClickAway={() => {
                      setTest(false);
                    }}
                  >
                    <ul
                      className="absolute right-0 w-56 p-2 mt-2 space-y-2 text-gray-600 bg-white border border-gray-100 rounded-md shadow-md dark:border-gray-700 dark:text-gray-300 dark:bg-gray-700"
                      aria-label="submenu"
                    >
                      <li className="flex cursor-default">
                        <span
                          className="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md border border-t-0 border-l-0 border-r-0 border-gray-300"
                          href="#"
                        >
                          <svg
                            className="w-4 h-4 mr-3"
                            aria-hidden="true"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                          </svg>
                          <span className="mr-1">
                            {decodeValue(localStorage.getItem("auth"))?.name ? decodeValue(localStorage.getItem("auth"))?.name : "User"}
                          </span>
                          <span>
                            (
                            {decodeValue(localStorage.getItem("auth"))?.tipe_user === 0 ? "Admin" : "User"}
                            )
                          </span>
                        </span>
                      </li>
                      {/* UPDATE PASSWORD */}
                      <li className="flex cursor-pointer" onClick={() => setOpenModal(true)}>
                        <span
                          className="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-purple-200"
                          href="#"
                        >
                          <svg className="w-4 h-4 mr-3"
                            aria-hidden="true"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path d="M 12 1 C 8.6761905 1 6 3.6761905 6 7 L 6 8 C 4.9069372 8 4 8.9069372 4 10 L 4 20 C 4 21.093063 4.9069372 22 6 22 L 18 22 C 19.093063 22 20 21.093063 20 20 L 20 10 C 20 8.9069372 19.093063 8 18 8 L 18 7 C 18 3.6761905 15.32381 1 12 1 z M 12 3 C 14.27619 3 16 4.7238095 16 7 L 16 8 L 8 8 L 8 7 C 8 4.7238095 9.7238095 3 12 3 z M 6 10 L 18 10 L 18 20 L 6 20 L 6 10 z M 12 13 C 10.9 13 10 13.9 10 15 C 10 16.1 10.9 17 12 17 C 13.1 17 14 16.1 14 15 C 14 13.9 13.1 13 12 13 z"></path>
                          </svg>
                          <span>Update Password</span>
                        </span>
                      </li>
                      {/* LOGOUT */}
                      <li className="flex cursor-pointer">
                        <div
                          className="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-purple-200"
                          onClick={logout}
                        >
                          <svg
                            className="w-4 h-4 mr-3"
                            aria-hidden="true"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"></path>
                          </svg>
                          <span>Log out</span>
                        </div>
                      </li>
                    </ul>
                  </ClickAwayListener>
                )}
              </div>
            </li>
          </ul>
        </div>
      </header>
    </>


  );
}
