const initialState = {
	listInfoProduk: [],
	listCU:[],
	currentProduk: {},
	isLoading: false,
};

export default function infoProdukReducer(state = initialState, action) {
	switch (action.type) {
		case "SET_LIST_INFO_PRODUK":
			return {
				...state,
				listInfoProduk: action.payload,
				isLoading: false,
			};
		case "SET_LIST_CU":
			return {
				...state,
				listCU: action.payload,
				isLoading: false,
			};
		case "SET_CURRENT_PRODUK":
			return {
				...state,
				currentProduk: action.payload,
				isLoading: false,
			};
		case "SET_INFO_PRODUK_LOADING":
			return {
				...state,
				isLoading: action.payload,
			};
		default:
			return state;
	}
}
