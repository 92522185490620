// PACAKGE
import { Fragment, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
// ACTIONS
import { changePasswordUser } from "../store/actions/userManagementAction";
// COMPONENTS
import { SwalNotify, SwalSuccessNotify, LoadingComponent } from "./index";
// UTILS
import { specialCharactersCheck, specialCharactersList, specialCharactersReplace } from "../utils";
// ICONS
import { showPass, hidePass } from "../assets";

export default function ModalUpdatePassword({ openModal, setOpenModal }) {
    const headTitle = useRef();
    const dispatch = useDispatch();
    // DATA
    const { isLoading } = useSelector(state => state.userManagement);
    const [input, setInput] = useState("");
    // CONDTIONAL
    const [showPassword, setShowPassword] = useState(false);
    const [showOldPwd, setShowOldPwd] = useState(false);

    // HANDLE INPUT
    const handleInput = (e) => {
        if (e.target.name === "new_password") {
            const checkStr = specialCharactersCheck(e.target.value);
            if (checkStr) {
                SwalNotify(`Input Tidak Boleh Mengandung Character: 
                    ${specialCharactersList()}`);
            };
            setInput({ ...input, [e.target.name]: specialCharactersReplace(e.target.value) });
        } else {
            setInput({ ...input, [e.target.name]: e.target.value });
        };
    };

    // HANDLE CONFRIM FROM MODAL && SUBMIT DATA
    const handleConfirm = async (e) => {
        e.preventDefault();

        // CHECK NEW PASSWORD LENGTH
        if (input.new_password?.length < 6) {
            return SwalNotify("Minimum Password 6 Karakter!");
        };

        // SUBMIT DATA
        const submited = await dispatch(changePasswordUser(input));
        if (submited) {
            SwalSuccessNotify("Data Berhasil Tersimpan").then(() => {
                setOpenModal(false);
            });
        };
    };

    return (
        <>
            {/* LOADING COMPONENT */}
            {isLoading && (<LoadingComponent />)}

            {/* CONTENT */}
            <Transition show={openModal} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-20 overflow-y-auto"
                    style={{ width: "100%" }}
                    initialFocus={headTitle}
                    static
                    open={openModal}
                    onClose={() => { }}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Dialog.Overlay className="fixed inset-0 opacity-30 bg-black" />
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <form className="inline-block w-full p-6 my-8 overflow-x-hidden overflow-y-auto text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
                                style={{ maxWidth: 500 }}
                                onSubmit={handleConfirm}
                            >
                                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-700"
                                >
                                    <h1 ref={headTitle} className="text-center" style={{ fontSize: 30 }}>
                                        Update Password
                                    </h1>
                                </Dialog.Title>
                                <div className="mt-4">
                                    <div className="flex flex-col relative">
                                        <label className="block mt-4 text-sm">
                                            Password Lama
                                        </label>
                                        <input
                                            className="w-full mt-1 text-sm border border-purple-300 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                                            name="old_password"
                                            value={input.old_password ? input.old_password : ""}
                                            onChange={handleInput}
                                            type={showOldPwd ? "text" : "password"}
                                            required={true}
                                        />
                                        <div className="bg-white absolute cursor-pointer" style={{ width: 25, height: 25, bottom: 8, right: 6, padding: 3 }} onClick={() => setShowOldPwd(!showOldPwd)}>
                                            <img src={showOldPwd ? showPass : hidePass} className="opacity-70 p-[2px]" alt="pwd-icon" style={{ width: 20, height: 20 }} />
                                        </div>
                                    </div>
                                    <div className="flex flex-col relative">
                                        <label className="block mt-4 text-sm">
                                            Password Baru
                                        </label>
                                        <input
                                            className="w-full mt-1 text-sm border border-purple-300 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                                            name="new_password"
                                            value={input.new_password ? input.new_password : ""}
                                            autoComplete="new-password"
                                            onChange={handleInput}
                                            type={showPassword ? "text" : "password"}
                                            required={true}
                                        />
                                        <div className="bg-white absolute cursor-pointer" style={{ width: 25, height: 25, bottom: 8, right: 6, padding: 3 }} onClick={() => setShowPassword(!showPassword)}>
                                            <img src={showPassword ? showPass : hidePass} className="opacity-70 p-[2px]" alt="pwd-icon" style={{ width: 20, height: 20 }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 flex justify-center gap-2">
                                    <button
                                        type="submit"
                                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-purple-600 border border-transparent rounded-md hover:bg-purple-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-purple-600"
                                    >
                                        Simpan
                                    </button>
                                    <div
                                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 cursor-pointer"
                                        onClick={() => setOpenModal(false)}
                                    >
                                        Batal
                                    </div>
                                </div>
                            </form>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
