const initialState = {
  listAnggota: [],
  isLoading: false,
};

function anggotaReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_LIST_ANGGOTA":
      return {
        ...state,
        listAnggota: action.payload,
        isLoading: false,
      };
    case "SET_LIST_ANGGOTA_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
}

export default anggotaReducer;
