// PACKAGE
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// ACTIONS
import { fetchUsers, fetchCUId } from "../store/actions/userManagementAction";
// HELPERS
import { decodeValue } from "../utils";
// COMPONENTS
import { SwalNotify, ModalManageUser, LoadingComponent } from "../components";

export default function UserManagementPage() {
	const dispatch = useDispatch();
	const history = useHistory();
	// DATA
	const { listUsers, listTipeUser, isLoading } = useSelector((state) => state.userManagement);
	const [detailUser, setDetailUser] = useState("");
	const [action, setAction] = useState("");
	// CONDITION
	const [openModal, setOpenModal] = useState(false);

	// UPADTE RUANG USAHA
	useEffect(() => {
		document.title = "List User | CUSO DM";
	}, []);

	// FETCH DATA WHEN OPENING PAGE
	useEffect(() => {
		if (decodeValue(localStorage.getItem("auth"))) {
			// CHECK TIPE USER
			if (decodeValue(localStorage.getItem("auth"))?.tipe_user !== 0) {
				SwalNotify("Anda Tidak Memiliki Akses Menu Tersebut, Silahkan Hubungi Administrator");
				history.push("/");
			};

			// FETCH DATA
			dispatch(fetchUsers());
			dispatch(fetchCUId());
		};
	}, [localStorage.getItem("auth")]);

	return (
		<>
			{/* LOADING COMPONENT */}
			{isLoading && (<LoadingComponent />)}

			{/* MODAL EDIT USER */}
			<ModalManageUser
				openModal={openModal}
				setOpenModal={setOpenModal}
				setAction={setAction}
				data={detailUser}
				header={action}
			/>

			<main className="px-5">
				<div className="flex justify-between items-center">
					<h2 className="my-6 text-2xl font-bold">
						List User
					</h2>
					<button className="px-4 py-2 m-1 rounded-lg text-white items-center text-sm bg-purple-600 hover:bg-purple-800 focus:outline-none"
						onClick={() => { setOpenModal(true); setAction("Add") }}>
						Tambah User
					</button>
				</div>
				<div className="w-full overflow-hidden rounded-lg shadow-md border border-gray-300">
					<div className="sticky top-0 overflow-x-auto" style={{ height: "77vh", maxWidth: 1200, minWidth: 1024 }}>
						<table className="whitespace-no-wrap w-full">
							<thead>
								<tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b bg-gray-200">
									<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200">
										No
									</th>
									<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200">
										Nama
									</th>
									<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200">
										Username
									</th>
									<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200">
										Tipe User
									</th>
									<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200">
										Email
									</th>
									<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200">
										CU ID
									</th>
									<th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b bg-gray-200">
										Action
									</th>
								</tr>
							</thead>
							<tbody className="bg-white divide-y-gray-600">
								{listUsers?.map((data, idx) => (
									<tr className="border border-t-0 border-l-0 border-r-0 border-gray-300 hover:bg-blue-100">
										<td className="px-4 py-2.5 text-sm text-center">{idx + 1}</td>
										<td className="px-4 py-2.5 text-sm">{data.name}</td>
										<td className="px-4 py-2.5 text-sm truncate">{data.username}</td>
										<td className="px-4 py-2.5 text-sm truncate text-center">{listTipeUser[data.tipe_user]}</td>
										<td className="px-4 py-2.5 text-sm truncate">{data.email}</td>
										<td className="px-4 py-2.5 text-sm truncate text-center">{data.cu_id || "-"}</td>
										<td className="px-4 py-2.5 text-sm flex justify-center">
											<button
												className="flex items-center justify-between px-2 py-1 text-sm font-medium leading-5 text-purple-600 rounded-md dark:text-purple-600 focus:outline-none focus:shadow-outline-gray bg-gray-200"
												aria-label="Edit"
												onClick={() => { setOpenModal(true); setDetailUser(data); setAction("Edit") }}
											>
												Edit
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</main>
		</>

	);
}
