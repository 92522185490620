// CONFIG
import axios from "../../config/axiosinstance";
// HELPERS
import { encodeValue } from "../../utils";
// COMPONENTS
import { SwalNotify } from "../../components";

export const handleLogin = (payload) => {
  return (dispatch) => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
    });
    axios({
      url: "login",
      method: "POST",
      data: payload,
    })
      .then(({ data }) => {
        return axios({
          method: "GET",
          headers: {
            token: data.token,
          },
        }).then(response => ({
          data1: data,
          data2: response.data
        }));
      })
      .then(({ data1, data2 }) => {
        dispatch({
          type: "SET_CU_TIPE_USER",
          payload: {
            isLogin: true,
            tipeUser: data2.tipe_user,
            cuId: data2.CU_Id,
          },
        });
        dispatch({
          type: "SET_LIST_JENIS_TRANSAKSI",
          payloadPPOB: data2.jenisTransaksiPPOB,
          payloadViaBank: data2.jenisTransaksiViaBank,
        });
        // SET JENIS TRANSAKSI ON LOCAL STORAGE
        localStorage.setItem("auth", encodeValue(
          {
            ...data1,
            ...data2
          }
        ));
        // SET LOGIN STATUS
        dispatch({
          type: "SET_LOGIN_STATUS",
          payload: true,
        });
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });
      })
      .catch(({ response }) => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });
        SwalNotify(`${response?.data?.message === "mohon maaf, username tidak ditemukan" || response?.data?.message === "mohon maaf, password salah" ? "Username atau Password Salah!" : response?.data?.message}`);
      });
  };
};

export const handleLogout = () => {
  return (dispatch) => {
    dispatch({
      type: "SET_LOGIN_STATUS",
      payload: false,
    });
  };
};

export const setMenuBarOpen = (payload) => {
  return (dispatch) => {
    dispatch({
      type: "SET_MENU_BAR_OPEN",
      payload,
    });
  };
};