// PACKAGE
import { useState, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
// ACTIONS
import { fetchCU, updateBanner, fetchCurrentBanner } from "../store/actions/ruangUsahaAction";
// HELPERS
import { decodeValue } from "../utils";
// COMPONENTS
import { SwalNotify, ModalConfirmation, LoadingComponent, SwalSuccessNotify } from "../components";

export default function UpdateBannerPage() {
	const history = useHistory();
	const dispatch = useDispatch();
	const topRef = useRef();
	// DATA
	let { kode } = useParams();
	let { listCU, currentBanner, isLoading } = useSelector((state) => state.ruangUsaha);
	const [image, setImage] = useState('');
	const [imagePreview, setImagePreview] = useState('');
	const [imageUploaded, setImageUploaded] = useState(false);
	const [judul, serJudul] = useState('');
	const [infoSingkat, setInfoSingkat] = useState('');
	const [infoDetail, setInfoDetail] = useState("");
	const [noWhatsapp, setNoWhatsapp] = useState(null);
	const [koperasi, setKoperasi] = useState(null);
	// CONDITION
	const [openModal, setOpenModal] = useState(false);

	// UPADTE RUANG USAHA
	useEffect(() => {
		document.title = "Update Banner | CUSO DM";
	}, []);

	// FETCH DATA WHEN OPENING PAGE
	useEffect(() => {
		if (decodeValue(localStorage.getItem("auth"))) {
			// CHECK TIPE USER
			if (decodeValue(localStorage.getItem("auth"))?.tipe_user !== 0 && decodeValue(localStorage.getItem("auth"))?.tipe_user !== 2) {
				SwalNotify("Anda Tidak Memiliki Akses Menu Tersebut, Silahkan Hubungi Administrator");
				history.push("/");
			};

			// FETCH DATA CU
			dispatch(fetchCurrentBanner(kode));
			dispatch(fetchCU());
		};

		// AUTO SCROLL
		topRef.current.scrollIntoView({ behavior: "smooth", block: 'end' });
	}, [localStorage.getItem("auth")]);

	// HANDLE CHECK IMG FORMAT
	const checkImgFormat = (img) => {
		const validFormat = ["image/jpeg", "image/png", "image/jpg"];
		return validFormat.includes(img.type);
	};

	//Foto Produk
	function handleImageChange(e) {
		const img = e.target.files[0];

		if (img && checkImgFormat(img) && img.size < 300000) {
			setImage(img);
			let reader = new FileReader();

			reader.onload = function (e) {
				setImagePreview(e.target.result);
				setImageUploaded(true);
			};

			reader.readAsDataURL(img);
		} else {
			SwalNotify("Gambar Tidak Sesuai Persyaratan, Silahkan Pilih Gambar Lain");
			setImagePreview("");
			setImageUploaded(false);
		};
	};

	//Nama Produk
	function handleJudulChange(e) {
		if (e.target.value.length) {
			serJudul(e.target.value);
		} else {
			serJudul("");
		};
	};

	//InfoSingkat Produk
	function handleInfoSingkatChange(e) {
		if (e.target.value.length) {
			setInfoSingkat(e.target.value);
		} else {
			setInfoSingkat("");
		};
	};

	//Deskripsi Produk
	const handleEditorChange = (content) => {
		setInfoDetail(content)
	};

	//CU_Id
	function handleKoperasiChange(e) {
		if (e.target.value) {
			setKoperasi(e.target.value);
		} else {
			setKoperasi(null);
		};
	};

	//no Whatsapp
	function handleNoWhatsappChange(e) {
		if (e.target.value) {
			setNoWhatsapp(e.target.value);
		} else {
			setNoWhatsapp("");
		};
	};

	//submit
	function handleSubmit(e) {
		e.preventDefault();
		setOpenModal(true);
	};

	// HANDLE CONFRIM FROM MODAL && SUBMIT DATA
	const handleConfirm = async () => {
		setOpenModal(false);

		let newDate = new Date();
		let date = newDate.getDate();
		let month = newDate.getMonth() + 1;
		let year = newDate.getFullYear();
		const separator = '-';
		const tanggal = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`;

		const fd = new FormData();
		fd.append('Status_Banner', 0);
		fd.append('Tanggal', tanggal);
		fd.append('Kode_Kategori', '005');
		fd.append('Status', 0);
		fd.append('Tipe_Penjualan', 1);
		fd.append('Harga', "0");

		//jika admin maka CU_Id dipilih, jika bukan admin maka cu id tergantung akun
		if (decodeValue(localStorage.getItem("auth"))?.CU_Id === null && koperasi) {
			fd.append('CU_Id', koperasi);
		} else if (decodeValue(localStorage.getItem("auth"))?.CU_Id === null && !koperasi) {
			fd.append('CU_Id', currentBanner.CU_Id);
		} else {
			fd.append('CU_Id', decodeValue(localStorage.getItem("auth"))?.CU_Id);
		};

		if (image) {
			fd.append('Foto', image)
		} else {
			fd.append('Foto_URL', currentBanner.Foto_URL);
		};

		if (judul) {
			fd.append('Judul', judul);
		} else {
			fd.append('Judul', currentBanner.Judul);
		};

		if (infoSingkat) {
			fd.append('Info_Singkat', infoSingkat);
		} else {
			fd.append('Info_Singkat', currentBanner.Info_Singkat);
		};

		if (infoDetail) {
			fd.append('Informasi_Detail', infoDetail);
		} else {
			fd.append('Informasi_Detail', currentBanner.Informasi_Detail);
		};

		if (noWhatsapp) {
			fd.append('No_Hp_Whatsapp', noWhatsapp);
		} else {
			fd.append('No_Hp_Whatsapp', currentBanner.No_HP_Whatsapp);
		};

		// SUBMIT DATA
		const submited = await dispatch(updateBanner(fd, kode));
		if (submited) {
			SwalSuccessNotify("Data Berhasil Tersimpan").then(() => {
				// REDIRECT IF USER ADM CUSO
				if (decodeValue(localStorage.getItem("auth"))?.tipe_user === 0 && currentBanner.CU_Id !== "CUSO" && currentBanner.CU_Id !== "XUSO") {
					history.push(`/banner?cu_id=${koperasi || currentBanner.CU_Id}`);
				} else {
					history.push("/banner");
				};
			});
		};
	};

	return (
		<>
			{/* LOADING COMPONENT */}
			{isLoading && (<LoadingComponent />)}

			{/* MODAL CONFIRMATION */}
			<ModalConfirmation
				openModal={openModal}
				setOpenModal={setOpenModal}
				handleConfirm={handleConfirm}
				data={currentBanner ? {
					...currentBanner, imageUploaded, imagePreview, infoDetail, judul, noWhatsapp, infoSingkat,
					Koperasi: listCU?.find(item => item.CU_Id === (koperasi || currentBanner.CU_Id))?.Nama_CU,
				} : null}
				menu={"banner"}
			/>

			<div className="py-3">
				{/** Upload Form*/}
				<form className="w-full" style={{ maxWidth: 1024 }} onSubmit={handleSubmit}>

					{/* TOP REF */}
					<div className="bg-transparent" ref={topRef} />

					{/** Judul Form */}
					<div className="flex flex-wrap w-full mb-6">
						<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
							<h1 className="font-sans font-bold text-2xl">Edit Banner</h1>
						</div>
					</div>

					{/** Image */}
					<div className="flex flex-wrap w-full mb-6">
						<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
							<h1 className="font-['Open_Sans'] font-bold text-xl">Foto</h1>
							<p className="text-sm font-['Open_Sans']">
								Format gambar .jpg .jpeg .png dan
								ukuran maximum 300kb.
							</p>
						</div>

						<div className="w-full border border-gray-300 rounded-md" style={{ maxWidth: 600 }}>
							{/* BORDER PHOTO */}
							<div className="p-3">
								<div className="border border-gray-300 rounded-md flex justify-center" style={{ maxWidth: 600, maxHeight: 300, minHeight: 250 }}>
									{
										currentBanner && (
											<img className="w-fit h-fit" src={
												imageUploaded && imagePreview ? imagePreview :
													currentBanner.Foto_URL ? currentBanner.Foto_URL : null} alt="" style={{ maxHeight: 250 }} />
										)}
								</div>
							</div>
							<div className="flex items-center justify-center w-full mb-1">
								<label
									className="flex flex-col border-4 border-blue-200 border-dashed hover:border-gray-300 cursor-pointer mb-1" style={{ width: 190, height: 60 }}>
									<div className="flex flex-col items-center justify-center pt-1">
										<svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 group-hover:text-gray-600" style={{ width: 20, height: 20 }}
											fill="none" viewBox="0 0 24 24" stroke="currentColor">
											<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
												d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
										</svg>
										<p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
											Update Photo</p>
									</div>
									<input id="upload-image-produk" type="file" accept=".jpg,.png,.jpeg" className="opacity-0" onChange={handleImageChange} />
								</label>
							</div>
						</div>
					</div>

					{/** Divider 1*/}
					<div className="flex flex-wrap w-full mb-6 border-dashed border-t-2 border-gray-300">
						<div className="w-full md:w-1/3 px-3 mt-2 mb-6 md:mb-0">
							<h1 className="font-sans font-bold text-2xl">Informasi Banner</h1>
						</div>
					</div>

					{/** Nama Produk */}
					<div className="flex flex-wrap w-full mb-6">
						<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
							<h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Judul Banner</h1>
							<p className="text-sm font-['Open_Sans']">
								Cantumkan judul banner yang deskriptif agar
								mudah dipahami user.
							</p>
						</div>
						<div className="w-full md:w-2/3 px-3">
							<label className="block text-sm mt-5">
								<input
									className="block w-full mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
									style={{ maxWidth: 590 }}
									onChange={handleJudulChange}
									value={judul || currentBanner.Judul}
								/>
							</label>
						</div>
					</div>

					{/** Info Singkat */}
					<div className="flex flex-wrap w-full mb-6">
						<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
							<h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Info Singkat</h1>
							<p className="text-sm font-['Open_Sans']">
								Cantumkan info singkat untuk mendeskirpsikan banner.
							</p>
						</div>
						<div className="w-full md:w-2/3 px-3">
							<label className="block text-sm mt-5">
								<input
									className="block w-full mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
									style={{ maxWidth: 590 }}
									onChange={handleInfoSingkatChange}
									value={infoSingkat || currentBanner.Info_Singkat}
								/>
							</label>
						</div>
					</div>

					{/** Deskripsi Produk */}
					<div className="flex flex-wrap w-full mb-6">
						<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
							<h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Deskripsi Banner</h1>
							<p className="text-sm font-['Open_Sans']">
								Pastikan Deskripsi banner memuat
								informasi terkait banner. Semakin detail, semakin
								berguna bagi user, cantumkan max. 2500 karakter.
							</p>
						</div>
						<div className="w-full md:w-2/3 px-3 mt-5">
							<label className="block mt-4 text-sm">
								<Editor
									apiKey={window.env.REACT_APP_TINY_MCE_API_KEY}
									initialValue={currentBanner.Informasi_Detail}
									init={{
										height: 400,
										width: 590,
										menubar: false,
										plugins: [
											"advlist autolink lists link image charmap print preview anchor",
											"searchreplace visualblocks code fullscreen",
											"insertdatetime media table paste code help wordcount",
										],
										toolbar:
											"undo redo | formatselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent link | removeformat | help",
									}}
									onEditorChange={handleEditorChange}
								/>
							</label>
						</div>
					</div>

					{/** Divider 2*/}
					<div className="flex flex-wrap w-full mb-6 border-dashed border-t-2 border-gray-300">
						<div className="pl-3 mt-2 mb-6 md:mb-0">
							<h1 className="font-sans font-bold text-2xl">Pengelolaan Banner</h1>
						</div>
					</div>

					{/** Nama Koperasi (untuk admin CUSO) */}
					{decodeValue(localStorage.getItem("auth"))?.CU_Id === null ? (
						<div>
							{/** CU_Id */}
							<div className="flex flex-wrap w-full mb-6">
								<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
									<h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Koperasi</h1>
									<p className="text-sm font-['Open_Sans']">
										Silahkan Pilih Koperasi.
									</p>
								</div>
								<div className="w-full md:w-2/3 px-3 mt-5">
									<select onChange={handleKoperasiChange} className="block w-full mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
										style={{ maxWidth: 590 }} value={koperasi || currentBanner.CU_Id}>
										<option selected hidden >Pilih Koperasi</option>
										{listCU.map((data, idx) => (
											<option value={data.CU_Id} key={idx}>{data.CU_Id} - {data.Nama_CU}</option>
										))}
									</select>
								</div>
							</div>
						</div>
					) : null}

					{/** No HP/Whatsapp */}
					<div className="flex flex-wrap w-full mb-10">
						<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
							<h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Nomor HP/Whatsapp</h1>
							<p className="text-sm font-['Open_Sans']">
								Cantumkan nomor handphone atau whatsapp yang dapat dihubungi agar user mudah untuk menghubungi.
							</p>
						</div>
						<div className="w-full md:w-2/3 px-3">
							<label className="block text-sm mt-5">
								<input
									className="block w-full mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
									style={{ maxWidth: 590 }}
									onChange={handleNoWhatsappChange}
									value={noWhatsapp || currentBanner.No_HP_Whatsapp}
								/>
							</label>
						</div>
					</div>

					{/** Submit */}
					<div className="flex flex-wrap justify-center w-full" style={{ paddingBottom: 10 }}>
						<button
							type="submit"
							className="w-full md:w-1/6 flex justify-center py-2 my-1 mr-2 rounded-lg text-white items-center text-sm bg-green-700 hover:bg-green-600 focus:outline-none"
						>
							Simpan
						</button>
						<button
							onClick={() => decodeValue(localStorage.getItem("auth"))?.tipe_user === 0 && currentBanner.CU_Id !== "CUSO" && currentBanner.CU_Id !== "XUSO" ? history.push(`/banner?cu_id=${currentBanner.CU_Id}`) : history.push("/banner")}
							type="button"
							className="w-full md:w-1/6 flex justify-center py-2 my-1 rounded-lg text-white items-center text-sm bg-red-600 hover:bg-red-500 focus:outline-none"
						>
							Batal
						</button>
					</div>
				</form>
			</div>
		</>

	);
}
