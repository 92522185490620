// CONFIG
import axios from "../../config/axiosinstance";
// HELPERS
import { decodeValue } from "../../utils";
// COMPONENTS
import { SwalNotify } from "../../components";

//Create New Berita
export const postBerita = (payload) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: "SET_BERITA_LOADING",
				payload: true,
			});
			await axios({
				method: "POST",
				url: "news",
				headers: {
					"Content-Type": "multipart/form-data",
					token: decodeValue(localStorage.getItem("auth")).token,
				},
				data: payload,
			});
			// SUCCSESS SUBMIT
			return true;
		} catch ({ response }) {
			if (response) {
				if (response?.status === 401) {
					dispatch({
						type: "SET_LOGIN_STATUS",
						payload: false,
					});
					localStorage.removeItem("auth");
				} else {
					SwalNotify(response.data.message);
				};
			};
			// FAIL TO SUBMIT
			return null;
		} finally {
			dispatch({
				type: "SET_BERITA_LOADING",
				payload: false,
			});
		};
	};
};

//Fetch berita
export const fetchBerita = (cu_id) => {
	return (dispatch) => {
		dispatch({
			type: "SET_LIST_BERITA",
		});
		dispatch({
			type: "SET_BERITA_LOADING",
			payload: true,
		});
		axios({
			method: "GET",
			url: "news",
			headers: {
				token: decodeValue(localStorage.getItem("auth")).token,
			},
		})
			.then(({ data }) => {
				if (decodeValue(localStorage.getItem("auth"))?.CU_Id === null) {
					if (!cu_id) {
						dispatch({
							type: "SET_LIST_BERITA",
							payload: data.data.reverse()
						});
					}
					// FILTER DATA BASED ON CU_ID 
					else {
						const filterData = data.data.filter(item => item.CU_Id === cu_id).reverse();
						if (filterData?.length) {
							dispatch({
								type: "SET_LIST_BERITA",
								payload: filterData,
							});
						} else {
							SwalNotify("Berita Tidak Ditemukan");
							dispatch({
								type: "SET_LIST_BERITA",
								payload: [],
							});
						};
					};
				} else {
					dispatch({
						type: "SET_LIST_BERITA",
						payload: data.data.filter(item => item.CU_Id === decodeValue(localStorage.getItem("auth"))?.CU_Id).reverse()
					});
				};
			})
			.catch(({ response }) => {
				if (response?.status === 401) {
					SwalNotify("Session Berakhir").then(() => {
						dispatch({
							type: "SET_LOGIN_STATUS",
							payload: false,
						});
						localStorage.removeItem("auth");
					});
				} else {
					SwalNotify(response?.data?.message);
				};
				dispatch({
					type: "SET_BERITA_LOADING",
					payload: false,
				});
			});
	};
};

//Fetch CU
export const fetchCU = () => {
	return (dispatch) => {
		dispatch({
			type: "SET_BERITA_LOADING",
			payload: true,
		});
		axios({
			method: "GET",
			url: "rusaha/cu",
			headers: {
				token: decodeValue(localStorage.getItem("auth")).token,
			},
		})
			.then(({ data }) => {
				dispatch({
					type: "SET_LIST_CU",
					payload: data.data,
				});
			})
			.catch(({ response }) => {
				if (response) {
					if (response?.status === 401) {
						dispatch({
							type: "SET_LOGIN_STATUS",
							payload: false,
						});
						localStorage.removeItem("auth");
					} else {
						SwalNotify(response.data.message);
					};
				};
				dispatch({
					type: "SET_BERITA_LOADING",
					payload: false,
				});
			});
	};
};

//Fetch Kategori
export const fetchKategori = () => {
	return (dispatch) => {
		dispatch({
			type: "SET_BERITA_LOADING",
			payload: true,
		});
		axios({
			method: "GET",
			url: "news/list",
			headers: {
				token: decodeValue(localStorage.getItem("auth")).token,
			},
		})
			.then(({ data }) => {
				dispatch({
					type: "SET_LIST_CATEGORY",
					payload: data.data,
				});
			})
			.catch(({ response }) => {
				if (response) {
					if (response?.status === 401) {
						dispatch({
							type: "SET_LOGIN_STATUS",
							payload: false,
						});
						localStorage.removeItem("auth");
					} else {
						SwalNotify(response.data.message);
					};
				};
				dispatch({
					type: "SET_BERITA_LOADING",
					payload: false,
				});
			});
	};
};

//Fetch Current Berita
export const fetchCurrentBerita = (kode) => {
	return (dispatch) => {
		dispatch({
			type: "SET_BERITA_LOADING",
			payload: true,
		});
		axios({
			method: "GET",
			url: `news/${kode}`,
			headers: {
				token: decodeValue(localStorage.getItem("auth")).token,
			},
		})

			.then(({ data }) => {
				dispatch({
					type: "SET_CURRENT_BERITA",
					payload: data.data,
				});
			})
			.catch(({ response }) => {
				if (response) {
					if (response?.status === 401) {
						dispatch({
							type: "SET_LOGIN_STATUS",
							payload: false,
						});
						localStorage.removeItem("auth");
					} else {
						SwalNotify(response.data.message);
					};
				};
				dispatch({
					type: "SET_BERITA_LOADING",
					payload: false,
				});
			});
	};
};

//Update Berita
export const updateBerita = (payload, id) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: "SET_BERITA_LOADING",
				payload: true,
			});
			await axios({
				method: "PUT",
				url: `news/${id}`,
				headers: {
					"Content-Type": "multipart/form-data",
					token: decodeValue(localStorage.getItem("auth")).token,
				},
				data: payload,
			});
			// SUCCSESS SUBMIT
			return true;
		} catch ({ response }) {
			if (response) {
				if (response?.status === 401) {
					dispatch({
						type: "SET_LOGIN_STATUS",
						payload: false,
					});
					localStorage.removeItem("auth");
				} else {
					SwalNotify(response.data.message);
				};
			};
			// FAIL TO SUBMIT
			return null;
		} finally {
			dispatch({
				type: "SET_BERITA_LOADING",
				payload: false,
			});
		};
	};
};
