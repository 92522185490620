// CONFIG
import axios from "../../config/axiosinstance";
// HELPERS
import { decodeValue } from "../../utils";
// COMPONENTES
import { SwalNotify } from "../../components";

//Fetch TRX Data
export const fetchTRX = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_DASHBOARD_LOADING",
			payload: true,
		});
		axios({
			method: "GET",
			url: "mainpage/trx/date",
			headers: {
				token: decodeValue(localStorage.getItem("auth")).token,
			},
			params: {
				CU_Id: payload.CU_Id,
				start_date: payload.start_date,
				end_date: payload.end_date
			}
		})
			.then(({ data }) => {
				const allData = data.data
				let payload = {}
				payload.allData = allData
				payload.success = []
				payload.pending = []
				payload.failed = []
				payload.label = Object.keys(allData).filter(item => item !== 'totalTrx')
				payload.label.forEach(item => {
					payload.success.push(allData[item].success)
					payload.pending.push(allData[item].pending)
					payload.failed.push(allData[item].failed)
				})
				dispatch({
					type: "SET_TRX_DATA",
					payload: payload,
				});
			})
			.catch(({ response }) => {
				if (response) {
					if (response?.status === 401) {
						dispatch({
							type: "SET_LOGIN_STATUS",
							payload: false,
						});
						localStorage.removeItem("auth");
					} else {
						SwalNotify(response.data.message);
					};
				};
				dispatch({
					type: "SET_DASHBOARD_LOADING",
					payload: false,
				});
			});
	};
};

//Fetch TRX Data By Status
export const fetchTRXByStatus = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_DASHBOARD_LOADING",
			payload: true,
		});
		axios({
			method: "GET",
			url: "mainpage/trx/date",
			headers: {
				token: decodeValue(localStorage.getItem("auth")).token,
			},
			params: {
				CU_Id: payload.CU_Id,
				start_date: payload.start_date,
				end_date: payload.end_date,
				trx_type: payload.trx_type
			}
		})
			.then(({ data }) => {
				const allData = data.data
				let payload = {}
				payload.allData = allData
				payload.success = []
				payload.pending = []
				payload.failed = []
				payload.label = Object.keys(allData).filter(item => item !== 'totalTrx')
				payload.label.forEach(item => {
					payload.success.push(allData[item].success)
					payload.pending.push(allData[item].pending)
					payload.failed.push(allData[item].failed)
				})
				dispatch({
					type: "SET_TRX_DATA",
					payload: payload,
				});
			})
			.catch(({ response }) => {
				if (response) {
					if (response?.status === 401) {
						dispatch({
							type: "SET_LOGIN_STATUS",
							payload: false,
						});
						localStorage.removeItem("auth");
					} else {
						SwalNotify(response.data.message);
					};
				};
				dispatch({
					type: "SET_DASHBOARD_LOADING",
					payload: false,
				});
			});
	};
};

//Fetch User Data
export const fetchUser = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_DASHBOARD_LOADING",
			payload: true,
		});
		axios({
			method: "GET",
			url: "mainpage/user",
			headers: {
				token: decodeValue(localStorage.getItem("auth")).token,
			},
			params: {
				CU_Id: payload.CU_Id,
				start_date: payload.start_date,
				end_date: payload.end_date
			}
		})
			.then(({ data }) => {
				const allData = data.data
				let payload = {}
				payload.allData = allData
				payload.totalUser = allData.totalUser
				payload.newUser = allData.newUser
				payload.generationTotal = []
				payload.generation = Object.keys(allData.generation)
				payload.generation.forEach(item => {
					payload.generationTotal.push(allData.generation[item])
				})
				dispatch({
					type: "SET_USER_STATS",
					payload: payload,
				});
			})
			.catch(({ response }) => {
				if (response) {
					if (response?.status === 401) {
						dispatch({
							type: "SET_LOGIN_STATUS",
							payload: false,
						});
						localStorage.removeItem("auth");
					} else {
						SwalNotify(response.data.message);
					};
				};
				dispatch({
					type: "SET_DASHBOARD_LOADING",
					payload: false,
				});
			});
	};
};

//Fetch TRX Type
export const fetchTRXType = () => {
	return (dispatch) => {
		dispatch({
			type: "SET_DASHBOARD_LOADING",
			payload: true,
		});
		axios({
			method: "GET",
			url: "mainpage/trxtype",
			headers: {
				token: decodeValue(localStorage.getItem("auth")).token,
			}
		})
			.then(({ data }) => {
				dispatch({
					type: "SET_TRX_TYPE",
					payload: data.data,
				});
			})
			.catch(({ response }) => {
				if (response) {
					if (response?.status === 401) {
						dispatch({
							type: "SET_LOGIN_STATUS",
							payload: false,
						});
						localStorage.removeItem("auth");
					} else {
						SwalNotify(response.data.message);
					};
				};
				dispatch({
					type: "SET_DASHBOARD_LOADING",
					payload: false,
				});
			});
	};
};
