// CONFIG
import axios from "../../config/axiosinstance";
// HELEPRS
import { decodeValue } from "../../utils";
// COMPONENTS
import { SwalNotify } from "../../components";

export const fetchCUId = () => {
  return (dispatch) => {
    dispatch({
      type: "SET_USER_MANAGEMENT_LOADING",
      payload: true,
    });
    axios({
      url: "muser/load",
      method: "GET",
      headers: {
        token: decodeValue(localStorage.getItem("auth")).token,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: "SET_USER_MANAGEMENT_LIST_KOPERASI",
          payload: data.listKoperasi,
        });
      })
      .catch(({ response }) => {
        if (response) {
          if (response?.status === 401) {
            dispatch({
              type: "SET_LOGIN_STATUS",
              payload: false,
            });
            localStorage.removeItem("auth");
          } else {
            SwalNotify(response.data.message);
          };
        };
        dispatch({
          type: "SET_USER_MANAGEMENT_LOADING",
          payload: false
        });
      });
  };
};

export const fetchUsers = () => {
  return (dispatch) => {
    dispatch({
      type: "SET_USER_MANAGEMENT_LOADING",
      payload: true,
    });
    axios({
      url: "muser",
      method: "GET",
      headers: {
        token: decodeValue(localStorage.getItem("auth")).token,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: "SET_USER_MANAGEMENT_LIST_USER",
          payload: data.users,
        });
      })
      .catch(({ response }) => {
        if (response) {
          if (response?.status === 401) {
            dispatch({
              type: "SET_LOGIN_STATUS",
              payload: false,
            });
            localStorage.removeItem("auth");
          } else {
            SwalNotify(response.data.message);
          };
        };
        dispatch({
          type: "SET_USER_MANAGEMENT_LOADING",
          payload: false
        });
      });
  };
};

export const addUser = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_USER_MANAGEMENT_LOADING_BUTTON",
        payload: true,
      });
      await axios({
        url: "muser/register",
        method: "POST",
        headers: {
          token: decodeValue(localStorage.getItem("auth")).token,
        },
        data: payload,
      });
      // SUCCSESS SUBMIT
      return true;
    } catch ({ response }) {
      if (response) {
        if (response?.status === 401) {
          dispatch({
            type: "SET_LOGIN_STATUS",
            payload: false,
          });
          localStorage.removeItem("auth");
        } else {
          SwalNotify(response.data.message);
        };
      };
      // FAIL TO SUBMIT
      return null;
    } finally {
      dispatch({
        type: "SET_USER_MANAGEMENT_LOADING_BUTTON",
        payload: false,
      });
    };
  };
};

// HANDLE EDIT USER
export const editUser = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_USER_MANAGEMENT_LOADING_BUTTON",
        payload: true,
      });
      await axios({
        url: "muser/edit",
        method: "PATCH",
        headers: {
          token: decodeValue(localStorage.getItem("auth")).token,
        },
        data: payload,
      });
      // SUCCSESS SUBMIT
      return true;
    } catch ({ response }) {
      if (response) {
        if (response?.status === 401) {
          dispatch({
            type: "SET_LOGIN_STATUS",
            payload: false,
          });
          localStorage.removeItem("auth");
        } else {
          SwalNotify(response.data.message);
        };
      };
      // FAIL TO SUBMIT
      return null;
    } finally {
      dispatch({
        type: "SET_USER_MANAGEMENT_LOADING_BUTTON",
        payload: false,
      });
    };
  };
};

// HANDLE CHANGE PASSWORD USER
export const changePasswordUser = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_USER_MANAGEMENT_LOADING_BUTTON",
        payload: true,
      });
      await axios({
        url: "muser/changepassword",
        method: "PATCH",
        headers: {
          token: decodeValue(localStorage.getItem("auth")).token,
        },
        data: payload,
      });
      // SUCCSESS SUBMIT
      return true;
    } catch ({ response }) {
      if (response) {
        if (response?.status === 401 && response?.data?.message !== "mohon maaf, password salah") {
          dispatch({
            type: "SET_LOGIN_STATUS",
            payload: false,
          });
          localStorage.removeItem("auth");
        } else {
          SwalNotify(response.data.message);
        };
      };
      // FAIL TO SUBMIT
      return null;
    } finally {
      dispatch({
        type: "SET_USER_MANAGEMENT_LOADING_BUTTON",
        payload: false,
      });
    };
  };
};
