// CONFIG
import axios from "../../config/axiosinstance";
// HELEPRS
import { decodeValue } from "../../utils";
// COMPONENTS
import { SwalNotify } from "../../components";

//Fetch Category
export const fetchCategory = () => {
	return (dispatch) => {
		dispatch({
			type: "SET_RUANG_USAHA_LOADING",
			payload: true
		});
		axios({
			method: "GET",
			url: "rusaha/category",
			headers: {
				token: decodeValue(localStorage.getItem("auth")).token,
			},
		})
			.then(({ data }) => {
				dispatch({
					type: "SET_LIST_CATEGORY",
					payload: data.data,
				});
			})
			.catch(({ response }) => {
				if (response) {
					if (response?.status === 401) {
						dispatch({
							type: "SET_LOGIN_STATUS",
							payload: false,
						});
						localStorage.removeItem("auth");
					} else {
						SwalNotify(response.data.message);
					};
				};
				dispatch({
					type: "SET_RUANG_USAHA_LOADING",
					payload: false
				});
			});
	};
};

// Fetch CU
export const fetchCU = () => {
	return (dispatch) => {
		dispatch({
			type: "SET_RUANG_USAHA_LOADING",
			payload: true
		});
		axios({
			method: "GET",
			url: "rusaha/cu",
			headers: {
				token: decodeValue(localStorage.getItem("auth")).token,
			},
		})
			.then(({ data }) => {
				dispatch({
					type: "SET_LIST_CU",
					payload: data.data,
				});
			})
			.catch(({ response }) => {
				if (response) {
					if (response?.status === 401) {
						dispatch({
							type: "SET_LOGIN_STATUS",
							payload: false,
						});
						localStorage.removeItem("auth");
					} else {
						SwalNotify(response.data.message);
					};
				};
				dispatch({
					type: "SET_RUANG_USAHA_LOADING",
					payload: false
				});
			});
	};
};

//Fetch RuangUsaha
export const fetchRuangUsaha = (cu_id) => {
	return (dispatch) => {
		dispatch({
			type: "SET_LIST_RUANG_USAHA",
		});
		dispatch({
			type: "SET_RUANG_USAHA_LOADING",
			payload: true
		});
		axios({
			method: "GET",
			url: "rusaha",
			headers: {
				token: decodeValue(localStorage.getItem("auth")).token,
			},
		})
			.then(({ data }) => {
				// FILTER DATA BASED ON CONDITION
				let filtered = [];
				if (decodeValue(localStorage.getItem("auth"))?.CU_Id === null) {
					filtered = data.data.filter(item => item.Status_Banner === 1).reverse();
					if (!cu_id) {
						dispatch({
							type: "SET_LIST_RUANG_USAHA",
							payload: filtered
						});
					} else {
						const filterData = filtered.filter(item => item.CU_Id === cu_id);
						if (filterData?.length) {
							dispatch({
								type: "SET_LIST_RUANG_USAHA",
								payload: filterData,
							});
						} else {
							SwalNotify("Produk Tidak Ditemukan");
							dispatch({
								type: "SET_LIST_RUANG_USAHA",
								payload: [],
							});
						};
					};
				} else {
					filtered = data.data.filter(item => item.Status_Banner === 1 && item.CU_Id === decodeValue(localStorage.getItem("auth"))?.CU_Id).reverse();
					dispatch({
						type: "SET_LIST_RUANG_USAHA",
						payload: filtered
					});
				};
			})
			.catch(({ response }) => {
				if (response) {
					if (response?.status === 401) {
						dispatch({
							type: "SET_LOGIN_STATUS",
							payload: false,
						});
						localStorage.removeItem("auth");
					} else {
						SwalNotify(response.data.message);
					};
				};
				dispatch({
					type: "SET_RUANG_USAHA_LOADING",
					payload: false
				});
			});
	};
};

//Search RuangUsaha
export const searchRuangUsaha = (search, cu_id) => {
	return (dispatch) => {
		dispatch({
			type: "SET_LIST_RUANG_USAHA",
		});
		dispatch({
			type: "SET_RUANG_USAHA_LOADING",
			payload: true,
		});
		axios({
			method: "GET",
			url: `rusaha/search/${search}`,
			headers: {
				token: decodeValue(localStorage.getItem("auth")).token,
			},
		})
			.then(({ data }) => {
				// FILTER DATA BASED ON CONDITION
				let filtered = [];
				if (decodeValue(localStorage.getItem("auth"))?.CU_Id === null) {
					filtered = data.data.filter(item => item.Status_Banner === 1).reverse();
					if (!cu_id || cu_id === "all") {
						dispatch({
							type: "SET_LIST_RUANG_USAHA",
							payload: filtered
						});
					} else {
						const filterData = filtered.filter(item => item.CU_Id === cu_id);
						if (filterData?.length) {
							dispatch({
								type: "SET_LIST_RUANG_USAHA",
								payload: filterData,
							});
						} else {
							SwalNotify("Produk Tidak Ditemukan");
							dispatch({
								type: "SET_LIST_RUANG_USAHA",
								payload: [],
							});
						};
					};
				} else {
					filtered = data.data.filter(item => item.Status_Banner === 1 && item.CU_Id === decodeValue(localStorage.getItem("auth"))?.CU_Id).reverse();
					dispatch({
						type: "SET_LIST_RUANG_USAHA",
						payload: filtered
					});
				};
			})
			.catch(({ response }) => {
				if (response) {
					if (response?.status === 401) {
						dispatch({
							type: "SET_LOGIN_STATUS",
							payload: false,
						});
						localStorage.removeItem("auth");
					} else {
						if (response.data.message === "usaha tidak di temukan") {
							SwalNotify("Produk Tidak Ditemukan");
						} else {
							SwalNotify(response.data.message);
						};
					};
				};
				dispatch({
					type: "SET_RUANG_USAHA_LOADING",
					payload: false
				});
			});
	};
};

//Delete RuangUsaha
export const deleteRuangUsaha = (payload) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: "SET_RUANG_USAHA_LOADING",
				payload: true,
			});
			await axios({
				method: "DELETE",
				url: `rusaha/${payload}`,
				headers: {
					token: decodeValue(localStorage.getItem("auth")).token,
				},
			});
			// SUCCSESS SUBMIT
			return true;
		} catch ({ response }) {
			if (response) {
				if (response?.status === 401) {
					dispatch({
						type: "SET_LOGIN_STATUS",
						payload: false,
					});
					localStorage.removeItem("auth");
				} else {
					SwalNotify(response.data.message);
				};
			};
			// FAIL TO SUBMIT
			return null;
		} finally {
			dispatch({
				type: "SET_RUANG_USAHA_LOADING",
				payload: false,
			});
		};
	};
};

//Fetch Current Ruang Usaha
export const fetchCurrentUsaha = (kode) => {
	return (dispatch) => {
		dispatch({
			type: "SET_RUANG_USAHA_LOADING",
			payload: true
		});
		axios({
			method: "GET",
			url: `rusaha/${kode}`,
			headers: {
				token: decodeValue(localStorage.getItem("auth")).token,
			},
		})
			.then(({ data }) => {
				if (data.data.Status_Banner === 1) {
					dispatch({
						type: "SET_CURRENT_RUANG_USAHA",
						payload: data.data,
					});
				} else {
					SwalNotify("Data Ini Bukan Ruang Usaha!");
				};
			})
			.catch(({ response }) => {
				if (response) {
					if (response?.status === 401) {
						dispatch({
							type: "SET_LOGIN_STATUS",
							payload: false,
						});
						localStorage.removeItem("auth");
					} else {
						SwalNotify(response.data.message);
					};
				};
				dispatch({
					type: "SET_RUANG_USAHA_LOADING",
					payload: false
				});
			});
	};
};

//Create New Ruang Usaha
export const postRuangUsaha = (payload) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: "SET_RUANG_USAHA_LOADING",
				payload: true,
			});
			await axios({
				method: "POST",
				url: "rusaha",
				headers: {
					"Content-Type": "multipart/form-data",
					token: decodeValue(localStorage.getItem("auth")).token,
				},
				data: payload,
			});
			// SUCCSESS SUBMIT
			return true;
		} catch ({ response }) {
			if (response) {
				if (response?.status === 401) {
					dispatch({
						type: "SET_LOGIN_STATUS",
						payload: false,
					});
					localStorage.removeItem("auth");
				} else {
					SwalNotify(response.data.message);
				};
			};
			// FAIL TO SUBMIT
			return null;
		} finally {
			dispatch({
				type: "SET_RUANG_USAHA_LOADING",
				payload: false,
			});
		};
	};
};

//Update Ruang Usaha
export const updateRuangUsaha = (payload, id) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: "SET_RUANG_USAHA_LOADING",
				payload: true,
			});
			await axios({
				method: "PUT",
				url: `rusaha/${id}`,
				headers: {
					"Content-Type": "multipart/form-data",
					token: decodeValue(localStorage.getItem("auth")).token,
				},
				data: payload,
			});
			// SUCCSESS SUBMIT
			return true;
		} catch ({ response }) {
			if (response) {
				if (response?.status === 401) {
					dispatch({
						type: "SET_LOGIN_STATUS",
						payload: false,
					});
					localStorage.removeItem("auth");
				} else {
					SwalNotify(response.data.message);
				};
			};
			// FAIL TO SUBMIT
			return null;
		} finally {
			dispatch({
				type: "SET_RUANG_USAHA_LOADING",
				payload: false,
			});
		};
	};
};

//Fetch Banner
export const fetchBanner = (cu_id) => {
	return (dispatch) => {
		dispatch({
			type: "SET_LIST_BANNER",
		});
		dispatch({
			type: "SET_RUANG_USAHA_LOADING",
			payload: true
		});
		axios({
			method: "GET",
			url: "rusaha",
			headers: {
				token: decodeValue(localStorage.getItem("auth")).token,
			},
		})
			.then(({ data }) => {
				// FILTER DATA BASED ON CONDITION
				let filtered = [];
				if (decodeValue(localStorage.getItem("auth"))?.CU_Id === null) {
					filtered = data.data.filter(item => item.Status_Banner === 0).reverse();
					if (!cu_id) {
						dispatch({
							type: "SET_LIST_BANNER",
							payload: filtered
						});
					} else {
						const filterData = filtered.filter(item => item.CU_Id === cu_id);
						if (filterData?.length) {
							dispatch({
								type: "SET_LIST_BANNER",
								payload: filterData,
							});
						} else {
							SwalNotify("Banner Tidak Ditemukan");
							dispatch({
								type: "SET_LIST_BANNER",
								payload: [],
							});
						};
					};
				} else {
					filtered = data.data.filter(item => item.Status_Banner === 0 && item.CU_Id === decodeValue(localStorage.getItem("auth"))?.CU_Id).reverse();
					dispatch({
						type: "SET_LIST_BANNER",
						payload: filtered,
					});
				};
			})
			.catch(({ response }) => {
				if (response) {
					if (response?.status === 401) {
						dispatch({
							type: "SET_LOGIN_STATUS",
							payload: false,
						});
						localStorage.removeItem("auth");
					} else {
						SwalNotify(response.data.message);
					};
				};
				dispatch({
					type: "SET_RUANG_USAHA_LOADING",
					payload: false
				});
			});
	};
};

//Search Banner
export const searchBanner = (search, cu_id) => {
	return (dispatch) => {
		dispatch({
			type: "SET_LIST_BANNER",
		});
		dispatch({
			type: "SET_RUANG_USAHA_LOADING",
			payload: true,
		});
		axios({
			method: "GET",
			url: `rusaha/search/${search}`,
			headers: {
				token: decodeValue(localStorage.getItem("auth")).token,
			},
		})
			.then(({ data }) => {
				// FILTER DATA BASED ON CONDITION
				let filtered = [];
				if (decodeValue(localStorage.getItem("auth"))?.CU_Id === null) {
					filtered = data.data.filter(item => item.Status_Banner === 0).reverse();
					if (!cu_id || cu_id === "all") {
						dispatch({
							type: "SET_LIST_BANNER",
							payload: filtered
						});
					} else {
						const filterData = filtered.filter(item => item.CU_Id === cu_id);
						if (filterData?.length) {
							dispatch({
								type: "SET_LIST_BANNER",
								payload: filterData,
							});
						} else {
							SwalNotify("Produk Tidak Ditemukan");
							dispatch({
								type: "SET_LIST_BANNER",
								payload: [],
							});
						};
					};
				} else {
					filtered = data.data.filter(item => item.Status_Banner === 0 && item.CU_Id === decodeValue(localStorage.getItem("auth"))?.CU_Id).reverse();
					dispatch({
						type: "SET_LIST_BANNER",
						payload: filtered,
					});
				};
			})
			.catch(({ response }) => {
				if (response) {
					if (response?.status === 401) {
						dispatch({
							type: "SET_LOGIN_STATUS",
							payload: false,
						});
						localStorage.removeItem("auth");
					} else {
						if (response.data.message === "usaha tidak di temukan") {
							SwalNotify("Banner Tidak Ditemukan");
						} else {
							SwalNotify(response.data.message);
						};
					};
				};
				dispatch({
					type: "SET_RUANG_USAHA_LOADING",
					payload: false
				});
			});
	};
};

//Fetch Current Banner
export const fetchCurrentBanner = (kode) => {
	return (dispatch) => {
		dispatch({
			type: "SET_RUANG_USAHA_LOADING",
		});
		axios({
			method: "GET",
			url: `rusaha/${kode}`,
			headers: {
				token: decodeValue(localStorage.getItem("auth")).token,
			},
		})
			.then(({ data }) => {
				if (data.data.Status_Banner === 0) {
					dispatch({
						type: "SET_CURRENT_BANNER",
						payload: data.data,
					});
				} else {
					SwalNotify("Data Ini Bukan Banner!");
				};
			})
			.catch(({ response }) => {
				if (response) {
					if (response?.status === 401) {
						dispatch({
							type: "SET_LOGIN_STATUS",
							payload: false,
						});
						localStorage.removeItem("auth");
					} else {

						SwalNotify(response.data.message);
					};
				};
			});
	};
};

//Update Ruang Usaha
export const updateBanner = (payload, id) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: "SET_RUANG_USAHA_LOADING",
				payload: true,
			});
			await axios({
				method: "PUT",
				url: `rusaha/${id}`,
				headers: {
					"Content-Type": "multipart/form-data",
					token: decodeValue(localStorage.getItem("auth")).token,
				},
				data: payload,
			});
			// SUCCSESS SUBMIT
			return true;
		} catch ({ response }) {
			if (response) {
				if (response?.status === 401) {
					dispatch({
						type: "SET_LOGIN_STATUS",
						payload: false,
					});
					localStorage.removeItem("auth");
				} else {
					SwalNotify(response.data.message);
				};
			};
			// FAIL TO SUBMIT
			return null;
		} finally {
			dispatch({
				type: "SET_RUANG_USAHA_LOADING",
				payload: false,
			});
		};
	};
};