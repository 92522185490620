// PACAKGE
import { Fragment, useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
// ACTIONS
import { fetchListAnggota, searchListAnggota, editDataAnggota } from "../store/actions/listAnggota";
// COMPONENTS
import { SwalSuccessNotify } from "./index";
// UTILS
import { formatDate, decodeValue } from "../utils";

export default function ModalEditAnggota({ openModal, setOpenModal, setSelectedRow, data, dataQuery }) {
    const headTitle = useRef();
    const dispatch = useDispatch();
    // DATA
    const [input, setInput] = useState("");
    // CONDITIONAL
    const [isConfirmed, setIsConfirmed] = useState(false);

    // CHANGE DOCUMENT NAME
    useEffect(() => {
        if (openModal) {
            document.title = `Edit Anggota | CUSO DM`;
        };
    }, [openModal]);

    // SET DATA IF EDIT
    useEffect(() => {
        if (data) {
            setInput({
                ID_Anggota: data.ID_Anggota,
                Nama_Anggota: data.Nama_Anggota,
                Tgl_Lahir: data.Tgl_Lahir?.split("T")[0],
                Email: data.Email
            });
        } else {
            setInput("");
        };
    }, [data, openModal]);

    // HANDLE INPUT
    const handleInput = (e) => {
        setInput({ ...input, [e.target.name]: e.target.value });
    };

    // HANDLE CONFRIM FROM MODAL && SUBMIT DATA
    const handleConfirm = async (e) => {
        e.preventDefault();

        if (!isConfirmed) {
            return setIsConfirmed(true);
        };

        // SUBMIT DATA
        const submited = await dispatch(editDataAnggota(input));
        if (submited) {
            SwalSuccessNotify("Data Berhasil Tersimpan").then(() => {
                let url = "anggota";
                let urlQuery = "anggota/?";
                const arrQuery = [];
                // CHECK TIPE USER
                if (decodeValue(localStorage.getItem("auth"))?.tipe_user === 0) {
                    for (const key in dataQuery) {
                        if (dataQuery[key].length > 0) {
                            arrQuery.push(`${key}=${dataQuery[key]}`);
                        };
                    };
                } else {
                    arrQuery.push(`CU_Id=${decodeValue(localStorage.getItem("auth"))?.CU_Id}`);
                    for (const key in dataQuery) {
                        if (dataQuery[key].length > 0) {
                            arrQuery.push(`${key}=${dataQuery[key]}`);
                        };
                    };
                };
                urlQuery += arrQuery.join("&");
                if (urlQuery[urlQuery.length - 1] !== "?") {
                    url = urlQuery;
                };
                // REFETCH DATA
                if (arrQuery.length) {
                    dispatch(searchListAnggota(url));
                } else {
                    dispatch(fetchListAnggota());
                };
                setOpenModal(false);
                setIsConfirmed(false);
                setSelectedRow(null);
            });
        };
    };

    return (
        <Transition show={openModal} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-20 overflow-y-auto"
                style={{ width: "100%" }}
                initialFocus={headTitle}
                static
                open={openModal}
                onClose={() => { }}
            >
                <div className="min-h-screen px-4 text-center">
                    <Dialog.Overlay className="fixed inset-0 opacity-30 bg-black" />
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <form className="inline-block w-full p-4 overflow-x-hidden overflow-y-auto text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
                            style={{ maxWidth: 500 }}
                            onSubmit={handleConfirm}
                        >
                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-700"
                            >
                                <h1 ref={headTitle} className="text-center" style={{ fontSize: 24 }}>
                                    {!isConfirmed ? "Edit Data Anggota" : "Perhatian"}
                                </h1>
                                {isConfirmed ? (<p className="text-center" style={{ fontSize: 14 }}>Apakah Anda Yakin dengan Data Anggota di Bawah ini?</p>) : null}
                            </Dialog.Title>
                            <div className="mt-6">
                                {!isConfirmed ? (
                                    <>
                                        <div>
                                            <label className="block" style={{ fontSize: 14, marginTop: 8, fontWeight: 500 }}>
                                                ID Anggota
                                            </label>
                                            <input
                                                className="w-full mt-1 text-sm border border-purple-300 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                                                name="No_RekeningSH"
                                                disabled={true}
                                                value={input.ID_Anggota ? input.ID_Anggota : ""}
                                            />
                                        </div>
                                        <div>
                                            <label className="block" style={{ fontSize: 14, marginTop: 8, fontWeight: 500 }}>
                                                Nama Anggota
                                            </label>
                                            <input
                                                className="w-full mt-1 text-sm border border-purple-300 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                                                name="Nama_Anggota"
                                                value={input.Nama_Anggota ? input.Nama_Anggota : ""}
                                                type="text"
                                                onChange={handleInput}
                                                required={true}
                                            />
                                        </div>
                                        <div>
                                            <label className="block" style={{ fontSize: 14, marginTop: 8, fontWeight: 500 }}>
                                                Tanggal Lahir
                                            </label>
                                            <input
                                                className="w-full mt-1 text-sm border border-purple-300 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                                                name="Tgl_Lahir"
                                                value={input.Tgl_Lahir ? input.Tgl_Lahir : ""}
                                                type="date"
                                                onChange={handleInput}
                                                required={true}
                                            />
                                        </div>
                                        <div>
                                            <label className="block" style={{ fontSize: 14, marginTop: 8, fontWeight: 500 }}>
                                                Email
                                            </label>
                                            <input
                                                className="w-full mt-1 text-sm border border-purple-300 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                                                name="Email"
                                                value={input.Email ? input.Email : ""}
                                                type="email"
                                                onChange={handleInput}
                                                required={true}
                                            />
                                        </div>
                                        <div>
                                            <label className="block" style={{ fontSize: 14, marginTop: 8, fontWeight: 500 }}>
                                                CU ID
                                            </label>
                                            <input
                                                className={`w-full mt-1 text-sm border border-purple-300 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input`}
                                                disabled={true}
                                                name="Cu_ID"
                                                type="text"
                                                value={data ? data.RegistrasiAwal_CU_Id : ""}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <div className="border border-black p-2" style={{ fontSize: 14 }}>
                                        <div>
                                            <p className="inline-block text-left" style={{ paddingRight: 5, width: 175, fontWeight: 500 }}>ID Anggota</p>
                                            <p className="inline-block text-right" style={{ paddingRight: 5 }}>:</p>
                                            <p className="inline-block text-left" style={{ paddingRight: 5 }}>{input.ID_Anggota ? input.ID_Anggota : ""}</p>
                                        </div>
                                        <div className="flex">
                                            <p className="inline-block text-left" style={{ paddingRight: 5, width: 175, fontWeight: 500 }}>Nama Anggota</p>
                                            <p className="inline-block text-right" style={{ paddingRight: 5 }}>:</p>
                                            <p className="inline-block text-left" style={{ paddingRight: 5, maxWidth: 250 }}>{input.Nama_Anggota ? input.Nama_Anggota : ""}</p>
                                        </div>
                                        <div className="flex">
                                            <p className="inline-block text-left" style={{ paddingRight: 5, width: 175, fontWeight: 500 }}>Tanggal Lahir</p>
                                            <p className="inline-block text-right" style={{ paddingRight: 5 }}>:</p>
                                            <p className="inline-block text-left" style={{ paddingRight: 5, maxWidth: 250 }}>{input.Tgl_Lahir ? formatDate(input.Tgl_Lahir) : ""}</p>
                                        </div>
                                        <div className="flex">
                                            <p className="inline-block text-left" style={{ paddingRight: 5, width: 175, fontWeight: 500 }}>Email</p>
                                            <p className="inline-block text-right" style={{ paddingRight: 5 }}>:</p>
                                            <p className="inline-block text-left" style={{ paddingRight: 5, maxWidth: 250 }}>{input.Email ? input.Email : ""}</p>
                                        </div>
                                        <div>
                                            <p className="inline-block text-left" style={{ paddingRight: 5, width: 175, fontWeight: 500 }}> CU ID</p>
                                            <p className="inline-block text-right" style={{ paddingRight: 5 }}>:</p>
                                            <p className="inline-block text-left" style={{ paddingRight: 5 }}>{data ? data.RegistrasiAwal_CU_Id : ""}</p>
                                        </div>
                                    </div>
                                )}

                            </div>
                            <div className="mt-4 flex justify-center gap-2">
                                <button
                                    type="submit"
                                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-purple-600 border border-transparent rounded-md hover:bg-purple-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-purple-600"
                                >
                                    {isConfirmed ? "Iya" : "Simpan"}
                                </button>
                                <div
                                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 cursor-pointer"
                                    onClick={() => {
                                        // CHECK IS CONFIRMED
                                        if (isConfirmed) {
                                            return setIsConfirmed(false);
                                        };
                                        setOpenModal(false);
                                        setSelectedRow(null);
                                        setTimeout(() => {
                                            setInput("");
                                            setIsConfirmed(false);
                                        }, 200);
                                    }}
                                >
                                    {isConfirmed ? "Tidak" : "Batal"}
                                </div>
                            </div>
                        </form>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
}
